import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthService } from '../services/AuthService';

interface Props {
  path: string;
}

export default class UserRoute extends React.Component<Props> {

  render() {
    return (
      <Route path={this.props.path} render={({ location }) => (AuthService.isAuth && AuthService.user?.email !== "anonymous@bbri.be")
        ? this.props.children
        : <Redirect to={{ pathname: '/login', state: { from: location } }}/>}/>
    );
  }
}
