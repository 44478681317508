import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import './DecisionTreeEditorPage.scss';
import { TabMenu } from "primereact/tabmenu";
import Canvas from "./decision-tree/canvas";
import CodeEditorJS from "./code-editor/CodeEditorJS";
import CodeEditorHTML from "./code-editor/CodeEditorHTML";
import RequirementEditor from "./requirement-editor/RequirementEditor";
import FormEditor from "./form-editor/FormEditor";
import DiagramEditor from "./diagram-editor/DiagramEditor";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";

interface Props extends WithTranslation {
}

interface States {
  activeTool: any;
  showGoToAdminDialog: boolean;
  showGoToFormDialog: boolean;
  isDirtyJS: boolean;
  isDirtyHTML: boolean;
}

enum Lang {
  HTML = "html",
  JS = "script",
}

class DecisionTreeEditorPage extends React.Component<Props, States> {

  MIN_ZOOM_LVL: number = 0;
  MAX_ZOOM_LVL: number = 190; //absolute max is 199
  ZOOM_FACTOR: number = 100;
  zoomLevel: number = 100;

  canvas: any; // decision-tree canvas
  canvasPosition: any; // decision-tree canvas position
  selectedShape: any; // decision-tree shape
  displayShapeForm: boolean = false;
  isReadOnly: boolean = false;


  private menuItems: any[];

  private toast: Toast | null;

  constructor(props: Props) {
    super(props);
    this.toast = null;
    this.state = {
      activeTool: "form",
      showGoToAdminDialog: false,
      showGoToFormDialog: false,
      isDirtyJS: false,
      isDirtyHTML: false,
    };
    this.menuItems = [
      {
        //label: 'Formulaire',
        icon: 'formIcon',
        target: 'form'
      },
      {
        //label: 'Exigence', 
        icon: 'requirementIcon',
        target: 'requirement'
      },
      {
        //label: 'Arbre',
        icon: 'treeIcon',
        target: 'tree'
      },
      {
        //label: 'Script',
        icon: 'scriptIcon',
        target: 'script'
      },
      {
        //label: 'Html',
        icon: 'htmlIcon',
        target: 'html'

      },
      {
        icon: "pi pi-sitemap",
        target: "diagram"
      }
    ];
    this.state = {
      activeTool: this.menuItems[0],
      showGoToAdminDialog: false,
      showGoToFormDialog: false,
      isDirtyJS: false,
      isDirtyHTML: false,
    };

  }

  handleEditorDirtyChangeJS = (isDirtyJS) => {
    this.setState({ isDirtyJS });
  }

  handleEditorDirtyChangeHTML = (isDirtyHTML) => {
    this.setState({ isDirtyHTML });
  }

  onTabChange = (e) => {
    if (this.state.isDirtyJS || this.state.isDirtyHTML) {
      const confirmLeave = window.confirm('Are you sure you want to leave this page? Unsaved changes will be lost.');
      if (!confirmLeave) {
        return;
      }
    }
    this.setState({ activeTool: e.value, isDirtyJS: false, isDirtyHTML: false });
  }

  render() {
    return (
      <div className="mainDecisionTreeEditor">
        <div className="topLigne"/>

        {/* NAVIGATOR*/}
        <div className="tabMenu">
          <TabMenu
            model={this.menuItems}
            activeItem={this.state.activeTool}
            onTabChange={this.onTabChange}
          />
        </div>

        {
          this.state.activeTool.target === "form" &&
            <>
                <FormEditor/>
            </>
        }

        {this.state.activeTool.target === "requirement" &&
            <>
                <RequirementEditor/>
            </>
        }

        {this.state.activeTool.target === "tree" &&
            <>
                <Canvas/>
            </>
        }

        {this.state.activeTool.target === "script" &&
            <>
                <CodeEditorJS
                    onDirtyChange={this.handleEditorDirtyChangeJS}
                />
            </>
        }
        {this.state.activeTool.target === "html" &&
            <>
                <CodeEditorHTML onDirtyChange={this.handleEditorDirtyChangeHTML}/>
            </>
        }
        {this.state.activeTool.target === "diagram" &&
            <>
                <DiagramEditor/>
            </>
        }
        <div className="float">
          <a className="float-button" onClick={() => this.setState({ showGoToAdminDialog: true })}>
            <i className="pi pi-cog"></i>
          </a>
          <a className="float-button" onClick={() => this.setState({ showGoToFormDialog: true })}>
            <i className="pi pi-sign-out"></i>
          </a>
        </div>
        <Dialog
            header="Confirmation"
            visible={this.state.showGoToAdminDialog}
            modal
            style={{ width: "350px" }}
            footer={this.goToButton("/admin")}
            onHide={() => this.setState({ showGoToAdminDialog: false })}
        >
          <div className="confirmation-content">
            <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
            />
            <span>Do you want to go to admin page ?</span>
          </div>
        </Dialog>
        <Dialog
            header="Confirmation"
            visible={this.state.showGoToFormDialog}
            modal
            style={{ width: "350px" }}
            footer={this.goToButton("/")}
            onHide={() => this.setState({ showGoToFormDialog: false })}
        >
          <div className="confirmation-content">
            <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
            />
            <span>Do you want to go to form page ?</span>
          </div>
        </Dialog>
      </div>
    );
  }

  private goToButton(path: string) {
    return (
        <div style={{ display: "flex" }}>
          <Button
              onClick={() => window.location.href = path}>Yes</Button>
          <Button
                  className="p-button-danger"
                  onClick={() => {
                    this.setState({
                      showGoToAdminDialog: false,
                      showGoToFormDialog: false
                    })
                  }
          }>No</Button>
        </div>
    )
  }
}

export default withTranslation()(DecisionTreeEditorPage);
