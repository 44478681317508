import { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { ToggleButton } from 'primereact/togglebutton';
import { AutoComplete } from 'primereact/autocomplete';

import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { FormService } from "../../form/services/FormService";
import { TranslationService } from '../../form/services/TranslationService';
import { RequirementsService } from '../services/RequirementsService';

import RequirementSidebar from "./components/RequirementSidebar";
import "./RequirementEditor.scss";
import { Requirement } from "../models/Requirement";
import { Requirements } from "../models/Requirements";
import { Dropdown } from "primereact/dropdown";
import { DecisionTreeEditorService } from "../services/DecisionTreeEditorService";
import { DecisionTreeService } from "../../form/services/DecisionTreeService";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

interface Props extends WithTranslation {
}

interface States {
  displayConfirmation: boolean;
  requirements: Requirement[];
  group: string | { name: string },
  groups: { name: string | undefined }[];
  filteredGroups: { name: string | undefined }[];
  requirementName: string,
  requirementTitle: { name: string },
  answerName: { type: string, target: string },
  suffix: string | { name: string },
  prefix: string | { name: string },
  uppercase: boolean,
  refreshRequirementsState: boolean,
  prismicKeys: { name: string }[];
  schemas: { type: string, target: string }[];
  decisionTrees: { type: string, target: string }[];
  allDecisionTrees: { type: string, target: string }[];
  prefixes: { name: string | undefined }[];
  suffixes: { name: string | undefined }[];
  filteredPrefixes: { name: string | undefined }[];
  filteredSuffixes: { name: string | undefined }[];
  decisionTreeList: any[];
  displayNewRequirementConfirmation: boolean;
  selectedRequirement: any;
  displayDeleteConfirmation: boolean;
  deleteRequirement: boolean,
  downloadRequirement: boolean,
  isActive: boolean;
  currentRequirement: Requirement | null;
}


class RequirementEditor extends React.Component<Props, States> {


  private toast: Toast | null;
  private t: TFunction;
  private formService: FormService;
  private decisionTreeEditorService: DecisionTreeEditorService;

  private translationService: TranslationService;
  private requirementService: RequirementsService;
  private decisionTreeService: DecisionTreeService;

  private leftContents: any;
  private rightContents: any;


  constructor(props: Props) {
    super(props);
    this.t = this.props.t;
    this.toast = null;
    this.formService = new FormService();

    this.translationService = new TranslationService();
    this.requirementService = new RequirementsService();
    this.decisionTreeEditorService = new DecisionTreeEditorService();
    this.decisionTreeService = new DecisionTreeService();

    this.state = {
      requirements: [],
      displayConfirmation: false,
      group: "",
      groups: [],
      filteredGroups: [],
      requirementName: "",
      requirementTitle: { name: "" },
      answerName: { type: "", target: "" },
      suffix: "",
      prefix: "",
      uppercase: false,
      prismicKeys: [],
      refreshRequirementsState: false,
      schemas: [],
      decisionTrees: [],
      allDecisionTrees: [],

      prefixes: [],
      suffixes: [],
      filteredPrefixes: [],
      filteredSuffixes: [],
      decisionTreeList: [],
      displayNewRequirementConfirmation: false,
      selectedRequirement: null,
      displayDeleteConfirmation: false,
      deleteRequirement: false,
      downloadRequirement: false,
      isActive: false,
      currentRequirement: null,
    };
    this.resetEditor = this.resetEditor.bind(this);
    this.handleUpload = this.handleUpload.bind(this);

    this.searchGroup = this.searchGroup.bind(this);
    this.searchPrefix = this.searchPrefix.bind(this);
    this.searchSuffix = this.searchSuffix.bind(this);

  }


  componentDidMount() {

    this.getPrismicKeys().then(() => {
      this.getRequirements();
    });
    this.getDecisionTrees();
    this.getSchemas();

  }


  /**
   * Load requirements
   */
  private getRequirements() {
    this.requirementService
      .getMany()
      .then((response: AxiosResponse<Requirements[]>) => {

        let groups: { name: string | undefined }[] = [{ name: "" }];
        let prefixes: { name: string | undefined }[] = [{ name: '' }];
        let suffixes: { name: string | undefined }[] = [{ name: "" }];

        let requirementsArrived = response.data[0].requirements;

        let counter = 0;

        let groupAlreadyStored: any[] = [];
        let prefixAlreadyStored: any[] = [];
        let suffixAlreadyStored: any[] = [];

        let foundPrefix = false;
        let foundSuffix = false;

        for (let i = 0; i < requirementsArrived.length; i++) {

          if (requirementsArrived[i].group) {
            if (!groupAlreadyStored.includes(requirementsArrived[i].group)) {
              groupAlreadyStored.push(requirementsArrived[i].group);
              groups[counter] = { name: requirementsArrived[i].group };
            }
          }

          if (requirementsArrived[i].prefix) {
            prefixAlreadyStored.push(requirementsArrived[i].prefix);
            prefixes[counter] = { name: requirementsArrived[i].prefix };
          }
          if (requirementsArrived[i].suffix) {
            suffixAlreadyStored.push(requirementsArrived[i].suffix);
            suffixes[counter] = { name: requirementsArrived[i].suffix };
          }

          if (
            requirementsArrived[i].group
            ||
            requirementsArrived[i].suffix
            ||
            requirementsArrived[i].prefix
            &&
            !groupAlreadyStored.includes(requirementsArrived[i].group)
          ) {
            counter++;
          }
        }

        /* DELETE EMPTY ELEMENT AND ELEMENT.NAME EQUALS TO "" */
        for (let i = 0; i < groups.length; i++) {
          if (!groups[i] || groups[i].name === "") {
            let index = groups.indexOf(groups[i]);
            if (index > -1) {
              groups.splice(index, 1);
            }
          }
        }
        for (let i = 0; i < prefixes.length; i++) {
          if (!prefixes[i] || prefixes[i].name === "") {
            let index = prefixes.indexOf(prefixes[i]);
            if (index > -1) {
              prefixes.splice(index, 1);
            }
          }
        }
        for (let i = 0; i < suffixes.length; i++) {
          if (!suffixes[i] || suffixes[i].name === "") {
            let index = suffixes.indexOf(suffixes[i]);
            if (index > -1) {
              suffixes.splice(index, 1);
            }
          }
        }

        groups = groups.filter(function (el) {
          return el != null;
        });
        prefixes = prefixes.filter(function (el) {
          return el != null;
        });
        suffixes = suffixes.filter(function (el) {
          return el != null;
        });

        for (let i = 0; i < groups.length; i++) {
          if (this.state.groups.includes(groups[i])) {
            groups.splice(i, 1);
          }
        }

        /* Delete duplicate possibly pushed by isPrismic keys */
        prefixes = this.uniq_fast(prefixes);
        suffixes = this.uniq_fast(suffixes);

        /* Set values */
        this.setState(prevState => (
          {
            groups: groups,
            prefixes: [...prefixes, ...prevState.prefixes],
            suffixes: [...suffixes, ...prevState.suffixes]
          })
        );

        /* REQUIREMENT */
        this.setState({ requirements: requirementsArrived });
      });
  }

  private uniq_fast(a) {
    let seen = {};
    let out: any[] = [];
    let len = a.length;
    let j = 0;
    for (let i = 0; i < len; i++) {
      let item = a[i];
      if (seen[item] !== 1) {
        seen[item] = 1;
        out[j++] = item;
      }
    }
    return out;
  }

  /**
   * Load decision trees
   */
  private getDecisionTrees() {
    this.decisionTreeService.getAllDecisionTrees().then((res) => {

      let decisionTreesTransform: { type: string, target: string }[] = [];
      for (let i = 0; i < res.data.length; i++) {
        let name: string = res.data[i].name;
        decisionTreesTransform.push({ type: "decision_tree", target: name });
      }

      this.setState({
        decisionTrees: decisionTreesTransform
      });

      this.setState({
        allDecisionTrees: [...decisionTreesTransform]
      });
    });

    this.decisionTreeService.getAllDecisionTrees().then((res: any) => {
      if (res.data) {
        const data = [] as any;
        for (const dt of res.data) {
          data.push({ type: "decision_tree", target: dt.name });
        }
        this.setState({
          decisionTreeList: data
        });
      }
    });
  }

  /**
   * Load schemas
   */
  private getSchemas() {
    this.decisionTreeEditorService.findAll().then((res) => {

      let schemasTransform: { type: string, target: string }[] = [];
      for (let i = 0; i < res.data.length; i++) {
        let name: string = res.data[i].name;
        schemasTransform.push({ type: "decision_tree", target: name });
      }

      this.setState({
        schemas: schemasTransform
      });

      this.setState(prevState => ({
        allDecisionTrees: [...schemasTransform, ...prevState.allDecisionTrees]
      }));
    });
  }

  /**
   * Load isPrismic keys
   */
  private async getPrismicKeys() {
    await this.translationService.get('fr')
      .then((response) => {
        return response;
      })
      .then((data) => {

        let transformObj = [{ name: '' }];

        for (let i = 0; i < Object.keys(data.data).length; i++) {
          transformObj[i] = { name: Object.keys(data.data)[i] };
        }
        this.setState({ prismicKeys: transformObj });

        /* Delete duplicate possibly pushed by isPrismic keys */
        for (let i = 0; i < transformObj.length; i++) {
          if (this.state.prefixes.includes(transformObj[i])) {

            transformObj.splice(i, 1);
          }
          if (this.state.suffixes.includes(transformObj[i])) {
            transformObj.splice(i, 1);
          }
        }

        this.setState(prevState => ({
          prefixes: [...transformObj, ...prevState.prefixes],
          suffixes: [...transformObj, ...prevState.suffixes]
        }));
      });
  }

  refreshRequirement = (requirement) => {


    if (!requirement.requirement) {
      requirement.requirement = "";
    }
    if (!requirement.answer) {
      requirement.answer = { type: "", target: "" };
    }
    if (!requirement.requirementTitle) {
      requirement.requirementTitle = { name: "" };
    }
    if (!requirement.prefix) {
      requirement.prefix = "";
    }
    if (!requirement.suffix) {
      requirement.suffix = "";
    }
    if (!requirement.uppercase) {
      requirement.uppercase = false;
    }

    this.setState(
      {
        selectedRequirement: requirement,
        group: requirement.group,
        requirementName: requirement.requirement,
        answerName: requirement.answer,
        requirementTitle: { name: requirement.requirementTitle },
        prefix: requirement.prefix,
        suffix: requirement.suffix,
        uppercase: requirement.uppercase
      }
    );
  };

  private searchGroup(event) {
    setTimeout(() => {
      let filteredGroups;
      if (!event.query.trim().length) {
        filteredGroups = [...this.state.groups];
      }
      else {
        filteredGroups = this.state.groups.filter((group) => {
          return group.name?.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      this.setState({ filteredGroups });
    }, 250);
  }

  private searchPrefix(event) {
    setTimeout(() => {

      let filteredPrefixes: { name: string | undefined }[];

      if (!event.query.trim().length) {
        filteredPrefixes = [...this.state.prefixes];
      }
      else {
        filteredPrefixes = this.state.prefixes.filter((prefix) => {
          return prefix.name?.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      this.setState({ filteredPrefixes });
    }, 250);
  }

  private searchSuffix(event) {
    setTimeout(() => {
      let filteredSuffixes;
      if (!event.query.trim().length) {
        filteredSuffixes = [...this.state.suffixes];
      }
      else {
        filteredSuffixes = this.state.suffixes.filter((suffix) => {
          return suffix.name?.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      this.setState({ filteredSuffixes });
    }, 250);
  }


  /**
   * On upload for one script
   */
  handleUpload() {

    let group = this.state.group;
    let requirementName = this.state.requirementName;
    let requirementTitle = this.state.requirementTitle;
    let answerName = this.state.answerName;

    let suffix = "";
    let prefix = "";

    /* let suffix = this.state.suffix;
    let prefix = this.state.prefix; */

    if (typeof this.state.suffix === 'string') {
      suffix = this.state.suffix;
    }
    else {
      suffix = this.state.suffix.name;
    }

    if (typeof this.state.prefix === 'string') {
      prefix = this.state.prefix;
    }
    else {
      prefix = this.state.prefix.name;
    }


    let uppercase = this.state.uppercase;

    if (typeof this.state.group === 'string') {
      group = this.state.group;
    }
    else {
      group = this.state.group.name;
    }

    if (!group || group === "") {
      this.toast?.show({
        severity: SeverityEnum.ERROR,
        detail: `Please enter a group name !`,
      });
      return;
    }
    else if (!requirementName) {
      this.toast?.show({
        severity: SeverityEnum.ERROR,
        detail: `Please enter a requirement name !`,
      });
      return;
    }
    else if (!requirementTitle.name || requirementTitle.name === "") {
      this.toast?.show({
        severity: SeverityEnum.ERROR,
        detail: `Please select the title requirement !`,
      });
      return;
    }
    else if (!answerName || answerName.target === "") {
      this.toast?.show({
        severity: SeverityEnum.ERROR,
        detail: `Please select the answer of the requirement !`,
      });
      return;
    }


    let requi = new Requirement(
      group,
      requirementName,
      requirementTitle.name,
      answerName,
      prefix,
      suffix,
      uppercase,
      this.state.isActive
    );

    const r = this.state.requirements as any;
    if (!r.length) {
      r[0] = requi;
    }
    for (let i = 0; i < r.length; i++) {
      if (r[i].requirement === requi.requirement) {
        r[i] = requi;
        break;
      }
      else if (
        r[i].requirement !== requi.requirement
        &&
        r[r.length - 1] === r[i]
      ) {
        r.push(requi);
        break;
      }
    }
    this.setState({
      requirements: r
    });

    const dtoRequi = new Requirements(
      'default',
      this.state.requirements
    );

    const jsonString = JSON.stringify(dtoRequi);

    let f = new File([jsonString], "data.json", { type: "application/json" });
    this.requirementService
      .uploadRequirements(f)
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: `Success - Requirement uploaded`,
        });
        this.setState({ refreshRequirementsState: true });
      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `Requirement NOT uploaded : ${message}`,
        });
      });
  }

  resetEditor() {

    this.setState({
      requirements: [],
      displayConfirmation: false,
      group: "",
      groups: [],
      filteredGroups: [],
      requirementName: "",
      requirementTitle: { name: "" },
      answerName: { type: "", target: "" },
      suffix: "",
      prefix: "",
      uppercase: false,
      prismicKeys: [],
      refreshRequirementsState: false,
      schemas: [],
      decisionTrees: [],
      allDecisionTrees: [],
      currentRequirement: null,
      isActive: true,

      prefixes: [],
      suffixes: [],
      filteredPrefixes: [],
      filteredSuffixes: [],
    }, () => {
      this.getRequirements();
      this.getPrismicKeys();
      this.getSchemas();
      this.getDecisionTrees();
    });


  }

  handleDeletedRequirement = () => {
    this.resetEditor();
  };

  handleNewScript() {
    this.setState({ displayNewRequirementConfirmation: false });
    this.resetEditor();
  }

  renderDeleteDialogFooter() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.setState({ displayConfirmation: false })}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => this.setState({ deleteRequirement: true })}
          autoFocus
        />
      </div>
    );
  }


  render() {
    this.leftContents = (
      <React.Fragment>
        <h2 className="editorTitle">Requirement editor</h2>
        <div className="toolbarCenterRequirement">
          <InputText
            value={this.state.requirementName}
            onChange={(e) => this.setState({ requirementName: e.currentTarget.value })}
            placeholder="Requirement name"
            style={{ width: '20rem' }}
            className="requirementInput"
          />
          <div id="isActiveCheckbox">
            <div>
              <label>Active: </label>
              <Checkbox checked={this.state.isActive}
                        onChange={e => {
                          if (this.state.currentRequirement) {
                            this.requirementService.activeOne(this.state.requirementName, e.checked).then(res => {
                              if (res.status === 201) {
                                this.toast?.show({
                                  severity: SeverityEnum.SUCCESS,
                                  detail: `Requirement updated!`,
                                });
                                const currentRequirement = this.state.currentRequirement as any;
                                if (currentRequirement) {
                                  currentRequirement.isActive = e.checked;
                                }
                                this.setState({ isActive: e.checked, refreshRequirementsState: true, currentRequirement });
                              }
                            });
                          }
                          else {
                            this.setState({ isActive: e.checked });
                          }
                        }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    this.rightContents = (
      <div className="action">
        <Button
          type="button"
          icon="pi pi-download"
          className="p-button-danger"
          style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
          onClick={() => this.setState({
            downloadRequirement: true
          }, () => this.setState({
            downloadRequirement: false
          }))}
        />
        <Button
          icon="pi pi-save"
          className="p-button-danger"
          style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
          onClick={this.handleUpload}
        />
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => {
            if (!this.state.selectedRequirement) {
              this.toast?.show({
                severity: SeverityEnum.ERROR,
                summary: 'Error',
                detail: `Please select a requirement`,
              });
              return;
            }
            else {
              this.setState({ deleteRequirement: true });
            }
          }}
        />
      </div>
    );

    return (
      <div className="editor-script">
        <div className="sideBar">
          <RequirementSidebar
            requirementToViewCallback={this.refreshRequirement}
            reloadRequirements={this.state.refreshRequirementsState}
            resetRefreshRequirementsState={() => this.setState({ refreshRequirementsState: false })}
            deletedRequirement={this.handleDeletedRequirement}
            deleteRequirementAction={this.state.deleteRequirement}
            resetDeleteRequirementAction={() => this.setState({ deleteRequirement: false })}
            actionDownloadRequirement={this.state.downloadRequirement}
            setCurrentRequirement={(currentRequirement) => {
              this.setState({ currentRequirement, isActive: (currentRequirement.isActive === undefined) ? true : currentRequirement.isActive });
            }}
          />
        </div>
        <Toolbar left={this.leftContents} right={this.rightContents}/>
        <div className="workPlace" id="requirementEditorArea">
          <Card>
            <Toast ref={(el) => (this.toast = el)}/>

            <div className="p-grid" id="requirementEditor">

              <div className="p-col-12-jc-center" id="requirementArea">

                <div className="inputtext-group">
                  <div className="inputtext-area">
                    <span className="sub-title">{"Group"}</span>
                    <span className="p-float-label">

                      <AutoComplete
                        value={this.state.group}
                        suggestions={this.state.filteredGroups}
                        completeMethod={this.searchGroup}
                        field="name"
                        dropdown
                        onChange={(e) => this.setState({ group: e.value })}
                      />

                  </span>
                  </div>
                </div>

                <div className="dropdown-answer-requi">
                  <div className="dropdown-area">
                    <span className="sub-title">{"Answer"}</span>
                    <span className="p-float-label">
                      <Dropdown
                        value={this.state.answerName}
                        options={this.state.decisionTreeList}
                        onChange={(e) => {
                          this.setState({ answerName: e.value });
                        }}
                        optionLabel="target"
                        filter
                        filterBy="target"
                        placeholder={"Select the answer target"}
                      />
                    </span>
                  </div>
                  <div className="dropdown-area">
                    <span className="sub-title">{"Requirement title"}</span>
                    <span className="p-float-label">
                      <Dropdown
                        value={this.state.requirementTitle}
                        options={this.state.prismicKeys}
                        onChange={(e) => {
                          this.setState({ requirementTitle: e.value });
                        }}
                        optionLabel="name"
                        filter
                        filterBy="name"
                        placeholder={"Select a isPrismic key"}
                      />
                    </span>
                  </div>
                </div>

                <div className="inputtext-suffix-prefix">
                  <div className="inputtext-area">
                    <span className="sub-title">{"Prefix"}</span>
                    <span className="p-float-label">
                      {/* <Input.Text 
                      value={this.state.prefix} 
                      onChange={(e) => this.setState({ prefix: e.currentTarget.value  })}

                      placeholder="Prefix"
                      style={{width: '20rem'}}
                      />  */}

                      <AutoComplete
                        value={this.state.prefix}
                        suggestions={this.state.filteredPrefixes}
                        completeMethod={this.searchPrefix}
                        field="name"
                        dropdown
                        onChange={(e) => this.setState({ prefix: e.value })}
                      />

                  </span>
                  </div>
                  <div className="inputtext-area">
                    <span className="sub-title">{"Suffix"}</span>
                    <span className="p-float-label">

                      <AutoComplete
                        value={this.state.suffix}
                        suggestions={this.state.filteredSuffixes}
                        completeMethod={this.searchSuffix}
                        field="name"
                        dropdown
                        onChange={(e) => this.setState({ suffix: e.value })}
                      />

                  </span>
                  </div>

                </div>
                <div className="checkbox-uppercase">
                  <span className="sub-title">{"Uppercase"}</span>
                  <ToggleButton
                    checked={this.state.uppercase}
                    onChange={(e) => this.setState({ uppercase: e.value })}
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }

}

export default withTranslation()(RequirementEditor);
