import {TFunction} from "i18next";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {FileUpload} from "primereact/fileupload";
import {Toast} from "primereact/toast";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {SeverityEnum} from "../../shared/enum/SeverityEnum";
import {FormService} from '../../form/services/FormService';
import {AdminTemplateWordService} from "../services/AdminTemplateWordService";
import {AxiosResponse} from "axios";
import {FileUtils} from "../../shared/utils/FileUtils";

interface Props extends WithTranslation {
}

interface States {
}

class AdminTemplateWord extends React.Component<Props, States> {
    private readonly fService: FormService;
    private readonly adminTemplateWordService: AdminTemplateWordService;
    private toast: Toast | null;
    private fileUpload: FileUpload | null;
    private t: TFunction;

    constructor(props: Props) {
        super(props);
        this.fService = new FormService();
        this.adminTemplateWordService = new AdminTemplateWordService();
        this.t = this.props.t;
        this.toast = null;
        this.fileUpload = null;
    }

    /**
     * On upload
     */
    handleUpload(e: { files: File }) {
        if (!e.files) {
            return;
        }
        this.adminTemplateWordService
            .uploadOne(e.files[0])
            .then(() => {
                this.toast?.show({
                    severity: SeverityEnum.SUCCESS,
                    detail: this.fService.t(this.t, "ui_admin_template_word_toast_success", false),
                });
                this.fileUpload?.clear();
            })
            .catch((err: any) => {
                const message = err?.response?.data?.message;
                this.toast?.show({
                    severity: SeverityEnum.ERROR,
                    detail: `${this.fService.t(this.t, "ui_admin_template_word_toast_error", false)} : ${message}`,
                });
            });
    }

    /**
     * On download
     */
  handleDownload() {
    this.adminTemplateWordService.downloadOne('default').then((response: AxiosResponse<Blob>) => {
      FileUtils.downloadFile(response);
    });
  }

    render() {
        return (
            <Card title={this.fService.t(this.t, "ui_admin_template_word_title", false)}>
                <Toast ref={(el) => (this.toast = el)}/>
                <Button
                    label={this.fService.t(this.t, "ui_admin_template_word_download_button", false)}
                    className="p-mb-2"
                    onClick={() => this.handleDownload()}
                />
                <FileUpload
                    ref={(el) => (this.fileUpload = el)}
                    accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    customUpload={true}
                    uploadHandler={(e: { files: File }) => this.handleUpload(e)}

                    // emptyTemplate={() => <p className="p-m-0">{this.t("ui_admin_form_upload")}</p>}
                ></FileUpload>
            </Card>
        )
    }
}

export default withTranslation()(AdminTemplateWord);
