import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button } from "@bbri/ui";
import { TFunction } from 'i18next';
import "./About.scss";
import { FormService } from "../../form/services/FormService";
import ReactGA from "react-ga";
import { ReactGAUtils } from "../utils/ReactGAUtils";
import {Dialog} from "primereact/dialog";
import {AuthService} from "../../login/services/AuthService";

interface Props extends WithTranslation {
}

interface States {
    showDialog: boolean
}

class LogoutIcon extends Component<Props, States> {

    private t: TFunction;
    private formService: FormService;
    private authService : AuthService;

    constructor(props: Props) {
        super(props);
        this.t = this.props.t;
        this.formService = new FormService();
        this.authService = new AuthService();
        this.state = {
            showDialog: false
        }
    }

    handleClick() {
        this.setState({
            showDialog: true
        })
    }

    render() {
        return (
            <div style={{ marginRight: "10px" }}>
                <i style={{ cursor: "pointer", fontSize: "1.5rem" }} className="pi pi-sign-out" onClick={() => this.handleClick()}></i>
                <Dialog
                    header={this.formService.t(this.props.t, "ui_sign_out_confirmation", false)}
                    visible={this.state.showDialog}
                    style={{ width: "350px" }}
                    onHide={() => this.setState({ showDialog: false})}>
                    <div style={{ display: "flex" }}>
                        <Button onClick={() => {
                            this.authService.logout()
                                .then(() => {
                                    window.location.href = '/home';
                                })
                        }}>{this.formService.t(this.props.t, "oui", false)}</Button>
                        <Button onClick={() => this.setState({ showDialog: false })}>{this.formService.t(this.props.t, "non", false)}</Button>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default withTranslation()(LogoutIcon);
