import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./styles/global.scss";
import FormLoading from "./form/components/FormLoading";
import {Offline} from "react-detect-offline";
import NoConnectionModal from "./form/components/NoConnectionModal";
import {Network} from "@capacitor/network";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./shared/config/ssoAuthConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const apiUrl = process.env.REACT_APP_API_URL

ReactDOM.render(
  <FormLoading/>,
  document.getElementById("root")
)

Network.addListener('networkStatusChange', status => {
  if (status.connected) {
    window.location.reload();
    return;
  } else {
    ReactDOM.render(
      <React.Suspense fallback={<FormLoading/>}>
        {<Offline polling={{
          url: `${apiUrl}/test-connection`
        }}>
          <NoConnectionModal/>
        </Offline>}
      </React.Suspense>,
      document.getElementById("root")
    )
  }
});

Network.getStatus().then(status => {
  if (status.connected) {
    ReactDOM.render(
      <React.Suspense fallback={<FormLoading/>}>
        <React.StrictMode>
          <MsalProvider instance={msalInstance}>
            <App/>
          </MsalProvider>
        </React.StrictMode>
        {<Offline polling={{
          url: `${apiUrl}/test-connection`
        }}>
          <NoConnectionModal/>
        </Offline>}
      </React.Suspense>,
      document.getElementById("root")
    )
  } else {
    ReactDOM.render(
      <React.Suspense fallback={<FormLoading/>}>
        {<Offline polling={{
          url: `${apiUrl}/test-connection`
        }}>
          <NoConnectionModal/>
        </Offline>}
      </React.Suspense>,
      document.getElementById("root")
    )
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
