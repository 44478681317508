import { withTranslation, WithTranslation } from "react-i18next";
import React, { ReactNode } from "react";
import { TFunction } from "i18next";
import { FormService } from "../services/FormService";
import { Checkbox } from "@bbri/ui";
import { FormQuestion } from "../models/FormQuestion";
import {Tooltip} from "primereact/tooltip";
import {Button} from "primereact/button";
import "./FormCheckbox.scss"

export const separatorKey = "[[SEP]]"
export const tooltipKey = "[[TOOLTIP]]"

export const infoKey = "[[INFO]]"

export const regexKey = /(\[\[TOOLTIP]]|\[\[INFO]])/

export const getValueOfTag = (tag : string, value: string | undefined) => {
  if (!value) {
    return ""
  }
  const keys = value.split(regexKey)
  if (keys.includes(tag)) {
    const indexOfTag = keys.indexOf(tag)
    if (keys.length > indexOfTag + 1) {
      return keys[indexOfTag + 1]
    }
  }
}

export const modifyValueOfTag = (tag : string, value: string, newValue : string) => {
  const keys = value.split(regexKey)
  if (keys.includes(tag)) {
    const indexOfTag = keys.indexOf(tag)
    if (newValue === "") {
      keys.splice(indexOfTag, 2)
    }
    else if (keys.length > indexOfTag + 1) {
      keys[indexOfTag + 1] = newValue
    }
    return keys.join("")
  }
  else {
    return value + tag + newValue
  }
}

interface Props extends WithTranslation {
  question: FormQuestion;
  currentValues: string[];
  values: any[];
  onValueChange: (v: any) => void;
  name: string;
}

class FormCheckbox extends React.Component<Props> {
  private t: TFunction;
  private formService: FormService;
  private valueList: string[] = [];

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.t = this.props.t;
  }

  onValueChange(v: any) {
    this.setState({ ...this.state, value: v });
  }

  render() {
    const list: ReactNode[] = [];
    const values = this.props.currentValues || [];

    for (let i = 0; i < values.length; i++) {
      const key = `${this.props.name}${i}`;
      const keys = values[i].split(regexKey);
      const value = keys[0];

      let tooltip = getValueOfTag(tooltipKey, values[i]);
      let info = getValueOfTag(infoKey, values[i]);

      const tooltipElement = <>
        {tooltip && (
            <>
              <i style={{ marginLeft: "5px" }} className={`pi pi-search a_${key}`}/>
              <Tooltip target={`.a_${key}`}>
                {this.formService.t(this.t, tooltip, false)}
              </Tooltip>
            </>
        )}
        {info &&
            <>
              <i style={{ marginLeft: "5px" }} className={`pi pi-thumbs-up i_${key}`}/>
              <Tooltip target={`.i_${key}`}>
                {this.formService.t(this.t, info, false)}
              </Tooltip>
            </>
        }
      </>

      if (value.includes(separatorKey)) {
        list.push(<div style={{ display: "flex" }}>
          <h3 className={`a_${key}`} style={{ marginBottom: "16px" }}>{this.formService.t(this.t, value.replace(separatorKey, ""), true)}</h3>
          {tooltipElement}
        </div>)
      }
      else {
        list.push(
          <div style={{ display: "flex" }}>
            <div>
              <Checkbox
                id={key}
                key={key}
                label={this.formService.t(this.t, value, true)}
                checked={(this.props.values && this.props.values.includes(value))}
                onChange={() => {
                  this.props.onValueChange(value);
                }}
              />
            </div>
            {tooltipElement}
          </div>
        );
      }
    }

    return list;
  }
}

export default withTranslation()(FormCheckbox);
