import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {FormService} from "../../form/services/FormService";
import {TFunction} from "i18next";
import {JsPDFUtils} from "./JsPDFUtils";

export class GraphPDFUtils {
    static async timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    static async exportGraphPDF(appTitle: string,
                         graphTitle: string,
                         graphComparisonTitle: string,
                         diagramTitle: string,
                         appLogo: string,
                         bbriLogo : string) {

        JsPDFUtils.initJsPDF();

        const graphHTML = document.getElementById("graph-zone")
        const tabHTML = document.getElementById("graph-table")
        const resultHTML = document.getElementById("graph-result")
        const diagramHTML = document.getElementById("diagram-zone")

        const pdf = new jsPDF('p', 'pt', 'a4');

        const applicationTitle = appTitle
        const title = graphTitle

        let offset = 0

        const langImage = new Image();
        langImage.src = appLogo
        const logoImageRapport = langImage.width > langImage.height ? langImage.width / langImage.height : langImage.height / langImage.width;
        let width = 0
        if (logoImageRapport > 2) {
            width = 30 * logoImageRapport
        }
        else {
            width = 30 / logoImageRapport
        }
        pdf.addImage(langImage, 'png', 40, 20, width, 30);

        const bbriImage = new Image();
        bbriImage.src = bbriLogo
        const bbriImageRapport = bbriImage.width > bbriImage.height ? bbriImage.width / bbriImage.height : bbriImage.height / bbriImage.width;
        let widthBbri = 0
        if (bbriImageRapport > 2) {
            widthBbri = 30 * bbriImageRapport
        }
        else {
            widthBbri = 30 / bbriImageRapport
        }
        pdf.addImage(bbriImage, 'png', 420, 20, widthBbri, 30);

        // pdf.setFontSize(25)
        // pdf.text(applicationTitle, 40, 30 + offset)
        offset += 60
        pdf.setFontSize(18)
        pdf.setFont("Roboto-Bold", "normal")
        pdf.text(title, 40, 30 + offset)
        offset += 20
        pdf.setFont("Roboto-Regular", "normal")
        if (resultHTML) {
            pdf.setFontSize(12)
            pdf.text(resultHTML.innerText.replace("\n", ""), 40, 30 + offset, { maxWidth: 500})
            offset += 40
        }
        if (graphHTML) {
            graphHTML.classList.add("print-graph")
            await this.timeout(50)
            let canvasGraph = await html2canvas(graphHTML)
            pdf.addImage(canvasGraph.toDataURL("image/jpeg"), "JPEG", 40, 50 + offset, 480, 220)
            offset += 280
        }
        if (tabHTML) {
            const tabCanvas = await html2canvas(tabHTML)
            let imgWidth = 210;
            let pageHeight = 200;
            let imgHeight = tabCanvas.height * imgWidth / tabCanvas.width;
            let heightLeft = imgHeight;

            let position = 0

            pdf.setFontSize(18)
            pdf.setFont("Roboto-Bold", "normal")
            pdf.text(graphComparisonTitle, 40, 20 + offset)
            pdf.addImage(tabCanvas.toDataURL("image/jpeg"), "JPEG", 50, 50 + offset, 480, tabCanvas.height/3)
            heightLeft -= pageHeight

            if (heightLeft >= 0) {
                position = heightLeft - imgHeight
                console.log(position)
                pdf.addPage()
                pdf.addImage(tabCanvas.toDataURL("image/jpeg"), "JPEG", 50, position - 235, 480, tabCanvas.height/3)
            }
            offset += 40
        }
        if (diagramHTML) {
            pdf.setFontSize(18)
            pdf.setFont("Roboto-Bold", "normal")
            pdf.text(diagramTitle, 40, 30 + offset, { maxWidth: 500})
            offset += 10
            const diagramImage = diagramHTML.getElementsByTagName("img")[0]
            const svgRes = await fetch(diagramImage.src + "?get")
            let svgString = await svgRes.text()
            svgString = svgString.replace(/\r?\n|\r/g, '').trim()
            svgString = svgString.replace("svg", `svg width="${diagramImage.width}" height="${diagramImage.height}"`)
            console.log(svgString)
            const decodedSvgString = unescape(encodeURIComponent(svgString))
            const base64 = btoa(decodedSvgString)
            const imageSource = `data:image/svg+xml;base64,${base64}`
            const diagramPNG = await this.base64SvgToBase64Png(imageSource, 1900)
            pdf.addImage(diagramPNG as HTMLImageElement, "PNG", 40, offset, 500, 360)
        }

        pdf.save("pv-diagram.pdf", { returnPromise: true })
            .then(() => {
                if (graphHTML) {
                    graphHTML.classList.remove("print-graph")
                }
            })

    }

    private static base64SvgToBase64Png (originalBase64, width) {
        return new Promise(resolve => {
            let img = document.createElement('img');
            img.onload = function () {
                document.body.appendChild(img);
                let canvas = document.createElement("canvas");
                let ratio = (img.clientWidth / img.clientHeight) || 1;
                document.body.removeChild(img);
                canvas.width = width;
                canvas.height = width / ratio;
                let ctx = canvas.getContext("2d");
                if (ctx) {
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    try {
                        let data = canvas.toDataURL('image/png');
                        resolve(data);
                    } catch (e) {
                        resolve(null);
                    }
                }
                else {
                    resolve(null)
                }
            };
            img.onerror = function() {
                resolve(null);
            };
            img.src = originalBase64;
        });
    }
}