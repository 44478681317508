import { TFunction } from "i18next";
import React, { Component, MouseEvent, ReactNode } from "react";
import { X } from "react-feather";
import { WithTranslation, withTranslation } from "react-i18next";
import { Form } from "../models/Form";
import { DecisionTreeService } from "../services/DecisionTreeService";
import { FormUtils } from "../utils/FormUtils";
import "./FormStepper.scss";
import { FormService } from "../services/FormService";

interface Props extends WithTranslation {
  form: Form;
  history: number[];
  currentQuestion: number;
  displaySummaryStep: boolean;
  values: Record<string, any>;
  onStepClick: (questionId: number) => void;
}

interface States {
  steps: ReactNode[];
  isMobile: boolean;
  verticalStepper: boolean;
}

class FormStepper extends Component<Props, States> {
  private t: TFunction;
  private decisionTreeService: DecisionTreeService;
  private currentStep: number;
  private formService: FormService;

  constructor(props: Props) {
    super(props);

    this.formService = new FormService();
    this.t = this.props.t;
    this.decisionTreeService = new DecisionTreeService();
    this.currentStep = 0;

    this.state = {
      steps: [],
      isMobile: window.innerWidth < 768,
      verticalStepper: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.generateStepper();
    this.props.i18n.on("languageChanged", this.generateStepper.bind(this));
  }

  resize() {
    this.setState({
      isMobile: window.innerWidth < 768,
      verticalStepper: false,
    });
  }

  componentDidUpdate() {
    if (this.currentStep !== this.props.currentQuestion) {
      this.currentStep = this.props.currentQuestion;
      this.generateStepper();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
    this.props.i18n.off("languageChanged", this.generateStepper.bind(this));
  }

  async generateStepper() {
    const steps: ReactNode[] = [];
    const cache = new Map();

    const values = FormUtils.getValidValues(this.props.form.questions, this.props.values, this.props.history, this.props.currentQuestion, true);

    for (let i = 0; i < this.props.form.questions.length; i++) {
      const question = this.props.form.questions[i];
      const isCurrentQuestion = i === this.props.currentQuestion;

      if (isCurrentQuestion) {
        steps.push(this.createStep(i, question.shortTitle, false, true));
      }
      else if (this.props.history.includes(i)) {
        steps.push(this.createStep(i, question.shortTitle, true));
      }
      else if (
        !question.conditions ||
        (await FormUtils.checkCondition(
          this.decisionTreeService,
          question.conditions.lhs,
          question.conditions.operator,
          question.conditions.rhs,
          values,
          cache
        ))
      ) {
        steps.push(this.createStep(i, question.shortTitle));
      }
    }

    if (this.props.displaySummaryStep) {
      steps.push(this.createStep(-1, "ui_summary_title"));
    }

    this.setState({
      steps,
    });
  }

  /**
   * Handle: onClick on stepper
   *
   * For mobile & tablet device
   */
  handleStepperClick(e: MouseEvent) {
    e.stopPropagation();
    if (!this.state.isMobile) {
      return;
    }

    // Toggle vertical stepper
    this.setState({
      verticalStepper: !this.state.verticalStepper,
    });
  }

  /**
   * Handle: on Click on step item
   */
  handleStepClick(questionId: number) {
    this.props.onStepClick(questionId);

    if (this.state.verticalStepper) {
      this.setState({
        verticalStepper: false,
      });
    }
  }

  render() {
    let stepperClassName = "form-stepper-wrapper";

    if (this.state.isMobile && this.state.verticalStepper) {
      stepperClassName += " vertical-form-stepper";
    }

    return (
      <div className={stepperClassName}>
        <div className="form-stepper"
             onClick={(e: MouseEvent) =>
               this.state.isMobile && !this.state.verticalStepper
                 ? this.handleStepperClick(e)
                 : null
             }>
          <ul className={`form-step-list ${this.state.steps.length === 2 ? 'two-elements' : ''}`}>
            {this.state.steps}
          </ul>
        </div>
        {this.state.verticalStepper ? (
          <button
            type="button"
            className="vertical-form-step-close"
            onClick={(e: MouseEvent) => this.handleStepperClick(e)}
          >
            <X/>
          </button>
        ) : null}
      </div>
    );
  }

  private createStep(
    id: number,
    title: string,
    active: boolean = false,
    current: boolean = false
  ) {
    let liClassName = "";
    const canClicked = active || id === -1;

    // = Summary step
    if (id === -1) {
      liClassName += "form-step-summary ";
    }

    // = Step answered
    if (active) {
      liClassName += "form-step-active ";
    }

    // = Current step
    if (current) {
      liClassName += "form-step-current ";
    }

    return (
      <li
        key={id}
        className={liClassName}
        onClick={() =>
          (canClicked && !this.state.isMobile) ||
          (canClicked && this.state.isMobile && this.state.verticalStepper)
            ? this.handleStepClick(id)
            : null
        }
      >
        <div
          className={
            canClicked && !this.state.isMobile
              ? "form-step-circle form-step-circle-clickable"
              : "form-step-circle"
          }
        ></div>
        <div
          className="form-step-title">{this.formService.t(this.t, title, true)}</div>
      </li>
    );
  }
}

export default withTranslation()(FormStepper);
