import { TFunction } from "i18next";
import { Messages } from "primereact/messages";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Logo from "../shared/components/Logo";
import SelectLanguage from "../shared/components/SelectLanguage";
import ToolLogo from "../shared/components/ToolLogo";
import { SeverityEnum } from "../shared/enum/SeverityEnum";
import "./AnonymousPage.scss";
import { FormService } from "../form/services/FormService";
import ButtonAnonymous from "./components/ButtonAnonymous";
import { RichText } from "prismic-reactjs";
import { Button } from "@bbri/ui";
import ButtonLoginSSO from "./components/ButtonLoginSSO";


interface Props extends WithTranslation {
}

interface States {
  email: string;
  password: string;
}

class AnonymousPage extends React.Component<Props, States> {
  state: Readonly<States> = {
    email: "",
    password: "",
  };
  private messages: Messages | null;
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.messages = null;
    this.t = this.props.t;
    this.formService = new FormService();
    const formService = new FormService();

  }

  handleLogin(isAuth: boolean) {
    if (!isAuth && this.messages) {
      this.messages.replace({
        severity: SeverityEnum.ERROR,
        closable: false,
        sticky: true,
        detail: this.formService.t(this.t, "error_invalid_credentials", false, true),
      });
    }
  }

  render() {
    return (
      <div className="container">
        <div className="p-grid login-wrapper p-align-center">
          <div className="p-col-12 p-md-6 p-md-offset-3">
            <div className="p-text-center">
              <Logo/>
            </div>
            <div className="login-card">
              <div className="login-logo-wrapper">
                <SelectLanguage/>
                <ToolLogo/>
              </div>
              <div className="login-content">
                <Messages ref={(el) => (this.messages = el)}/>
                <div>
                  <h3 className="p-text-center p-mb-4 login-header">{this.formService.checkIfValue(RichText.render(this.t("sub_title_app", { returnObjects: true })), "sub_title_app")}</h3>
                  <p className="p-mb-4 login-text">
                    {this.formService.t(this.t, "introductive_text", true)}
                  </p>
                </div>
                <ButtonAnonymous
                  onLogin={(e) => this.handleLogin(e)}
                />
                {/*<Button block type="button" onClick={() => window.location.href = "/login"}>*/}
                {/*  {this.formService.t(this.t, "button_login", false, true)}*/}
                {/*</Button>*/}
                <ButtonLoginSSO>

                </ButtonLoginSSO>
              </div>
            </div>
            <div className="p-col-12 partners">
              <img src={this.formService.t(this.t, 'partners', false, false, false, true)} alt="partners"/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AnonymousPage);
