import {AxiosResponse} from 'axios';
import {APIService} from '../../shared/services/APIService';
import {DataStorage, KEY_ACCESS_TOKEN, KEY_AUTH_TYPE, KEY_SSO_USER} from '../../shared/constants';
import {LoginDTO} from '../dto/LoginDTO';
import {AuthUser} from '../models/AuthUser';
import {PublicClientApplication} from '@azure/msal-browser';
import {loginRequest, msalConfig} from '../../shared/config/ssoAuthConfig';
import {RoleEnum} from "../../shared/enum/RoleEnum";

// Create an instance of PublicClientApplication
const msalInstance = new PublicClientApplication(msalConfig);

// Handle the redirect flows
// msalInstance
//     .handleRedirectPromise()
//     .then((tokenResponse) => {
//       console.log(tokenResponse)
//       // Handle redirect response
//     })
//     .catch((error) => {
//       // Handle redirect error
//       console.log(error)
//     });

export class AuthService extends APIService {

  constructor() {
    super(false);
  }

  static get accessToken() {
    return DataStorage.getItem(KEY_ACCESS_TOKEN);
  }

  static get user(): AuthUser | null {
    const token = AuthService.accessToken;

    if (!token) {
      return null;
    }

    if (DataStorage.getItem(KEY_AUTH_TYPE) === "DEFAULT") {
      const data = AuthService.decodeJWT(token);
      if (!data) {
        return null;
      }
      return data;
    }
    else {
      const userSSOString = DataStorage.getItem(KEY_SSO_USER)
      if (userSSOString) {
        const userSSO = JSON.parse(userSSOString)
        if (userSSO) {
          return userSSO
        }
      }
      return null
    }

  }

  static get isAuth() {
    const data = AuthService.user;
    if (!data) {
      return false;
    }

    if ((data.exp * 1000) > Date.now()) {
      return true;
    }
    DataStorage.removeItem(KEY_ACCESS_TOKEN);
    return false;
  }

  /**
   * Decode a JWT
   */
  static decodeJWT(token: string) {
    let output = token.split('.')[1].replace(/-/g, "+").replace(/_/g, "/");
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += "==";
        break;
      case 3:
        output += "=";
        break;
      default:
        return null;
    }

    try {
      return JSON.parse(decodeURIComponent(atob(output).replace(/(.)/g, function (m, p) {
        let code = p.charCodeAt(0).toString(16).toUpperCase();
        if (code.length < 2) {
          code = '0' + code;
        }
        return '%' + code;
      })));
    } catch {
      return null;
    }
  }

  /**
   * Login
   */
  async login(dto: LoginDTO) {
    return this.http.post(`/auth/login`, dto)
      .then((result: AxiosResponse<{ accessToken: string; }>) => {
        DataStorage.setItem(KEY_ACCESS_TOKEN, result.data.accessToken);
        DataStorage.setItem(KEY_AUTH_TYPE, "DEFAULT")
        DataStorage.removeItem(KEY_SSO_USER)
        return AuthService.user;
      })
      .catch(() => {
        return null;
      });
  }

  async loginSSO() {
    // Popup
    // let auth
    // try {
    //   console.log(msalInstance.getActiveAccount())
    //   auth = await msalInstance.acquireTokenSilent(loginRequest)
    // }
    // catch (e) {
    //   console.log(e)
    //   try {
    //     auth = await msalInstance.acquireTokenPopup(loginRequest)
    //   }
    //   catch (e) {
    //     console.log(e)
    //   }
    // }
    // console.log("auth :", auth)

    // Redirect
    // let auth
    // try {
    //   auth = await msalInstance.acquireTokenSilent(loginRequest)
    // }
    // catch (e) {
    //   console.log(e)
    //   try {
    //     auth = await msalInstance.acquireTokenRedirect(loginRequest)
    //   }
    //   catch (e) {
    //     console.log(e)
    //   }
    // }
    // console.log(auth)

    // Just popup
    const auth = await msalInstance.acquireTokenPopup({...loginRequest})
    const user = {
      sub: auth.idTokenClaims["sub"],
      email: auth.idTokenClaims["email"],
      exp: auth.idTokenClaims["exp"],
      role: auth.idTokenClaims["roles"].includes("71300") ? RoleEnum.ADMIN : RoleEnum.USER
    }
    DataStorage.setItem(KEY_ACCESS_TOKEN, auth.idToken);
    DataStorage.setItem(KEY_AUTH_TYPE, "SSO")
    DataStorage.setItem(KEY_SSO_USER, JSON.stringify(user))
    return user
  }

  /**
   * Logout
   */
  async logout() {
    DataStorage.removeItem(KEY_ACCESS_TOKEN);
    DataStorage.removeItem(KEY_SSO_USER);
    if (DataStorage.getItem(KEY_AUTH_TYPE) === "SSO") {
      await msalInstance.logoutPopup({})
      DataStorage.setItem(KEY_AUTH_TYPE, "DEFAULT")
    }
  }
}