import { AxiosResponse } from 'axios';
import { APIService } from '../../shared/services/APIService';
import {AuthService} from "../../login/services/AuthService";
import {i18n} from "i18next";

export class SubmissionService extends APIService {

    constructor() {
        super(true);
    }

    getUserSubmissions() {
        return this.http.get("/forms/default/submissions")
    }

    saveLastSubmission(id: string, name: string) {
        return this.http.patch("/forms/default/submissions/save", { id, name }, {
            headers: {
                "Authorization": "Bearer " + AuthService.accessToken
            }
        })
    }

    changeSubmissionName(id: string, name: string) {
        return this.http.patch(`/forms/default/submissions/name/${id}?name=` + name)
    }

    changeSubmissionDescription(id: string, description: string) {
        return this.http.patch(`/forms/default/submissions/description/${id}`, { description })
    }

    archiveSubmission(id: string) {
        return this.http.patch("/forms/default/submissions/archive/" + id)
    }

    archiveMultipleSubmission(ids: string[]) {
        return this.http.patch("/forms/default/submissions/archive", ids)
    }

    deleteSubmission(id: string) {
        return this.http.delete("/forms/default/submissions/" + id)
    }

    exportSubmissionToWord(id: string, code: string) {
        return this.http.get<Blob>(`/templates/default/export-word/${id}/lang/${code}`, { responseType: 'blob' })
    }
}