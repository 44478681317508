import { TFunction } from "i18next";
import React, { ReactNode } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip, ReferenceLine, ResponsiveContainer, Text, ReferenceDot } from 'recharts';
import { Dot } from "../models/Dot"
import { Graph } from "../models/Graph"
import { FormService } from "../services/FormService";
import { Button } from "@bbri/ui";
import {GraphPDFUtils} from "../../shared/utils/GraphPDFUtils";
import "./FormPVGraph.scss"

interface Props extends WithTranslation {
    graphs: Graph[]
    onGraphClick?: (graphs: Graph[]) => void
}

interface States {
    highlightedDot: Dot[]
    activeHighlightedDot: number
    lineColors: string[]
    min: number
    max: number
}

export const colors = ["#ec6607", "#0087b7", "#00bfba", "#005979", "#9c9c9c", "#383838", "#ec6607", "#E6AC27", "#E08E79", "#EDB407"]

class FormPVGraph extends React.Component<Props, States> {

    private currentX: number
    private formService: FormService
    private t: TFunction

    constructor(props: Props) {
        super(props)
        this.currentX = 0
        this.formService = new FormService()
        this.t = this.props.t
        this.state = {
            highlightedDot: [],
            activeHighlightedDot: 0,
            lineColors: [],
            min: 0,
            max: 1
        }
    }

    componentDidMount(): void {

        this._getColors()
        if (this.props.graphs.length > 0 && this.props.graphs[0].dots.length > 0) {
            this._calculateMinMax(this.props.graphs)
        }
    }

    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        this._getColors()
        if (nextProps.graphs.length > 0 && nextProps.graphs[0].dots.length > 0) {
            this._calculateMinMax(nextProps.graphs)
        }
    }

    private _calculateMinMax(graphs: Graph[]) {

        let min = graphs[0].dots[0].x
        let max = graphs[0].dots[0].x

        for (const graph of graphs) {
            for (const dot of graph.dots) {
                if (dot.x < min) {
                    min = dot.x
                }

                if (dot.x > max) {
                    max = dot.x
                }
            }
        }

        this.setState({
            min,
            max
        })
    }

    private _getColors() {
        const colors: string[] = []
        for (const graph of this.props.graphs) {
            const color = "#" + ("00000" + Math.floor(Math.random() * Math.pow(16, 6)).toString(16)).slice(-6);
            colors.push(color)
        }
        this.setState({
            lineColors: colors
        })
    }

    handleOnMouseEnter(i) {
        if (i !== this.state.highlightedDot.length - 1) {
            const newList = [...this.state.highlightedDot]
            const temp = newList[newList.length - 1]
            newList[newList.length - 1] = newList[i]
            newList[i] = temp
            this.setState({
                highlightedDot: newList
            })
        }
    }

    async handleOnMouseMove(e) {
        this.currentX = e.activeLabel ? parseInt(e.activeLabel) : 0
    }

    private handleLineChartClick() {
        if (this.props.onGraphClick) {
            const graphs : Graph[] = []
            for (const graph of this.props.graphs) {
                const dot = graph.dots.find(d => d.x === this.currentX)
                if (dot) {
                    graphs.push({
                        title: graph.title,
                        dots: [dot]
                    })
                }
            }
            this.props.onGraphClick(graphs)
        }
    }

    private getValueForEachGraph(value, name, e) {
        const currentGraph = this.props.graphs.find(g => g.title === name)
        const currentDot = currentGraph?.dots.find(d => d.x === this.currentX)
        if (currentDot?.y) {
            return parseInt(currentDot.y.toString()) + " kW"
        }
        else {
            return ""
        }
    }

    private getTicks() {
        const result: number[] = []
        for (let i = this.state.min; i <= this.state.max; i = i + ((this.state.max - this.state.min) / 4)) {
            if (i === this.state.min) {
                result.push(Math.floor(this.state.min / 100) * 100)
            }
            else {
                result.push(Math.floor(i / 100) * 100)
            }
        }
        return result
    }

    _renderGraph() {
        return (
            <LineChart margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                       onMouseMove={(e) => this.handleOnMouseMove(e)}
                       onClick={() => this.handleLineChartClick()}>

                <XAxis type="number" dataKey="x"
                    domain={[Math.floor(this.state.min / 100) * 100, "dataMax"]}
                    label={{ value: this.formService.t(this.t, "graph_x_label", false), position: "insideBottom", dy: 10 }}
                    ticks={this.getTicks()} />
                <YAxis type="number"
                    label={{ value: this.formService.t(this.t, "graph_y_label", false), position: "insideLeft", angle: -90, dy: -10 }} />

                {this.props.graphs.length > 1 && (
                    <Legend iconType="plainline" wrapperStyle={{ paddingTop: "10px" }}></Legend>
                )}

                <Tooltip
                    cursor={this.props.graphs.length > 1}
                    formatter={(value, name, e) => this.getValueForEachGraph(value, name, e)}
                    labelFormatter={(value) => value + " L"}
                />

                {this.props.graphs.slice(0, colors.length).map((g, i) => (
                    <Line type="monotone"
                        name={g.title}
                        data={g.dots}
                        dataKey="y"
                        isAnimationActive={false}
                        stroke={this.props.graphs.length > 1 ? colors[i] : "#01A398"}
                        strokeWidth={3}
                        dot={false}
                        activeDot={this.props.graphs.length > 1 ? false : {
                            r: 5,
                            onClick: (c, event) => {
                                this.setState(state => {
                                    const dotAlreadyHighlighted = this.state.highlightedDot.find(d => d.x === event["payload"]["x"])
                                    let newList: Dot[] = []
                                    if (!dotAlreadyHighlighted) {
                                        newList = state.highlightedDot.concat({ x: event["payload"]["x"], y: Math.floor(event["payload"]["y"] * 100) / 100 })
                                    }
                                    this.setState({
                                        highlightedDot: newList
                                    })
                                })
                            },

                        }}
                    />
                ))}

                {this.state.highlightedDot.map(
                    (coord, i) => (
                        <React.Fragment key={coord.x}>
                            <ReferenceDot x={coord.x} y={coord.y}
                                r={5}
                                onClick={() => {
                                    const newList = this.state.highlightedDot.filter(d => d.x !== coord.x)
                                    this.setState({
                                        highlightedDot: newList
                                    })
                                }}
                                onMouseEnter={() => {
                                    this.handleOnMouseEnter(i)
                                }}
                                label={(props) => (<g>
                                    <rect
                                        style={{ strokeWidth: 1, stroke: "#01A398" }}
                                        x={props.viewBox.x - 60}
                                        y={props.viewBox.y - 30}
                                        fill="#A2D9DB"
                                        width={140}
                                        height={30}
                                        onMouseEnter={() => this.handleOnMouseEnter(i)}
                                    />
                                    <text x={props.viewBox.x} y={props.viewBox.y} fill="white" dy={-10} dx={-50}>
                                         {Math.floor(coord.y)} kW / {coord.x} L
                                    </text>
                                </g>)}
                                isFront={true}></ReferenceDot>
                            <ReferenceLine segment={[{ x: Math.floor(this.state.min / 100) * 100, y: coord.y }, { x: coord.x, y: coord.y }]}
                                stroke="#A2D9DB"
                                strokeDasharray="3 3" />
                            <ReferenceLine segment={[{ x: coord.x, y: 0 }, { x: coord.x, y: coord.y }]}
                                stroke="#A2D9DB"
                                strokeDasharray="3 3" />
                        </React.Fragment>
                    )
                )}
            </LineChart>

        )
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ alignSelf: "flex-end" }}>
                    <Button onClick={() => GraphPDFUtils.exportGraphPDF(
                        this.formService.t(this.t, "title_app", true),
                        this.formService.t(this.t, "ui_result_graph_title", false),
                        this.formService.t(this.t, "ui_comparaison_table_title", false),
                        this.formService.t(this.t, "ui_result_diagram_title", false),
                        this.formService.t(this.t, 'logo_app', false, false, false, true),
                        this.formService.t(this.t, 'bbri_logo', false, false, false, true)
                    )}>PDF</Button>
                </div>
                <ResponsiveContainer id="graph-zone" width="95%" height={500}>
                    {this._renderGraph()}
                </ResponsiveContainer>
            </div>
        )
    }


}

export default withTranslation()(FormPVGraph)