import {withTranslation, WithTranslation} from "react-i18next";
import React from "react";
import {FormQuestion} from "../../../form/models/FormQuestion";
import {Dropdown} from "primereact/dropdown";
import {QuestionType} from "../../../form/enum/QuestionType";
import {TranslationService} from "../../../form/services/TranslationService";
import {InputText} from "primereact/inputtext";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Dialog} from "primereact/dialog";
import "./FormEditorWorkPlace.scss";
import {Checkbox} from "primereact/checkbox";
import {DecisionTree} from "../../../admin/models/DecisionTree";
import {Fieldset} from "primereact/fieldset";
import {Toast} from "primereact/toast";
import {MultiSelect} from "primereact/multiselect";
import {OrganizationChart} from "primereact/organizationchart";
import {OperatorEnum} from "../operator.enum";
import {TabPanel, TabView} from 'primereact/tabview';
import {InputNumber} from 'primereact/inputnumber';
import {InputTextarea} from "primereact/inputtextarea";
import {SeverityEnum} from "../../../shared/enum/SeverityEnum";
import {
    getValueOfTag,
    infoKey,
    modifyValueOfTag,
    separatorKey,
    tooltipKey
} from "../../../form/components/FormCheckbox";
import {Calendar} from "primereact/calendar";
import {frBE, nlBE} from "../../../localisation";

interface States {
    currentQuestion: FormQuestion | null;
    changes: boolean;
    isEditing: boolean;
    displayAddValueDialog: boolean;
    displayAddConditionDialog: boolean;
    displayDeleteConditionDialog: boolean;
    displayEditConditionDialog: boolean;
    currentValue: any;
    currentImageValue: any;
    currentSubQuestion: any;
    currentSubQuestionType: any;
    currentImageName: any;
    currentCondition: any;
    currentConditionEdit: any;
    activeIndex: number;
    currentDropdownType: any;
    isCurrentValueSeparator: boolean;
    currentToolTip: string;
    currentInfo: string;
    displayRhsDialog: boolean,
    tempRhsSelection: any,
    editingRhsRowIndex: number | null,
}

interface Props extends WithTranslation {
    currentQuestion: any;
    prismicKeys: string[];
    questions: FormQuestion[];
    decisionTrees: DecisionTree[];
}

class FormEditorWorkPlace extends React.Component<Props, States> {

private translationService: TranslationService;
private toast: Toast | null;
private nodeCount: number;
private nodeCount2: number;
private nodes: any;

constructor(props: Props) {
    super(props);
    this.toast = null;
    this.translationService = new TranslationService();
    this.nodeCount = 0;
    this.nodeCount2 = 0;

    this.state = {
        currentQuestion: null,
        changes: false,
        isEditing: false,
        displayAddValueDialog: false,
        displayAddConditionDialog: false,
        displayDeleteConditionDialog: false,
        displayEditConditionDialog: false,
        currentValue: '',
        currentImageValue: '',
        currentSubQuestion: null,
        currentSubQuestionType: null,
        currentImageName: null,
        currentCondition: { currentNode: null, lhs: { type: null, value: null }, operator: null, rhs: { type: null, value: null } },
        currentConditionEdit: null,
        activeIndex: 0,
        currentDropdownType: "default",
        isCurrentValueSeparator: false,
        currentToolTip: "",
        currentInfo: "",
        displayRhsDialog: false,
        tempRhsSelection: null,
        editingRhsRowIndex: null,
    };
}

componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
    this.setState({
        currentQuestion: nextProps.currentQuestion,
        currentDropdownType: (nextProps.currentQuestion && nextProps.currentQuestion.target && nextProps.currentQuestion.target !== "") ? "decision_tree" : "default"
    });
}

private _setQuestionValue(key: string, value: any) {
    let currentQuestion = this.state.currentQuestion;
    if (currentQuestion) {
        currentQuestion[key] = value;
        this.setState({
            currentQuestion,
            changes: true
        });
    }
}

private _setQuestionValueWithDoubleKey(key: string[], value: any) {
    let currentQuestion = this.state.currentQuestion;
    if (currentQuestion) {
        if (!currentQuestion[key[0]]) {
            currentQuestion[key[0]] = {} as any;
        }
        currentQuestion[key[0]][key[1]] = value;
        this.setState({
            currentQuestion,
            changes: true
        });
    }
}

private _editValue(props) {
    return (<Dropdown
        value={this.state.currentQuestion?.values[props.rowIndex]}
        options={this.props.prismicKeys}
        onChange={(e) => {
            let currentQuestion = this.state.currentQuestion;
            if (currentQuestion) {
                currentQuestion.values[props.rowIndex] = e.value.name ?? e.value;
                this.setState({ currentQuestion });
            }
        }}
        placeholder="Select a City"
        filter
        editable
        appendTo={document.body}
    />);
}

private _editKeyValue(props, key) {
    return (<Dropdown
        value={getValueOfTag(key, this.state.currentQuestion?.values[props.rowIndex])}
        options={this.props.prismicKeys}
        onChange={(e) => {
            let currentQuestion = this.state.currentQuestion;
            if (currentQuestion) {
                currentQuestion.values[props.rowIndex] = modifyValueOfTag(key, currentQuestion.values[props.rowIndex], e.value.name ?? e.value);
                this.setState({ currentQuestion });
            }
        }}
        placeholder="Select a City"
        filter
        editable
        appendTo={document.body}
    />);
}

private _editImageValue(props) {
    return (<Dropdown
        value={this.state.currentQuestion?.imageValues[props.rowIndex]}
        options={this.props.prismicKeys}
        onChange={(e) => {
            let currentQuestion = this.state.currentQuestion;
            if (currentQuestion) {
                currentQuestion.imageValues[props.rowIndex] = e.value.name ?? e.value;
                this.setState({ currentQuestion });
            }
        }}
        placeholder="Select a City"
        filter
        editable
        appendTo={document.body}
    />);
}

private _actionBodyTemplate(row) {
    return (
        <React.Fragment>
            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger"
                    onClick={() => {
                        let currentQuestion = this.state.currentQuestion;
                        if (currentQuestion) {
                            switch (currentQuestion.type) {
                                case QuestionType.MULTIPLE:
                                case QuestionType.TABLE:
                                    currentQuestion.subQuestions?.splice(row.rowIndex, 1);
                                    break;
                                default:
                                    currentQuestion.values.splice(row.rowIndex, 1);
                                    currentQuestion.imageValues.splice(row.rowIndex, 1);
                            }
                            this.setState({ currentQuestion });
                        }
                    }}/>
        </React.Fragment>
    );
}

private _checkboxBodyTemplate(row) {
    return (
        <React.Fragment>
            <Checkbox
                checked={this.state.currentQuestion?.subQuestions?.[row.rowIndex].optional}
                onChange={e => {
                    let currentQuestion = this.state.currentQuestion as any;
                    if (currentQuestion?.subQuestions) {
                        currentQuestion.subQuestions[row.rowIndex].optional = e.checked;
                        this.setState({ currentQuestion });
                    }
                }}
            />
        </React.Fragment>
    );
}

private _checkboxBodyTemplatePrismic(row) {
    return (
        <React.Fragment>
            <Checkbox
                checked={this.state.currentQuestion?.subQuestions?.[row.rowIndex].isPrismic}
                onChange={e => {
                    let currentQuestion = this.state.currentQuestion as any;
                    if (currentQuestion?.subQuestions) {
                        currentQuestion.subQuestions[row.rowIndex].isPrismic = e.checked;
                        this.setState({ currentQuestion });
                    }
                }}
            />
        </React.Fragment>
    );
}

private _arrayBodyTemplate(value) {
    return (<p>{value.values?.join(', ')}</p>);
}

private _editSubQuestion(el: string, props: any) {
    let values: any[] = [];

    if (this.state.currentQuestion?.type === QuestionType.MULTIPLE) {
        // questionTypes.push(
        //     QuestionType.RADIO, QuestionType.STRING, QuestionType.NUMBER, QuestionType.DROPDOWN, QuestionType.BOOL
        // )
    }
    else if (this.state.currentQuestion?.type === QuestionType.TABLE) {
        values = [
            { name: QuestionType.RADIO, label: QuestionType.RADIO },
            { name: QuestionType.RADIO_IMAGE, label: QuestionType.RADIO_IMAGE },
            { name: QuestionType.DROPDOWN, label: QuestionType.DROPDOWN },
            { name: QuestionType.BOOL, label: QuestionType.BOOL },
            { name: QuestionType.NUMBER, label: QuestionType.NUMBER },
            { name: QuestionType.LONG_TEXT, label: QuestionType.LONG_TEXT },
            { name: QuestionType.MATRIX, label: QuestionType.MATRIX },
            { name: QuestionType.CHECKBOX, label: QuestionType.CHECKBOX },
            { name: QuestionType.STRING, label: QuestionType.STRING },
            { name: QuestionType.DATE_PICKER, label: QuestionType.DATE_PICKER },
            { name: QuestionType.PREVIOUS_QUESTION, label: QuestionType.PREVIOUS_QUESTION },
            { name: QuestionType.DECISION_TREE, label: QuestionType.DECISION_TREE },
        ];
        // questionTypes.push(
        //     QuestionType.STRING, QuestionType.NUMBER, QuestionType.DROPDOWN, QuestionType.PREVIOUS_QUESTION
        // )
    }
    if (el === 'size') {
        return (
            <div>
            </div>
        );
    }
    if (el === 'values') {
        let vTable: any[] = [];
        if (this.state.currentQuestion?.subQuestions?.[props.rowIndex][el]) {
            for (let value of this.state.currentQuestion?.subQuestions?.[props.rowIndex][el]) {
                vTable.push({ name: value, label: value });
            }
        }
        return (
            <MultiSelect
                value={vTable}
                options={this.props.prismicKeys}
                onChange={(e) => {
                    let currentQuestion = this.state.currentQuestion as any;
                    if (currentQuestion?.subQuestions) {
                        let v: any[] = [];
                        for (let value of e.value) {
                            v.push(value.name ?? value);
                        }
                        currentQuestion.subQuestions[props.rowIndex][el] = v;
                        this.setState({ currentQuestion });
                    }
                }}
                placeholder="Select a value"
                filter
                appendTo={document.body}
            />
        );
    }
    if (el === "default_value" || el === "suggested_value") {
        return (
            <Dropdown
                value={this.state.currentQuestion?.subQuestions?.[props.rowIndex][el]?.decision_tree}
                options={this.props.prismicKeys}
                onChange={(e) => {
                    let currentQuestion = this.state.currentQuestion as any;
                    if (currentQuestion?.subQuestions) {
                        currentQuestion.subQuestions[props.rowIndex][el] = {
                            decision_tree: e.value.name ? e.value.name : e.value
                        };
                        this.setState({ currentQuestion });
                    }
                }}
                placeholder="Select a decision tree"
                filter
                editable
                appendTo={document.body}
            />
        )
    }
    if (el === "target") {
        return <Dropdown
            value={this.state.currentQuestion?.subQuestions?.[props.rowIndex][el]}
            options={this.props.prismicKeys}
            onChange={(e) => {
                let currentQuestion = this.state.currentQuestion as any;
                if (currentQuestion?.subQuestions) {
                    currentQuestion.subQuestions[props.rowIndex][el] = e.value.name ? e.value.name : e.value
                    this.setState({ currentQuestion });
                }
            }}
            placeholder="Select a decision tree"
            filter
            editable
            appendTo={document.body}
        />
    }
    return (
        <Dropdown
            value={this.state.currentQuestion?.subQuestions?.[props.rowIndex][el]}
            options={(el === 'type') ? values : this.props.prismicKeys}
            onChange={(e) => {
                let currentQuestion = this.state.currentQuestion as any;
                if (currentQuestion?.subQuestions) {
                    currentQuestion.subQuestions[props.rowIndex][el] = e.value.name ? e.value.name : e.value;
                    this.setState({ currentQuestion });
                }
            }}
            placeholder={(el === 'type') ? "Select a type" : "Select a City"}
            filter
            editable
            appendTo={document.body}
        />
    );
}

private _getSizeContent(type: string) {
    switch (type) {
        case 'fixed':
            return (
                <div>
                    <label>Value: </label>
                    <InputText type="number"
                               className="sizeValueNumber p-inputtext-sm block mb-2"
                               placeholder="Enter value(s)"
                               value={this.state.currentQuestion?.size?.value}
                               onChange={(e: any) => {
                                   this._setQuestionValueWithDoubleKey(['size', 'value'], e.target.value);
                               }}
                    />
                    <div>
                        <label>Accept Copy: </label>
                        <Checkbox
                            className="block mb-2 mt-2"
                            checked={this.state.currentQuestion?.acceptCopy}
                            onChange={(e) => {
                                let currentQuestion = this.state.currentQuestion as any;

                                currentQuestion.acceptCopy = e.checked;

                                this.setState({ currentQuestion });
                            }}
                        />
                    </div>
                </div>
            );
        case QuestionType.PREVIOUS_QUESTION:
            let questionList: string[] = [];
            for (const question of this.props.questions) {
                questionList.push(question.question);
            }
            return (
                <div>
                    <label>Target: </label>
                    <Dropdown
                        value={this.state.currentQuestion?.size.target}
                        options={questionList}
                        onChange={(e) => {
                            let currentQuestion = this.state.currentQuestion as any;
                            if (currentQuestion) {
                                currentQuestion.size.target = e.value;
                                this.setState({ currentQuestion });
                            }
                        }}
                        placeholder="Select a value"
                        filter
                        editable
                        appendTo={document.body}
                    />
                    <div>
                        <label>Accept Copy: </label>
                        <Checkbox
                            className="block mb-2 mt-2"
                            checked={this.state.currentQuestion?.acceptCopy}
                            onChange={(e) => {
                                let currentQuestion = this.state.currentQuestion as any;

                                currentQuestion.acceptCopy = e.checked;

                                this.setState({ currentQuestion });
                            }}
                        />
                    </div>
                </div>
            );
        case QuestionType.DECISION_TREE:
            let decisionTreeList: string[] = [];
            for (const decisionTree of this.props.decisionTrees) {
                decisionTreeList.push(decisionTree.name);
            }
            return (
                <div>
                    <label>Target: </label>
                    <Dropdown
                        value={this.state.currentQuestion?.size.target ?? null}
                        options={decisionTreeList}
                        onChange={(e) => {
                            let currentQuestion = this.state.currentQuestion as any;
                            if (currentQuestion) {
                                currentQuestion.size.target = e.value;
                                this.setState({ currentQuestion });
                            }
                        }}
                        placeholder="Select a value"
                        filter
                        editable
                        appendTo={document.body}
                    />
                    <div>
                        <label>Accept Copy: </label>
                        <Checkbox
                            className="block mb-2 mt-2"
                            checked={this.state.currentQuestion?.acceptCopy}
                            onChange={(e) => {
                                let currentQuestion = this.state.currentQuestion as any;

                                currentQuestion.acceptCopy = e.checked;

                                this.setState({ currentQuestion });
                            }}
                        />
                    </div>
                </div>
            );
        case 'dynamic':
            return (
                <>
                    <div>
                        <label>Min: </label>
                        <InputText type="number"
                                   className="sizeValueNumber p-inputtext-sm block mb-2"
                                   placeholder="Enter minimum"
                                   value={this.state.currentQuestion?.size?.min}
                                   onChange={(e: any) => {
                                       this._setQuestionValueWithDoubleKey(['size', 'min'], e.target.value);
                                   }}
                        />
                    </div>
                    <div>
                        <label>Max: </label>
                        <InputText type="number"
                                   className="sizeValueNumber p-inputtext-sm block mb-2"
                                   placeholder="Enter maximum"
                                   value={this.state.currentQuestion?.size?.max}
                                   onChange={(e: any) => {
                                       this._setQuestionValueWithDoubleKey(['size', 'max'], e.target.value);
                                   }}
                        />
                    </div>
                    <div>
                        <label>Accept Copy: </label>
                        <Checkbox
                            className="block mb-2 mt-2"
                            checked={this.state.currentQuestion?.acceptCopy}
                            onChange={(e) => {
                                let currentQuestion = this.state.currentQuestion as any;

                                currentQuestion.acceptCopy = e.checked;

                                this.setState({ currentQuestion });
                            }}
                        />
                    </div>
                </>
            )

    }
}

getLocale() {
    const language = this.props.i18n.language;

    switch(language) {
        case 'fr':
            return frBE;
        case 'nl':
            return nlBE;
        default:
            return frBE;
    }
}

private _getContentByType(type: string[]) {
    let content: any[] = [];
    for (let i = 0; i < type.length; i++) {

        switch (type[i]) {
            case 'step':
                content.push(
                    <div className="param">
                        <label>Step: </label>
                        <InputText type="number"
                                   className="p-inputtext-sm block mb-2"
                                   placeholder="Enter value(s)"
                                   value={this.state.currentQuestion?.validation?.step}
                                   onChange={(e: any) => {
                                       this._setQuestionValueWithDoubleKey(['validation', 'step'], parseFloat(e.target.valueAsNumber));
                                   }}
                        />
                    </div>
                );
                break;
            case 'min':
                content.push(
                    <div className="param">
                        <label>Min: </label>
                        <InputText type="number"
                                   className="p-inputtext-sm block mb-2"
                                   placeholder="Enter value(s)"
                                   value={this.state.currentQuestion?.validation?.min}
                                   onChange={(e: any) => {
                                       this._setQuestionValueWithDoubleKey(['validation', 'min'], parseFloat(e.target.valueAsNumber));
                                   }}
                        />
                    </div>
                );
                break;
            case 'max':
                content.push(
                    <div className="param">
                        <label>Max: </label>
                        <InputText type="number"
                                   className="p-inputtext-sm block mb-2"
                                   placeholder="Enter value(s)"
                                   value={this.state.currentQuestion?.validation?.max}
                                   onChange={(e: any) => {
                                       this._setQuestionValueWithDoubleKey(['validation', 'max'], parseFloat(e.target.valueAsNumber));
                                   }}
                        />
                    </div>
                );
                break;
            case 'unit':
                content.push(
                    <div className="param">
                        <label>Unit: </label>
                        <Dropdown
                            value={{ name: this.state.currentQuestion?.unit, label: this.state.currentQuestion?.unit }}
                            options={this.props.prismicKeys}
                            onChange={(e) => {
                                let currentQuestion = this.state.currentQuestion as any;
                                if (currentQuestion) {
                                    currentQuestion["unit"] = e.target.value.name;
                                    this.setState({
                                        currentQuestion,
                                        changes: true
                                    });
                                }
                            }}
                            placeholder="Select a value"
                            filter
                            appendTo={document.body}
                        />
                    </div>
                );
                break;
            case 'minDate':
                content.push(
                    <div className="param">
                        <label>Min: </label>
                        <Calendar
                            value={new Date(this.state.currentQuestion?.size?.min)}
                            onChange={(e: any) => {
                                this._setQuestionValueWithDoubleKey(['size', 'min'], e.target.value.toLocaleDateString('sv', { month: 'numeric', year: 'numeric', day: 'numeric' }).replaceAll("/", "-"));
                            }}
                            dateFormat="dd/mm/yy"
                            locale={this.getLocale()}
                            monthNavigator
                            yearNavigator
                            yearRange="1900:2100"
                            appendTo={document.body}
                        />
                    </div>
                );
                break;
            case 'maxDate':
                content.push(
                    <div className="param">
                        <label>Max: </label>
                        <Calendar
                            value={new Date(this.state.currentQuestion?.size?.max)}
                            onChange={(e: any) => {
                                this._setQuestionValueWithDoubleKey(['size', 'max'], e.target.value.toLocaleDateString('sv', { month: 'numeric', year: 'numeric', day: 'numeric' }).replaceAll("/", "-"));
                            }}
                            dateFormat="dd/mm/yy"
                            locale={this.getLocale()}
                            monthNavigator
                            yearNavigator
                            yearRange="1900:2100"
                            appendTo={document.body}
                        />
                    </div>
                );
                break;
            case 'dead_end':
                content.push(
                    <div className="param">
                        <label>Dead-end: </label>
                        <Checkbox
                            className="block mb-2 mt-2"
                            checked={this.state.currentQuestion?.isDeadEnd}
                            onChange={(e) => {
                                let currentQuestion = this.state.currentQuestion as any;

                                currentQuestion.isDeadEnd = e.checked;

                                this.setState({ currentQuestion });
                            }}
                        />
                    </div>
                );
                break;
            case 'values':
                let table: any[] = [];
                if (this.state.currentQuestion?.values) {
                    for (const i in this.state.currentQuestion?.values) {
                        table.push({
                            value: this.state.currentQuestion?.values[i],
                            img: this.state.currentQuestion?.imageValues[i]
                        });
                    }
                }

                content.push(
                    <div className="valuesTable">
                        <DataTable
                            value={table}
                            reorderableColumns
                            onRowReorder={(e) => {
                                let currentQuestion = this.state.currentQuestion as any;
                                if (currentQuestion) {
                                    let values: any[] = [];
                                    let imageValues: any[] = [];

                                    e.value.forEach((el) => {
                                        values.push(el.value);
                                        imageValues.push(el.img);
                                    });

                                    currentQuestion.values = values;
                                    currentQuestion.imageValues = imageValues;
                                    this.setState({ currentQuestion });
                                }
                            }}
                            editMode="row">
                            <Column field="value" header="Values" editor={(props) => this._editValue(props)}/>
                            <Column field="img" header="Image values"
                                    editor={(props) => this._editImageValue(props)}/>
                            <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}/>
                            <Column body={(props, row) => this._actionBodyTemplate(row)}/>
                            <Column rowReorder={!this.state.isEditing} rowReorderIcon="pi pi-ellipsis-v" style={{ width: '3em' }}/>
                        </DataTable>
                        <div className="addValue">
                            <Button label="Add value"
                                    style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a', color: 'black' }}
                                    onClick={() => this.setState({ displayAddValueDialog: true })}
                                    className="p-button-secondary"/>
                        </div>
                    </div>
                );
                break;
            case 'values_only':
                let table2: any[] = [];
                if (this.state.currentQuestion?.values) {
                    for (const i in this.state.currentQuestion?.values) {
                        table2.push({
                            value: this.state.currentQuestion?.values[i],
                        });
                    }
                }

                content.push(
                    <div className="valuesTable">
                        <DataTable
                            lazy
                            value={table2}
                            reorderableColumns
                            onRowReorder={(e) => {
                                let currentQuestion = this.state.currentQuestion as any;
                                if (currentQuestion) {
                                    let values: any[] = [];

                                    e.value.forEach((el) => {
                                        values.push(el.value);
                                    });

                                    currentQuestion.values = values;
                                    this.setState({ currentQuestion });
                                }
                            }}
                            editMode="row">
                            <Column field="value" header="Values" editor={(props) => this._editValue(props)}/>
                            {this.state.currentQuestion?.type === QuestionType.CHECKBOX && (
                                <Column header="Tooltip" body={(rowData) => (
                                    <>
                                        <span>{getValueOfTag(tooltipKey, rowData.value)}</span>
                                    </>
                                )} editor={(props) => this._editKeyValue(props, tooltipKey)}/>
                            )}
                            {this.state.currentQuestion?.type === QuestionType.CHECKBOX && (
                                <Column header="Info" body={(rowData) => (
                                    <>
                                        <span>{getValueOfTag(infoKey, rowData.value)}</span>
                                    </>
                                )} editor={(props) => this._editKeyValue(props, infoKey)}/>
                            )}
                            <Column rowEditor header="Edit" headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}/>
                            <Column header="Delete" body={(props, row) => this._actionBodyTemplate(row)}/>
                            <Column rowReorder={!this.state.isEditing} rowReorderIcon="pi pi-ellipsis-v" style={{ width: '3em' }}/>
                        </DataTable>
                        <div className="addValue">
                            <Button label="Add value"
                                    style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a', color: 'black' }}
                                    onClick={() => this.setState({ displayAddValueDialog: true })}
                                    className="p-button-secondary"/>
                        </div>
                    </div>
                );
                break;
            case 'subQuestions':
                let tableSubQuestions: any[] = [];
                if (this.state.currentQuestion?.subQuestions) {
                    for (const i in this.state.currentQuestion?.subQuestions) {
                        tableSubQuestions.push(this.state.currentQuestion?.subQuestions[i]);
                    }
                }

                content.push(
                    <div>
                        <div className="valuesTable">
                            <DataTable
                                style={{width: '100%'}}
                                lazy
                                value={tableSubQuestions}
                                reorderableColumns
                                onRowReorder={(e) => {
                                    let currentQuestion = this.state.currentQuestion as any;
                                    if (currentQuestion) {
                                        let subQuestions: any[] = [];

                                        e.value.forEach((el) => {
                                            subQuestions.push(el);
                                        });

                                        currentQuestion.subQuestions = subQuestions;
                                        this.setState({ currentQuestion });
                                    }
                                }}
                                editMode="row">
                                <Column field="question" style={{ width: "100px" }} header="Question"
                                        editor={(props) => this._editSubQuestion('question', props)}/>
                                {
                                    this.state.currentQuestion?.type === QuestionType.MULTIPLE ?
                                        <Column field="shortTitle" header="Short title" style={{ width: "100px" }}
                                                editor={(props) => this._editSubQuestion('shortTitle', props)}/>
                                        : null
                                }
                                <Column field="type" header="Type" style={{ width: "100px" }}
                                        editor={(props) => this._editSubQuestion('type', props)}/>
                                {
                                    this.state.currentQuestion?.type === QuestionType.MULTIPLE ?
                                        <Column field="shortTitle" header="Suggested value" style={{ width: "100px" }}
                                                body={(rowData) => <p>{rowData.suggested_value?.decision_tree}</p>}
                                                editor={(props) => this._editSubQuestion('suggested_value', props)}/>
                                        : null
                                }
                                {
                                    this.state.currentQuestion?.type ?
                                        <Column field="values" header="Values" style={{ width: "100px" }}
                                                body={(props, row) => this._arrayBodyTemplate(props)}
                                                editor={(props) => this._editSubQuestion('values', props)}/>
                                        : null
                                }
                                <Column header="Values from decision tree / Question" style={{ width: "100px" }}
                                        body={(rowData) => <p>{rowData.target}</p>}
                                        editor={(props) => this._editSubQuestion('target', props)}/>

                                {
                                    this.state.currentQuestion?.type && (
                                        <Column header="Default Values" style={{ width: "100px" }}
                                                body={(rowData) => <p>{rowData.default_value?.decision_tree}</p>}
                                                editor={(props) => this._editSubQuestion('default_value', props)}/>
                                    )
                                }
                                <Column field="optional" header="Optional"
                                        style={{ textAlign: 'center', width: '7rem' }}
                                        body={(props, row) => this._checkboxBodyTemplate(row)}/>
                                <Column field="isPrismic" header="Prismic"
                                        style={{ textAlign: 'center', width: '7rem' }}
                                        body={(props, row) => this._checkboxBodyTemplatePrismic(row)}/>
                                <Column rowEditor header="Edit" headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}/>
                                <Column style={{ width: '80px' }} body={(props, row) => this._actionBodyTemplate(row)}/>
                                <Column rowReorder={!this.state.isEditing} rowReorderIcon="pi pi-ellipsis-v" style={{ width: '80px' }}/>
                            </DataTable>
                            <div className="addValue">
                                <Button label="Add a sub-question"
                                        onClick={() => {
                                            this.setState({ displayAddValueDialog: true });
                                        }}
                                        className="p-button-secondary"/>
                            </div>
                        </div>
                    </div>
                );
                break;
        }
    }

    return content;
}

private _renderAddValueDialogFooter() {
    return (
        <div>
            <Button
                label="Cancel"
                icon="pi pi-times"
                onClick={() => this.setState({
                    currentValue: null,
                    currentImageValue: null,
                    displayAddValueDialog: false
                })}
                style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                className="p-button-secondary"
            />
            <Button
                label="Save"
                icon="pi pi-check"
                onClick={() => {
                    let currentQuestion = this.state.currentQuestion as any;
                    if (currentQuestion) {
                        if (this.state.currentValue) {
                            if (currentQuestion.type === QuestionType.CHECKBOX) {

                                let value = this.state.currentValue
                                if (this.state.isCurrentValueSeparator) {
                                    value = separatorKey + value
                                }
                                if (this.state.currentToolTip && this.state.currentToolTip !== "") {
                                    value += tooltipKey + this.state.currentToolTip
                                }
                                if (this.state.currentInfo && this.state.currentInfo !== "") {
                                    value += infoKey + this.state.currentInfo
                                }
                                currentQuestion.values.push(value);

                            }
                            else {
                                currentQuestion.values.push(this.state.currentValue);
                            }
                        }
                        if (this.state.currentImageValue) {
                            currentQuestion.imageValues.push(this.state.currentImageValue);
                        }
                        if (this.state.currentSubQuestion && this.state.currentSubQuestionType && currentQuestion.subQuestions) {
                            currentQuestion.subQuestions.push({ question: this.state.currentSubQuestion, type: this.state.currentSubQuestionType });
                        }
                    }
                    this.setState({
                        currentQuestion,
                        currentValue: null,
                        currentImageValue: null,
                        currentSubQuestion: null,
                        currentSubQuestionType: null,
                        displayAddValueDialog: false,
                        isCurrentValueSeparator: false,
                    });
                }}
                style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
                className="p-button-secondary"
            />
        </div>
    );
}

private _getAddValueDialogContent() {
    let questionList: any[] = [];
    for (const question of this.props.questions) {
        questionList.push({ name: question.question, label: question.question });
    }

    switch (this.state.currentQuestion?.type) {

        case QuestionType.TABLE:
        case QuestionType.MULTIPLE:
            return (
                <div>
                    <div>
                        <label>Question: </label>
                        <Dropdown
                            value={this.state.currentSubQuestion}
                            options={questionList}
                            onChange={(e) => {
                                this.setState({ currentSubQuestion: e.value.name ?? e.value });
                            }}
                            filter
                            editable
                            appendTo={document.body}
                            placeholder="Select a question"/>
                    </div>
                    <div>
                        <label>Type: </label>
                        <Dropdown
                            value={this.state.currentSubQuestionType}
                            options={[
                                { name: QuestionType.RADIO, label: QuestionType.RADIO },
                                { name: QuestionType.RADIO_IMAGE, label: QuestionType.RADIO_IMAGE },
                                { name: QuestionType.DROPDOWN, label: QuestionType.DROPDOWN },
                                { name: QuestionType.BOOL, label: QuestionType.BOOL },
                                { name: QuestionType.NUMBER, label: QuestionType.NUMBER },
                                { name: QuestionType.LONG_TEXT, label: QuestionType.LONG_TEXT },
                                { name: QuestionType.MATRIX, label: QuestionType.MATRIX },
                                { name: QuestionType.CHECKBOX, label: QuestionType.CHECKBOX },
                                { name: QuestionType.STRING, label: QuestionType.STRING },
                                { name: QuestionType.DATE_PICKER, label: QuestionType.DATE_PICKER },
                                { name: QuestionType.PREVIOUS_QUESTION, label: QuestionType.PREVIOUS_QUESTION },
                                { name: QuestionType.DECISION_TREE, label: QuestionType.DECISION_TREE },
                            ]}
                            onChange={(e) => {
                                this.setState({ currentSubQuestionType: e.value.name ?? e.value });
                            }}
                            filter
                            editable
                            appendTo={document.body}
                            placeholder="Select a type"/>
                    </div>
                </div>
            );
        case QuestionType.MATRIX:
            return (
                <div>
                    <div>
                        <label>Valeur: </label>
                        <Dropdown
                            value={this.state.currentValue}
                            options={questionList}
                            onChange={(e) => {
                                this.setState({ currentValue: e.value.name ?? e.value });
                            }}
                            filter
                            editable
                            appendTo={document.body}
                            placeholder="Select a question"/>
                    </div>
                    <div>
                        <label>Valeur image: </label>
                        <Dropdown
                            value={this.state.currentImageValue}
                            options={questionList}
                            onChange={(e) => {
                                this.setState({ currentImageValue: e.value.name ?? e.value });
                            }}
                            filter
                            editable
                            appendTo={document.body}
                            placeholder="Select a type"/>
                    </div>
                </div>
            );
        case QuestionType.CHECKBOX:
            return (
                <div>
                    <div>
                        <label htmlFor="name">
                            Valeur:
                        </label>
                        <Dropdown
                            value={this.state.currentValue}
                            options={this.props.prismicKeys}
                            onChange={(e) => {
                                this.setState({ currentValue: e.value.name ?? e.value });
                            }}
                            placeholder="Select a value"
                            filter
                            editable
                            appendTo={document.body}
                        />
                    </div>
                    <div>
                        <label>Separator : </label>
                        <Checkbox
                            checked={this.state.isCurrentValueSeparator}
                            onChange={(e) => {
                                this.setState({ isCurrentValueSeparator: e.checked });
                            }}/>
                    </div>
                    <div>
                        <label htmlFor="name">
                            Tooltip:
                        </label>
                        <Dropdown
                            value={this.state.currentToolTip}
                            options={this.props.prismicKeys}
                            onChange={(e) => {
                                this.setState({ currentToolTip: e.value.name ?? e.value });
                            }}
                            placeholder="Select a value"
                            filter
                            editable
                            appendTo={document.body}
                        />
                    </div>
                    <div>
                        <label htmlFor="name">
                            Info box:
                        </label>
                        <Dropdown
                            value={this.state.currentInfo}
                            options={this.props.prismicKeys}
                            onChange={(e) => {
                                this.setState({ currentInfo: e.value.name ?? e.value });
                            }}
                            placeholder="Select a value"
                            filter
                            editable
                            appendTo={document.body}
                        />
                    </div>
                </div>
            )
        default:
            return (
                <div>
                    <div>
                        <label htmlFor="name">
                            Valeur:
                        </label>
                        <Dropdown
                            value={this.state.currentValue}
                            options={this.props.prismicKeys}
                            onChange={(e) => {
                                this.setState({ currentValue: e.value.name ?? e.value });
                            }}
                            placeholder="Select a value"
                            filter
                            editable
                            appendTo={document.body}
                        />
                    </div>
                </div>
            );

    }
}

private _renderAddConditionDialogFooter() {
    return (
        <div>
            <Button
                label="Cancel"
                icon="pi pi-times"
                onClick={() => this.setState({
                    displayAddConditionDialog: false
                })}
                style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                className="p-button-danger"
            />
            <Button
                label="Add"
                icon="pi pi-check"
                onClick={() => {
                    let currentQuestion = this.state.currentQuestion as any;
                    if (currentQuestion?.conditions) {
                        this.nodeCount = 0;
                        this.nodeCount2 = 0;
                        currentQuestion.conditions = this._getNodeById(this.state.currentQuestion?.conditions,
                            this.state.currentCondition.currentNode.data.id);
                        this.setState({
                            currentQuestion,
                            displayAddConditionDialog: false,
                            currentCondition: { currentNode: null, lhs: { type: null, value: null }, operator: null, rhs: { type: null, value: null } }
                        });
                    }
                    else {
                        let currentQuestion = this.state.currentQuestion as any;
                        currentQuestion.conditions = {
                            lhs: this._getConditionByType(this.state.currentCondition.lhs),
                            operator: this.state.currentCondition.operator.name,
                            rhs: this._getConditionByType(this.state.currentCondition.rhs)
                        };
                        this.setState({
                            currentQuestion,
                            displayAddConditionDialog: false,
                            currentCondition: { currentNode: null, lhs: { type: null, value: null }, operator: null, rhs: { type: null, value: null } }
                        });
                    }
                }}
                className="p-button-danger"
                style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
            />
        </div>
    );
}

private _getNodeById(condition: any, id: number) {
    if (condition.lhs) {
        if (condition.lhs.question
            || condition.lhs.decision_tree
            || typeof condition.lhs === 'string'
            || typeof condition.lhs === 'number'
            || Object.prototype.toString.call(condition.lhs) === '[object Array]'
            || typeof condition.lhs === 'boolean') {
            if (id === this.nodeCount2) {
                condition.lhs = {
                    lhs: this._getConditionByType(this.state.currentCondition.lhs),
                    operator: this.state.currentCondition.operator.name,
                    rhs: this._getConditionByType(this.state.currentCondition.rhs)
                };
            }
            this.nodeCount2++;
        }
        else {
            this._getNodeById(condition.lhs, id);
        }
    }

    if (condition.rhs) {
        if (condition.rhs.question
            || condition.rhs.decision_tree
            || typeof condition.rhs === 'string'
            || typeof condition.rhs === 'number'
            || Object.prototype.toString.call(condition.rhs) === '[object Array]'
            || typeof condition.rhs === 'boolean') {
            if (id === this.nodeCount2) {
                condition.rhs = {
                    lhs: this._getConditionByType(this.state.currentCondition.lhs),
                    operator: this.state.currentCondition.operator.name,
                    rhs: this._getConditionByType(this.state.currentCondition.rhs)
                };
            }
            this.nodeCount2++;
        }
        else {
            this._getNodeById(condition.rhs, id);
        }
    }

    return condition;
}

private _getConditionByType(condition: any) {
    switch (condition.type.name) {
        case 'question':
            return { question: condition.value };
        case 'list':
            let values: string[] = [];
            for (const v of condition.value) {
                values.push(v.name);
            }
            return values;
        case 'boolean':
            return condition.value.name === 'true' ? true : (condition.value.name === 'false' ? false : null);
        case 'decision_tree':
            return { decision_tree: condition.value.name };
        case 'value':
        case 'number':
            return condition.value;
    }
    return;
}

private _actionImageBodyTemplate(row) {
    return (
        <React.Fragment>
            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger"
                    onClick={() => {
                        let currentQuestion = this.state.currentQuestion;
                        if (currentQuestion) {
                            currentQuestion.images?.splice(row.rowIndex, 1);
                            this.setState({ currentQuestion });
                        }
                    }}/>
        </React.Fragment>
    );
}

private _getQuestionImages() {
    let datas: any[] = [];
    if (this.state.currentQuestion?.images) {
        for (const image of this.state.currentQuestion?.images) {
            if (image) {
                datas.push({ image });
            }
        }
    }
    return (
        <div>
            <DataTable
                value={datas}
            >
                <Column header="Images" field="image" style={{ width: '200px' }}/>
                <Column header="Delete" style={{ width: '50px', textAlign: 'center' }} body={(props, row) => this._actionImageBodyTemplate(row)}/>
            </DataTable>
        </div>
    );
}

private _getNodeTemplate(node: any) {
    if (node !== undefined) {
        return (
            <div>
                <p>{node.label}</p>
                {node.data?.type === 'final' ? (
                    <div>
                        <Button
                            icon="pi pi-plus"
                            className="p-button-rounded p-button-secondary p-button-text"
                            onClick={() => {
                                let currentCondition = this.state.currentCondition;
                                currentCondition.currentNode = node;
                                this.setState({
                                    currentCondition,
                                    displayAddConditionDialog: true
                                });
                            }}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
    else {
        return (
            <Button
                icon="pi pi-plus"
                className="p-button-rounded p-button-secondary"
                onClick={() => {

                }}
            />
        );
    }
}

private _getQuestionConditions() {
    let content: any = [];
    this.nodeCount = 0;

    if (this.state.currentQuestion?.conditions) {
        content.push(this._getConditionsDatas(this.state.currentQuestion?.conditions));
    }
    this.nodes = content;
    return content;
}

private _getConditionsDatas(condition: any) {
    let data: any = {};
    data.children = [];
    data.expanded = true;

    if (condition.operator) {
        data.label = condition.operator;
    }

    if (condition.lhs !== undefined) {
        if (condition.lhs.question) {
            data.children.push({
                data: { type: 'final', id: this.nodeCount },
                label: condition.lhs.question
            });
            this.nodeCount++;
        }
        else if (condition.lhs.decision_tree) {
            data.children.push({
                data: { type: 'final', id: this.nodeCount },
                label: condition.lhs.decision_tree
            });
            this.nodeCount++;
        }
        else if (typeof condition.lhs === 'string') {
            data.children.push({
                data: { type: 'final', id: this.nodeCount },
                label: condition.lhs
            });
            this.nodeCount++;
        }
        else if (Object.prototype.toString.call(condition.lhs) === '[object Array]') {
            data.children.push({
                data: { type: 'final', id: this.nodeCount },
                label: condition.lhs.join(', ')
            });
            this.nodeCount++;
        }
        else if (typeof condition.lhs === 'boolean') {
            data.children.push({
                data: { type: 'final', id: this.nodeCount },
                label: condition.lhs.toString()
            });
            this.nodeCount++;
        }
        else if (typeof condition.lhs === 'number') {
            data.children.push({
                data: { type: 'final', id: this.nodeCount },
                label: condition.lhs.toString()
            });
            this.nodeCount++;
        }
        else {
            data.children.push(this._getConditionsDatas(condition.lhs));
        }
    }

    if (condition.rhs !== undefined) {
        if (condition.rhs.question) {
            data.children.push({
                data: { type: 'final', id: this.nodeCount },
                label: condition.rhs.question
            });
            this.nodeCount++;
        }
        else if (condition.rhs.decision_tree) {
            data.children.push({
                data: { type: 'final', id: this.nodeCount },
                label: condition.rhs.decision_tree
            });
            this.nodeCount++;
        }
        else if (typeof condition.rhs === 'string') {
            data.children.push({
                data: { type: 'final', id: this.nodeCount },
                label: condition.rhs
            });
            this.nodeCount++;
        }
        else if (Object.prototype.toString.call(condition.rhs) === '[object Array]') {
            data.children.push({
                data: { type: 'final', id: this.nodeCount },
                label: condition.rhs.join(', ')
            });
            this.nodeCount++;
        }
        else if (typeof condition.rhs === 'boolean') {
            data.children.push({
                data: { type: 'final', id: this.nodeCount },
                label: condition.rhs.toString()
            });
            this.nodeCount++;
        }
        else if (typeof condition.rhs === 'number') {
            data.children.push({
                data: { type: 'final', id: this.nodeCount },
                label: condition.rhs.toString()
            });
            this.nodeCount++;
        }
        else {
            data.children.push(this._getConditionsDatas(condition.rhs));
        }
    }

    return data;
}

private _getAddConditionContent(side: string) {
    let content: any[] = [];

    let questionList: any[] = [];
    for (const question of this.props.questions) {
        questionList.push({ name: question.question, label: question.question });
    }

    switch (this.state.currentCondition[side].type?.name) {
        case 'question':
            content.push(
                <div>
                    <label>Question: </label>
                    <Dropdown
                        value={this.state.currentCondition[side].value}
                        options={questionList}
                        onChange={(e) => {
                            let currentCondition = this.state.currentCondition;
                            currentCondition[side].value = e.value.name ?? e.value;
                            this.setState({ currentCondition });
                        }}
                        filter
                        editable
                        appendTo={document.body}
                        placeholder="Select a question"/>
                </div>
            );
            break;
        case 'value':
            content.push(
                <div>
                    <label>Value: </label>
                    <InputText className="p-inputtext-sm block mb-2"
                               placeholder="Enter value(s)"
                               value={this.state.currentCondition[side].value}
                               onChange={(e: any) => {
                                   let currentCondition = this.state.currentCondition;
                                   currentCondition[side].value = e.target.value;
                                   this.setState({ currentCondition });
                               }}
                    />
                </div>
            );
            break;
        case 'number':
            content.push(
                <div>
                    <label>Value: </label>
                    <InputNumber
                        className="p-inputtext-sm block mb-2"
                        placeholder="Enter value"
                        value={this.state.currentCondition[side].value}
                        onChange={(e: any) => {
                            let currentCondition = this.state.currentCondition;
                            currentCondition[side].value = e.value;
                            this.setState({ currentCondition });
                        }}
                    />
                </div>
            );
            break;
        case 'list':
            content.push(
                <div>
                    <label>Value(s): </label>
                    <MultiSelect
                        value={this.state.currentCondition[side].value}
                        options={this.props.prismicKeys}
                        onChange={(e) => {
                            let currentCondition = this.state.currentCondition;
                            currentCondition[side].value = e.value;
                            this.setState({ currentCondition });
                        }}
                        placeholder="Select value(s)"
                        appendTo={document.body}
                        filter
                    />
                </div>
            );
            break;
        case 'boolean':
            content.push(
                <div>
                    <label>Value: </label>
                    <Dropdown
                        value={this.state.currentCondition[side].value}
                        options={[
                            { name: 'true', label: 'true' },
                            { name: 'false', label: 'false' },
                            { name: 'null', label: 'null' },
                        ]}
                        onChange={(e) => {
                            let currentCondition = this.state.currentCondition;
                            currentCondition[side].value = e.value;
                            this.setState({ currentCondition });
                        }}
                        filter
                        editable
                        appendTo={document.body}
                        placeholder="Select a value"/>
                </div>
            );
            break;
        case 'decision_tree':
            let decisionTreeList: any[] = [];
            for (const decisionTree of this.props.decisionTrees) {
                decisionTreeList.push({ name: decisionTree.name, label: decisionTree.name });
            }
            content.push(
                <div>
                    <label>Decision_tree: </label>
                    <Dropdown
                        value={this.state.currentCondition[side].value}
                        options={decisionTreeList}
                        onChange={(e) => {
                            let currentCondition = this.state.currentCondition;
                            currentCondition[side].value = e.value;
                            this.setState({ currentCondition });
                        }}
                        filter
                        editable
                        appendTo={document.body}
                        placeholder="Select a value"/>
                </div>
            );
            break;
    }

    return content;
}

private _getQuestionConditionalInfo() {
    let conditions: any = [];
    if (this.state.currentQuestion?.conditionalInfo) {
        for (const condition of this.state.currentQuestion?.conditionalInfo) {
            conditions.push(
                {
                    lhs: condition.conditions.lhs.question,
                    operator: condition.conditions.operator,
                    rhs: (condition.conditions.rhs as any)?.toString() ?? condition.conditions.rhs,
                    info: condition.info
                }
            );
        }
    }

    return (
        <div className="valuesTable">
            {
                this.state.currentQuestion?.conditionalInfo.length ? (
                    <DataTable
                        value={conditions}
                        reorderableColumns
                        onRowReorder={(e) => {
                            let list: any[] = [];
                            e.value.forEach((c) => {
                                list.push({
                                    conditions: {
                                        lhs: { question: c.lhs },
                                        operator: c.operator,
                                        rhs: c.rhs
                                    },
                                    info: c.info
                                });
                            });
                            let currentQuestion = this.state.currentQuestion as any;
                            currentQuestion.conditionalInfo = list;
                            this.setState({ currentQuestion });

                        }}
                        editMode="row">
                        <Column field="lhs" header="lhs" editor={(props) => this._editConditionalInfo(props)}/>
                        <Column field="operator" header="Operator"
                                editor={(props) => this._editConditionalInfo(props)}/>
                        <Column field="rhs" header="rhs"
                                editor={(props) => this._editConditionalInfo(props)}/>
                        <Column field="info" header="Info"
                                editor={(props) => this._editConditionalInfo(props)}/>
                        <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}/>
                        <Column body={(props, row) => this._actionConditionalInfoBodyTemplate(row)}/>
                        <Column rowReorder={!this.state.isEditing} rowReorderIcon="pi pi-ellipsis-v" style={{ width: '3em' }}/>
                    </DataTable>
                ) : null
            }
            <div className="addValue">
                <Button label="Add informations condition"
                        onClick={() => {
                            let currentQuestion = this.state.currentQuestion as any;
                            currentQuestion.conditionalInfo.push({
                                conditions: {
                                    lhs: { question: null },
                                    operator: null,
                                    rhs: null
                                },
                                info: null
                            });
                            this.setState({ currentQuestion });
                        }}
                        className="p-button-secondary"/>
            </div>
        </div>
    );
}

private _editConditionalInfo(props) {
    let questionList: any[] = [];
    for (const question of this.props.questions) {
        questionList.push({ name: question.question, label: question.question });
    }

    switch (props.field) {
        case 'lhs':
            return (<Dropdown
                value={this.state.currentQuestion?.conditionalInfo[props.rowIndex].conditions.lhs.question}
                options={questionList}
                onChange={(e) => {
                    let currentQuestion = this.state.currentQuestion;
                    if (currentQuestion) {
                        currentQuestion.conditionalInfo[props.rowIndex].conditions.lhs.question = e.value.name ?? e.value;
                        this.setState({ currentQuestion });
                    }
                }}
                placeholder="Select a lhs"
                filter
                editable
                appendTo={document.body}
            />);
        case 'rhs':
            return (
                <Button
                    label="Edit rhs"
                    onClick={() => this.setState({ displayRhsDialog: true, editingRhsRowIndex: props.rowIndex })}
                />
            );
        case 'info':
            return (<Dropdown
                value={this.state.currentQuestion?.conditionalInfo[props.rowIndex].info}
                options={this.props.prismicKeys}
                onChange={(e) => {
                    let currentQuestion = this.state.currentQuestion;
                    if (currentQuestion) {
                        currentQuestion.conditionalInfo[props.rowIndex].info = e.value.name ?? e.value;
                        this.setState({ currentQuestion });
                    }
                }}
                placeholder="Select an info"
                filter
                editable
                appendTo={document.body}
            />);
        case 'operator':
            let operatorList: any[] = [];
            for (let i in OperatorEnum) {
                operatorList.push({ name: OperatorEnum[i], label: OperatorEnum[i] });
            }
            return (
                <Dropdown
                    value={this.state.currentQuestion?.conditionalInfo[props.rowIndex].conditions.operator}
                    options={operatorList}
                    onChange={(e) => {
                        let currentQuestion = this.state.currentQuestion as any;
                        if (currentQuestion) {
                            currentQuestion.conditionalInfo[props.rowIndex].conditions.operator = e.value.name ?? e.value;
                            this.setState({ currentQuestion });
                        }
                    }}
                    placeholder="Select an operator"
                    filter
                    editable
                    appendTo={document.body}
                />
            );
    }
}

private handleSaveRhs = () => {
    let currentQuestion = this.state.currentQuestion as any;
    if (currentQuestion?.conditionalInfo[this.state.editingRhsRowIndex!]?.conditions) {
        currentQuestion.conditionalInfo[this.state.editingRhsRowIndex!].conditions = {
            ...currentQuestion.conditionalInfo[this.state.editingRhsRowIndex!].conditions,
            rhs: this._getConditionByType(this.state.currentCondition.rhs)
        };
        this.setState({
            currentQuestion,
            displayRhsDialog: false,
            currentCondition: { currentNode: null, lhs: { type: null, value: null }, operator: null, rhs: { type: null, value: null } }
        });
    }
}


private _actionConditionalInfoBodyTemplate(row) {
    return (
        <React.Fragment>
            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger"
                    onClick={() => {
                        let currentQuestion = this.state.currentQuestion;
                        if (currentQuestion) {
                            currentQuestion.conditionalInfo.splice(row.rowIndex, 1);
                            this.setState({ currentQuestion });
                        }
                    }}/>
        </React.Fragment>
    );
}

private _getQuestionConfig() {
    if (!this.state.currentQuestion?.type) {
        return this._getContentByType([]);
    }
    switch (this.state.currentQuestion?.type['name'] ? this.state.currentQuestion?.type['name'] : this.state.currentQuestion?.type) {
        case QuestionType.NUMBER:
            return this._getContentByType(['step', 'min', 'max', 'unit']);
        case QuestionType.TEXT_ONLY:
            return this._getContentByType(['dead_end']);
        case QuestionType.DATE_PICKER:
            return this._getContentByType(['minDate', 'maxDate']);
        case QuestionType.MATRIX:
            return this._getContentByType(['values']);
        case QuestionType.RADIO_IMAGE:
            return this._getContentByType(['values']);
        case QuestionType.CHECKBOX:
            return this._getContentByType(['values_only']);
        // case QuestionType.DROPDOWN:
        //   return this._getContentByType(['values_only']);
        case QuestionType.RADIO:
            return this._getContentByType(['values_only']);
        case QuestionType.MULTIPLE:
            return this._getContentByType(['subQuestions']);
        case QuestionType.TABLE:
            return this._getContentByType(['subQuestions', 'size']);
    }

}

render() {

    let questionTypes: any[] = [];
    for (let i in QuestionType) {
        questionTypes.push({ name: QuestionType[i], label: QuestionType[i] });
    }

    let operatorList: any[] = [];
    for (let i in OperatorEnum) {
        operatorList.push({ name: OperatorEnum[i], label: OperatorEnum[i] });
    }

    let decisionTreeList: any[] = [];
    for (const decisionTree of this.props.decisionTrees) {
        decisionTreeList.push({ name: decisionTree.name, label: decisionTree.name });
    }

    if (this.state.currentQuestion) {
        return (
            <div className="form">
                <Toast ref={(el) => this.toast = el}/>
                <div className="content">
                    <div className="tabView">
                        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                            <TabPanel header="Common" leftIcon="pi pi-list">
                                <div className="param" id="type">
                                    <label>Type: </label>
                                    <Dropdown
                                        value={this.state.currentQuestion?.type}
                                        options={questionTypes}
                                        onChange={(e) => {
                                            let currentQuestion = this.state.currentQuestion as any;
                                            if (currentQuestion) {
                                                if (currentQuestion.imageValues) {
                                                    currentQuestion.imageValues = [];
                                                }
                                                if (currentQuestion.values) {
                                                    currentQuestion.values = [];
                                                }
                                                if (currentQuestion.subQuestions) {
                                                    currentQuestion.subQuestions = [];
                                                }
                                                if (currentQuestion.size) {
                                                    delete currentQuestion.size;
                                                }
                                                this._setQuestionValue('type', e.value.name ?? e.value);
                                            }
                                            const currentQuestion2 = this.state.currentQuestion as any;
                                            switch (e.value.name ?? e.value) {
                                                case QuestionType.NUMBER:
                                                    currentQuestion2.validation = {
                                                        min: 0,
                                                        step: 1
                                                    };
                                            }
                                            this.setState({
                                                currentQuestion: currentQuestion2
                                            });
                                        }}
                                        filter
                                        editable
                                        placeholder="Select a type"
                                        appendTo={document.body}/>
                                </div>
                                <div className="param">
                                    <label>Short title: </label>
                                    <Dropdown
                                        value={this.state.currentQuestion?.shortTitle}
                                        options={this.props.prismicKeys}
                                        onChange={(e) => {
                                            let currentQuestion = this.state.currentQuestion as any;
                                            if (currentQuestion) {
                                                currentQuestion.shortTitle = e.value.name ?? e.value;
                                                this.setState({ currentQuestion });
                                            }
                                        }}
                                        placeholder="Select a short title"
                                        filter
                                        editable
                                        appendTo={document.body}
                                    />
                                </div>
                                <div className="param">
                                    <label>Info: </label>
                                    <Dropdown
                                        value={this.state.currentQuestion?.info}
                                        options={this.props.prismicKeys}
                                        onChange={(e) => {
                                            let currentQuestion = this.state.currentQuestion as any;
                                            if (currentQuestion) {
                                                currentQuestion.info = e.value.name ?? e.value;
                                                this.setState({ currentQuestion });
                                            }
                                        }}
                                        placeholder="Select a short title"
                                        filter
                                        editable
                                        appendTo={document.body}
                                    />
                                </div>
                                <div className="param">
                                    <label>SubInfo: </label>
                                    <Dropdown
                                        value={this.state.currentQuestion?.subInfo}
                                        options={this.props.prismicKeys}
                                        onChange={(e) => {
                                            let currentQuestion = this.state.currentQuestion as any;
                                            if (currentQuestion) {
                                                currentQuestion.subInfo = e.value.name ?? e.value;
                                                this.setState({ currentQuestion });
                                            }
                                        }}
                                        placeholder="Select a short title"
                                        filter
                                        editable
                                        appendTo={document.body}
                                    />
                                </div>
                                <div className="param">
                                    <label>Optional: </label>
                                    <Checkbox
                                        checked={this.state.currentQuestion.optional}
                                        onChange={e => {
                                            let currentQuestion = this.state.currentQuestion as any;
                                            if (e.checked) {
                                                currentQuestion.optional = true;
                                            }
                                            else {
                                                if (currentQuestion.optional) {
                                                    delete currentQuestion.optional;
                                                }
                                            }
                                            this.setState({ currentQuestion });
                                        }}
                                    />

                                </div>
                                <div className="param">
                                    <label>Hide in summary: </label>
                                    <Checkbox
                                        checked={this.state.currentQuestion.toDisplayInSummary === false}
                                        onChange={e => {
                                            let currentQuestion = this.state.currentQuestion as any;
                                            if (e.checked) {
                                                currentQuestion.toDisplayInSummary = false;
                                            }
                                            else {
                                                if (currentQuestion.toDisplayInSummary !== undefined) {
                                                    delete currentQuestion.toDisplayInSummary;
                                                }
                                            }
                                            this.setState({ currentQuestion });
                                        }}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel header="Configuration" leftIcon="pi pi-cog">
                                {(this.state.currentQuestion.type === QuestionType.TABLE) ? (
                                    <div>
                                        <Fieldset legend="Size" id="sizeFieldset">
                                            <label>Type: </label>
                                            <Dropdown
                                                value={this.state.currentQuestion?.size?.type ?? null}
                                                options={[
                                                    { name: 'fixed', label: 'fixed' },
                                                    { name: QuestionType.PREVIOUS_QUESTION, label: QuestionType.PREVIOUS_QUESTION },
                                                    { name: QuestionType.DECISION_TREE, label: QuestionType.DECISION_TREE },
                                                    { name: 'dynamic', label: 'dynamic' }
                                                ]}
                                                onChange={(e) => {
                                                    let currentQuestion = this.state.currentQuestion as any;
                                                    if (currentQuestion) {
                                                        if (!currentQuestion.size) {
                                                            currentQuestion.size = {};
                                                        }
                                                        currentQuestion.size.type = e.value.name ?? e.value;
                                                        this.setState({ currentQuestion });
                                                    }
                                                }}
                                                placeholder="Select a short title"
                                                filter
                                                editable
                                                appendTo={document.body}
                                            />
                                            {this._getSizeContent(this.state.currentQuestion?.size?.type)}
                                        </Fieldset>
                                    </div>
                                ) : (this.state.currentQuestion.type === QuestionType.DROPDOWN) ? (
                                    <div className="p-mb-5">
                                        <Fieldset legend="Type">
                                            <label>Type: </label>
                                            <Dropdown
                                                value={this.state.currentDropdownType}
                                                options={[
                                                    { name: "default", label: "default" },
                                                    { name: QuestionType.DECISION_TREE, label: QuestionType.DECISION_TREE },
                                                ]}
                                                onChange={(e) => {
                                                    let currentQuestion = this.state.currentQuestion as any
                                                    if (e.value.name === "default") {
                                                        currentQuestion.target = ""
                                                    }
                                                    this.setState({
                                                        currentDropdownType: e.value.name,
                                                        currentQuestion
                                                    })
                                                }}
                                                placeholder="Select a type"
                                                filter
                                                editable
                                                appendTo={document.body}
                                            />
                                            {this.state.currentDropdownType === QuestionType.DECISION_TREE && (
                                                <div>
                                                    <label>Decision tree : </label>
                                                    <Dropdown
                                                        value={this.state.currentQuestion.target}
                                                        options={decisionTreeList}
                                                        onChange={(e) => {
                                                            const currentQuestion = this.state.currentQuestion as any
                                                            currentQuestion.target = e.value.name
                                                            this.setState({
                                                                currentQuestion
                                                            })
                                                        }}
                                                        placeholder="Select a decision tree"
                                                        filter
                                                        editable
                                                        appendTo={document.body}
                                                    />
                                                </div>
                                            )}
                                        </Fieldset>
                                        {this.state.currentDropdownType === "default" && this._getContentByType(['values_only'])}
                                    </div>
                                ) : null
                                }
                                {
                                    this._getQuestionConfig()
                                }
                            </TabPanel>
                            <TabPanel header="Additional params" leftIcon="pi pi-plus-circle">
                                <div className="additionalParams">
                                    <Fieldset legend="Images">
                                        <div className="questionImages">
                                            {
                                                this.state.currentQuestion.images?.length > 0 ? this._getQuestionImages() : null
                                            }
                                            <div className="">
                                                <div className="p-inputgroup">
                                                    <InputText
                                                        value={this.state.currentImageName}
                                                        placeholder="Add image"
                                                        onChange={(e: any) => this.setState({ currentImageName: e.target.value })}/>
                                                    <Button
                                                        onClick={() => {
                                                            let currentQuestion = this.state.currentQuestion;
                                                            currentQuestion?.images.push(this.state.currentImageName);
                                                            this.setState({
                                                                currentQuestion,
                                                                currentImageName: ''
                                                            });
                                                        }}
                                                        disabled={!this.state.currentImageName}
                                                        icon="pi pi-check"
                                                        className="p-button-success"/>
                                                </div>
                                            </div>
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="Suggested_value">
                                        <div className="questionDefaultValue">
                                            <div className="p-inputgroup">
                                                <label>Decision_tree: </label>
                                                <Dropdown
                                                    value={this.state.currentQuestion.suggested_value?.decision_tree ?? null}
                                                    options={decisionTreeList}
                                                    onChange={(e) => {
                                                        if (!e.value) {
                                                            let currentQuestion = this.state.currentQuestion as any;
                                                            delete currentQuestion.suggested_value;
                                                            this.setState({ currentQuestion });
                                                        }
                                                        else {
                                                            let currentQuestion = this.state.currentQuestion as any;
                                                            currentQuestion.suggested_value = { decision_tree: null };
                                                            currentQuestion.suggested_value.decision_tree = e.value.name ?? e.value;
                                                            this.setState({ currentQuestion });
                                                        }
                                                    }}
                                                    placeholder="Select a decision_tree"
                                                    showClear
                                                    filter
                                                    editable
                                                    appendTo={document.body}
                                                />
                                            </div>
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="Default_value">
                                        <div className="questionDefaultValue">
                                            <div className="p-inputgroup">
                                                <label>Decision_tree: </label>
                                                <Dropdown
                                                    value={this.state.currentQuestion.default_value?.decision_tree ?? null}
                                                    options={decisionTreeList}
                                                    onChange={(e) => {
                                                        if (!e.value) {
                                                            let currentQuestion = this.state.currentQuestion as any;
                                                            delete currentQuestion.default_value;
                                                            this.setState({ currentQuestion });
                                                        }
                                                        else {
                                                            let currentQuestion = this.state.currentQuestion as any;
                                                            currentQuestion.default_value = { decision_tree: null };
                                                            currentQuestion.default_value.decision_tree = e.value.name ?? e.value;
                                                            this.setState({ currentQuestion });
                                                        }
                                                    }}
                                                    placeholder="Select a decision_tree"
                                                    showClear
                                                    filter
                                                    editable
                                                    appendTo={document.body}
                                                />
                                            </div>
                                        </div>
                                    </Fieldset>
                                </div>
                            </TabPanel>
                            <TabPanel header="Conditions" leftIcon="pi pi-eye-slash">
                                {
                                    this.state.currentQuestion.conditions ? (
                                            <div className="organizationChart">
                                                <div>
                                                    <Button label="Delete" className="p-button-danger" id="conditionsDelete"
                                                            onClick={() => {
                                                                this.setState({
                                                                    displayDeleteConditionDialog: true
                                                                });
                                                            }}/>
                                                </div>
                                                <div>
                                                    <Button label="Raw edit" className="p-button-secondary" id="conditionsDelete"
                                                            onClick={() => {
                                                                this.setState({
                                                                    currentConditionEdit: JSON.stringify(this.state.currentQuestion?.conditions, null, 4),
                                                                    displayEditConditionDialog: true
                                                                });
                                                            }}/>
                                                </div>
                                                <OrganizationChart
                                                    value={this._getQuestionConditions()}
                                                    nodeTemplate={(node) => this._getNodeTemplate(node)}
                                                />
                                            </div>
                                        ) :
                                        <Button
                                            label="Add condition"
                                            className="p-button-secondary"
                                            onClick={() => this.setState({ displayAddConditionDialog: true })}
                                        />
                                }
                            </TabPanel>
                            <TabPanel header="Informations conditions" leftIcon="pi pi-question-circle">
                                {
                                    this.state.currentQuestion.conditionalInfo ? (
                                            <div className="organizationChart">
                                                {this._getQuestionConditionalInfo()}
                                            </div>
                                        ) :
                                        <Button
                                            label="Add infos condition"
                                            className="p-button-secondary"
                                            onClick={() => this.setState({ displayAddConditionDialog: true })}
                                        />
                                }
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
                <Dialog
                    id="addValueDialog"
                    header="Add value"
                    footer={this._renderAddValueDialogFooter()}
                    visible={this.state.displayAddValueDialog}
                    style={{ width: '600px' }}
                    onHide={() => {
                        this.setState({
                            displayAddValueDialog: false,
                            currentSubQuestion: null,
                            currentSubQuestionType: null,
                            currentValue: null,
                            currentImageValue: null,
                        });
                    }}>
                    {this._getAddValueDialogContent()}
                </Dialog>

                <Dialog
                    id="addConditionDialog"
                    header={"Add condition"}
                    footer={this._renderAddConditionDialogFooter()}
                    visible={this.state.displayAddConditionDialog}
                    style={{ width: '600px' }}
                    onHide={() => {
                        this.setState({
                            displayAddConditionDialog: false
                        });
                    }}>
                    <Fieldset
                        legend="lhs">
                        <label>Type: </label>
                        <Dropdown
                            value={this.state.currentCondition?.lhs?.type ?? null}
                            options={[
                                { name: 'question', label: 'question' },
                                { name: 'value', label: 'value' },
                                { name: 'list', label: 'list' },
                                { name: 'boolean', label: 'boolean' },
                                { name: 'decision_tree', label: 'decision_tree' },
                                { name: 'number', label: 'number' }
                            ]}
                            onChange={(e) => {
                                let currentCondition = this.state.currentCondition as any;
                                if (!currentCondition) {
                                    currentCondition = {} as any;
                                }
                                currentCondition.lhs.type = e.value;
                                this.setState({ currentCondition });
                            }}
                            placeholder="Select a lhs type"
                            appendTo={document.body}
                            filter
                            editable
                        />
                        {this._getAddConditionContent('lhs')}
                    </Fieldset>
                    <label>Operator: </label>
                    <Dropdown
                        value={this.state.currentCondition?.operator ?? null}
                        options={operatorList}
                        onChange={(e) => {
                            let currentCondition = this.state.currentCondition as any;
                            if (!currentCondition) {
                                currentCondition = {} as any;
                            }
                            currentCondition.operator = e.value;
                            this.setState({ currentCondition });
                        }}
                        placeholder="Select an operator"
                        appendTo={document.body}
                        filter
                        editable
                    />
                    <Fieldset
                        legend="rhs">
                        <label>Type: </label>
                        <Dropdown
                            value={this.state.currentCondition?.rhs?.type ?? null}
                            options={[
                                { name: 'question', label: 'question' },
                                { name: 'value', label: 'value' },
                                { name: 'list', label: 'list' },
                                { name: 'boolean', label: 'boolean' },
                                { name: 'decision_tree', label: 'decision_tree' },
                                { name: 'number', label: 'number' }
                            ]}
                            onChange={(e) => {
                                let currentCondition = this.state.currentCondition as any;
                                if (!currentCondition) {
                                    currentCondition = {} as any;
                                }
                                currentCondition.rhs.type = e.value;
                                this.setState({ currentCondition });
                            }}
                            placeholder="Select a rhs type"
                            appendTo={document.body}
                            filter
                            editable
                        />
                        {this._getAddConditionContent('rhs')}
                    </Fieldset>
                </Dialog>

                <Dialog
                    id="addConditionnalInfoDialog"
                    header={"Add rhs condition"}
                    visible={this.state.displayRhsDialog}
                    style={{ width: '600px' }}
                    onHide={() => {
                        this.setState({
                            displayRhsDialog: false,
                            editingRhsRowIndex: null,
                        });
                    }}>
                    <Fieldset
                        legend="rhs">
                        <label>Type: </label>
                        <Dropdown
                            value={this.state.currentCondition?.rhs?.type ?? null}
                            options={[
                                { name: 'question', label: 'question' },
                                { name: 'value', label: 'value' },
                                { name: 'list', label: 'list' },
                                { name: 'boolean', label: 'boolean' },
                                { name: 'decision_tree', label: 'decision_tree' },
                                { name: 'number', label: 'number' }
                            ]}
                            onChange={(e) => {
                                let currentCondition = this.state.currentCondition as any;
                                if (!currentCondition) {
                                    currentCondition = {} as any;
                                }
                                currentCondition.rhs.type = e.value;
                                this.setState({ currentCondition });
                            }}
                            placeholder="Select a rhs type"
                            appendTo={document.body}
                            filter
                            editable
                        />
                        {this._getAddConditionContent('rhs')}
                        <Button label="Save" onClick={this.handleSaveRhs} />
                    </Fieldset>
                </Dialog>

                <Dialog header="Confirmation"
                        visible={this.state.displayDeleteConditionDialog}
                        modal style={{ width: '450px' }}
                        footer={(
                            <div>
                                <Button label="No" icon="pi pi-times" onClick={() => this.setState({ displayDeleteConditionDialog: false })} className="p-button-text"/>
                                <Button label="Yes" icon="pi pi-check" onClick={() => {
                                    let currentQuestion = this.state.currentQuestion as any;
                                    delete currentQuestion.conditions;
                                    this.setState({
                                        currentQuestion,
                                        displayDeleteConditionDialog: false
                                    });
                                }} autoFocus/>
                            </div>
                        )}
                        onHide={() => this.setState({ displayDeleteConditionDialog: false })}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }}/>
                        <span>Voulez-vous Delete les conditions?</span>
                    </div>
                </Dialog>
                <Dialog header="Edit conditions"
                        visible={this.state.displayEditConditionDialog}
                        modal style={{ width: '650px' }}
                        footer={(
                            <div>
                                <Button
                                    label="Copier"
                                    style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                                    className="p-button-danger"
                                    onClick={async () => {
                                        prompt("Copy to clipboard: Ctrl+C, Enter", this.state.currentConditionEdit ?? '');
                                    }}
                                />
                                <Button label="Close"
                                        style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                                        className="p-button-danger"
                                        icon="pi pi-times" onClick={() => this.setState({ displayEditConditionDialog: false })}/>
                                <Button label="Save"
                                        className="p-button-danger"
                                        style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
                                        icon="pi pi-check"
                                        onClick={() => {
                                            let currentQuestion = this.state.currentQuestion as any;
                                            if (currentQuestion) {
                                                try {
                                                    currentQuestion.conditions = JSON.parse(this.state.currentConditionEdit);
                                                    this.setState({
                                                        currentQuestion,
                                                        displayEditConditionDialog: false,
                                                        currentConditionEdit: null
                                                    });
                                                } catch (e) {
                                                    this.toast?.show({
                                                        severity: SeverityEnum.ERROR,
                                                        detail: "Structure du JSON invalide !",
                                                    });
                                                }
                                            }
                                        }}/>
                            </div>
                        )}
                        onHide={() => this.setState({ displayEditConditionDialog: false })}
                >
                    <InputTextarea
                        className="conditionTextArea"
                        value={this.state.currentConditionEdit}
                        onChange={(e: any) => {
                            this.setState({
                                currentConditionEdit: e.target.value
                            });
                        }}
                        rows={10} cols={60}
                    />
                </Dialog>
            </div>
        );
    }
    else {
        return null;
    }
}
}

export default withTranslation()(FormEditorWorkPlace);