import { Button, Content, Panel, Table } from "@bbri/ui";
import { TFunction } from "i18next";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import React, { ReactNode } from "react";
import { ChevronLeft, FileText } from "react-feather";
import { withTranslation, WithTranslation } from "react-i18next";
import { utils, writeFile } from "xlsx";
import { Form } from "../models/Form";
import { FormQuestion } from "../models/FormQuestion";
import { RequirementResult, RequirementResults } from "../models/RequirementResults";
import { FormUtils } from "../utils/FormUtils";
import "./FormResults.scss";
import { JsPDFUtils } from '../../shared/utils/JsPDFUtils';
import { FormService } from "../services/FormService";
import { AxiosResponse } from "axios";
import { Dialog } from "primereact/dialog";
import ReactGA from "react-ga";
import { FileService } from "../services/FileService";
import { QuestionType } from "../enum/QuestionType";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import FormPVGraph from "./FormPVGraph"
import { Dot } from "../models/Dot";
import { FormType } from "../enum/FormType";
import { DecisionTreeService } from "../services/DecisionTreeService";
import { DiagramCommentService } from "../services/DiagramCommentService";
import { DiagramService } from "../services/DiagramService";
import { Diagram } from "../models/Diagram"
import FormDiagram from "./FormDiagram"
import {ReactGAUtils} from "../../shared/utils/ReactGAUtils";
import HTMLtoDOCX from 'html-to-docx';
import {FileUtils} from "../../shared/utils/FileUtils";
import { DiagramComment } from "../models/DiagramComment";
import { DiagramDot } from "../models/DiagramDot";
import { Graph } from "../models/Graph";
import draw2d from "draw2d";
import {Submission} from "../../user/models/Submission";
import {SubmissionService} from "../../user/services/SubmissionService";
import {AuthUser} from "../../login/models/AuthUser";
import {AuthService} from "../../login/services/AuthService";
import {ResponsesDTO} from "../dto/ResponsesDTO";
import {InputText} from "primereact/inputtext";
import {Toast} from "primereact/toast";
import {SeverityEnum} from "../../shared/enum/SeverityEnum";
import html2canvas from "html2canvas";
import {GraphPDFUtils} from "../../shared/utils/GraphPDFUtils";
const juice = require("juice")

interface Props extends WithTranslation {
  form: Form;
  history: number[];
  values: Record<string, any>;
  idSubmittedSubmission: string | null
  submission?: Submission
  results: RequirementResult[];
  onResetClick: () => void;
  onPreviousClick: () => void;
  onlyResult?: boolean
  connected?: boolean
}

interface States {
  openConfirmationModal: boolean;
  openSubmissionNameModal: boolean
  openSubmissionEditModal: boolean
  newSubmissionName: string
  htmlTable: string;
  loadingHTML: boolean;
  loadingPDF : boolean
  allComments: DiagramComment[]
  graphInfo: Dot[] | string
  diagramName: string
  diagramInfo: Diagram | null,
  translationsReady: boolean,
}

class FormResults extends React.Component<Props, States> {
  private t: TFunction;
  private toast : Toast | null
  private xlsxData: any[];
  private formService: FormService;
  private isOnMobile: boolean = false;
  private html2render: string = '';
  private fileService: FileService;
  private decisionTreeService: DecisionTreeService
  private diagramCommentService: DiagramCommentService
  private diagramService: DiagramService
  private submissionService: SubmissionService
  private authService : AuthService

  constructor(props: Props) {
    super(props);
    this.t = this.props.t;
    this.toast = null
    this.xlsxData = [];
    this.formService = new FormService();
    this.fileService = new FileService();
    this.decisionTreeService = new DecisionTreeService()
    this.diagramCommentService = new DiagramCommentService()
    this.diagramService = new DiagramService();
    this.submissionService = new SubmissionService()
    this.authService = new AuthService();
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isOnMobile = true;
    }
    this.state = {
      openConfirmationModal: false,
      openSubmissionNameModal: false,
      openSubmissionEditModal: false,
      newSubmissionName: "",
      htmlTable: '',
      loadingHTML: true,
      allComments: [],
      graphInfo: [],
      diagramInfo: null,
      loadingPDF: false,
      diagramName: "",
      translationsReady: false,
    };

    this.props.i18n.reloadResources().then(() => {
      console.log('Translations forcefully reloaded');
      this.fileService.getOne('tableau-resultat').then((r) => {
        if (r.data.html) {
          this._replaceTagsInHTMLFile(r.data.html);
          this.setState({ translationsReady: true });
        }
        else {
          this.setState({
            loadingHTML: false,
          });
        }
      }, () => {
        this.setState({
          loadingHTML: false,
        });
      });
    });
  }

  componentDidMount(): void {
    if (this.props.form.type === FormType.GRAPH) {
      this._getGraphInfo()
      this._getDiagramInfo()
      this._getComments()
    }
    this._getDefaultSubmissionName()
  }

  private _getGraphInfo() {
    if (this.props.form.graphDecisionTree) {
      this.decisionTreeService.computeDecisionTree(this.props.form.graphDecisionTree, this.props.values)
        .then(
          (r) => {
            if (typeof r !== "string") {
              this.setState({
                graphInfo: r as any as Dot[]
              })
            }
            else {
              this.setState({
                graphInfo: r as string
              })
            }
          }
        )
        .catch(
          (e) => {
            this.setState({
              graphInfo: e.message
            })
          }
        )
    }
  }

  private async _getDiagramInfo() {
    if (this.props.form.diagramDecisionTree) {
      try {
        const diagramName = await this.decisionTreeService.computeDecisionTree(this.props.form.diagramDecisionTree, this.props.values)
        const diagramInfo = await this.diagramService.getOneDiagramByName(diagramName as string)
        this.setState({
          diagramName: diagramName as string ?? "",
          diagramInfo: diagramInfo
        })
      }
      catch {
        this.setState({
          diagramInfo: null
        })
      }
    }
  }

  private _getComments() {
    this.diagramCommentService.getAllComments()
      .then((r) => {
        this.setState({
          allComments: r.data
        })
      })
  }

  private _getDefaultSubmissionName() {
    if (this.props.form.defaultSubmissionName) {
      this.decisionTreeService.computeDecisionTree(this.props.form.defaultSubmissionName, this.props.values)
          .then(
              (r) => {
                if (typeof r === "string") {
                  this.setState({
                    newSubmissionName: this.formService.t(this.t, r, false)
                  })
                }
              }
          )
          .catch(e => console.log(e))
    }
  }

  private _replaceTagsInHTMLFile(htmlFile: string) {
    this.html2render = htmlFile;
    //Replace questions
    for (const qst of this.props.form.questions) {
      const replace = `{{question.${ qst.question }}}`;
      const re = new RegExp(replace, 'g');
      if (this.html2render) {
        this.html2render = this.html2render.replace(re, ((qst.question in this.props.values) ? this.formService.t(this.t, qst.question, false) : ''));
      }
    }

    //Replace values
    for (const [key, value] of Object.entries(this.props.values)) {
      const replace = `{{value.${ key }}}`;
      const re = new RegExp(replace, 'g');
      //Replace value as question table
      if (typeof value === 'object') {
        let question: any = null;
        let tableHeader = '';
        this.props.form.questions.forEach((q) => {
          if (q.question === key) {
            question = q;
          }
        });
        if (question.subQuestions) {
          for (const subQ of question.subQuestions) {
            tableHeader += `<td>${ this.formService.t(this.t, subQ.question, false) }</td>`;
          }
        }
        let table: any = null;
        table = `<table><thead><tr>${ tableHeader }</tr></thead><tbody>${ this._getTableBody(question, value) }</tbody></table>`;
        this.html2render = this.html2render.replace(re, table);
      }
      else {
        switch (value) {
          case true:
            this.html2render = this.html2render.replace(re, this.formService.t(this.t, "oui", false));
            break;
          case false:
            this.html2render = this.html2render.replace(re, this.formService.t(this.t, "non", false));
            break;
          default:
            this.html2render = this.html2render.replace(re, this.formService.t(this.t, value, false));
            break;
        }
      }
    }

    //Replace requirement
    for (const [i, value] of Object.entries(this.props.results)) {
      const replaceAnswer = `{{requirement.answer.${ value.requirement }}}`;
      const replaceRequirement = `{{requirement.requirement.${ value.requirement }}}`;
      const replaceRequirementTitle = `{{requirement.title.${ value.requirement }}}`;

      if (value.answer && typeof value.answer === 'object') {
        if (Array.isArray(value.answer)) {
          let multipleAnswerHTML = ''
          for (const ans of value.answer) {
            multipleAnswerHTML += this.formService.t(this.t, ans, true, false, true)
          }
          const re = new RegExp(replaceAnswer, 'g');
          this.html2render = this.html2render.replace(re, multipleAnswerHTML);
        }
        // else {
        //   this.html2render = this._replaceHtml(replaceAnswer, value.answer.map(x => this.formService.t(this.t, x, false)).join(''));
        // }
      }
      else if (typeof value.answer === 'string' && value.answer.includes("{{translation.")) {
        let answer = value.answer as any

        let result: any = null;
        const regex = new RegExp(/[{]{2}translation[.](.+?)[}]{2}/g);
        for (let j = 0; j < 25; j++) {
          while (null !== (result = regex.exec(answer))) {
            answer = answer.replace(`{{translation.${ result[1] }}}`, this.formService.t(this.t, result[1], true, false, true));
          }
        }

        this.html2render = this.html2render.replace(replaceAnswer, answer)
      }
      else {
        // @ts-ignore
        this.html2render = this._replaceHtml(replaceAnswer, value.answer);
      }
      this.html2render = this._replaceHtml(replaceRequirement, value.requirement);
      this.html2render = this._replaceHtml(replaceRequirementTitle, value.requirementTitle);
    }

    let result: any = null;
    const regex = new RegExp(/[{]{2}translation[.](.+?)[}]{2}/g);
    while (null !== (result = regex.exec(this.html2render))) {
      this.html2render = this.html2render.replace(`{{translation.${ result[1] }}}`, this.formService.t(this.t, result[1], true, false, true));
    }

    let resultImage: any = null;
    const regexImage = new RegExp(/[{]{2}image[.](.+?)[}]{2}/g);
    while (null !== (resultImage = regexImage.exec(this.html2render))) {
      this.html2render = this.html2render.replace(`{{image.${ resultImage[1] }}}`, this.formService.t(this.t, resultImage[1], false));
    }

    //Replace remaining tags
    const replace = `{{value.*?}}`;
    const re = new RegExp(replace, 'g');
    this.html2render = this.html2render.replace(re, '');
    const replace2 = `{{question.*?}}`;
    const re2 = new RegExp(replace2, 'g');
    this.html2render = this.html2render.replace(re2, '');
    const replace3 = `{{requirement.*?}}`;
    const re3 = new RegExp(replace3, 'g');
    this.html2render = this.html2render.replace(re3, '');
    const replace4 = `{{image.*?}}`;
    const re4 = new RegExp(replace4, 'g');
    this.html2render = this.html2render.replace(re4, '');

    let resultTitle: any = null;
    const regexTitle = new RegExp(/(<[hH]\d.*?>)([^<]+?)(<\/[hH]\d.*?>)/, 'g');
    while (null !== (resultTitle = regexTitle.exec(this.html2render))) {
      this.html2render = this.html2render.replace(resultTitle[0], `${ resultTitle[1] }${ resultTitle[2].replace(/ /g, '&nbsp;') }${ resultTitle[3] }`);
    }

    const fakeHTMLElement = document.createElement("div")

    fakeHTMLElement.innerHTML = this.html2render;
    const aElements = fakeHTMLElement.getElementsByTagName("a")

    for (let i = 0; i < aElements.length; i++) {
      aElements[i].setAttribute("target", "_blank")
    }

    this.html2render = fakeHTMLElement.innerHTML;

    this.setState({
      loadingHTML: false,
      htmlTable: this.html2render,
      openConfirmationModal: false,
    });
  }

  private _getTableBody(question: FormQuestion, value: any) {
    let tableBody = '';
    for (let i = 0; i < /*this._getSize(question)*/ value.length; i++) {
      tableBody += `<tr>${ this._getTableRow(question, value[i], i) }</tr>`;
    }
    return tableBody;
  }

  private _getTableRow(question: FormQuestion, el: any, i: number) {
    let row = '';
    if (question.subQuestions) {
      for (const subQ of question.subQuestions) {
        if (subQ.type === QuestionType.PREVIOUS_QUESTION && this.props.values[subQ.target][i]) {
          row += `<td>${ this.props.values[subQ.target][i][subQ.question] }</td>`;
        }
        else {
          // row += `<td>${el[subQ.question]}</td>`;
          row += `<td>${ this.formService.t(this.t, el[subQ.question], false) }</td>`;
        }
      }
    }
    return row;
  }

  private getTodayDate() {
    const today = new Date();

    const month = today.getMonth() + 1;

    return `${ today.getDate() }/${ month < 10 ? '0' : '' }${ month }/${ today.getFullYear() }`;
  }

  /**
   * Export XLSX
   */
  exportXLSX() {
    const workbook = utils.book_new();

    // = Summary
    const summaryData = [
      [this.formService.t(this.t, "ui_summary_date", false), this.getTodayDate()],
      [this.formService.t(this.t, "ui_summary_question", false), this.formService.t(this.t, "ui_summary_response", false)],
    ];

    this.createSummaryTable(summaryData);

    const summaryWs = utils.aoa_to_sheet(summaryData);
    utils.book_append_sheet(workbook, summaryWs, this.formService.t(this.t, "ui_summary_title", false));

    // = Requirements
    const requirementsWs = utils.aoa_to_sheet(this.xlsxData);
    utils.book_append_sheet(
      workbook,
      requirementsWs,
      this.formService.t(this.t, "ui_requirement_results_title", false)
    );

    // = Disclaimer
    const disclaimerWs = utils.aoa_to_sheet([[this.formService.t(this.t, "disclaimer_text", false).replace('<br>', '\n')]]);
    utils.book_append_sheet(
      workbook,
      disclaimerWs,
      this.formService.t(this.t, "disclaimer_title", false)
    );

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.formService.sendXLSX(this.props.form.form, workbook).then((response: AxiosResponse<RequirementResults>) => {
        if (Capacitor.isNativePlatform()) {
          Browser.open({ url: `${ process.env.REACT_APP_API_URL }/` + response.data['link'] });
        }
        else {
          window.open(`${ process.env.REACT_APP_API_URL }/` + response.data['link'], '_blank', 'location=no');
        }

      });
    }
    else {
      writeFile(workbook, "results.xlsx");
    }
  }

  /**
   * Export PDF
   */
  async exportPDF(fromWord : boolean = false) {
    // Document configured via backend
    if (this.state.htmlTable) {

      // From docx

      const element = document.getElementById("htmlTemplate")
      if (element) {
        if (fromWord) {
          this.setState({
            loadingPDF: true
          })
          try {
            await this.submissionService.exportSubmissionToWord(this.props.idSubmittedSubmission!, this.props.i18n.language).then((response: AxiosResponse<Blob>) => {
              this.fileService.convertDocxToPdf(response.data)
                  .then((res) => {
                    FileUtils.downloadFile(res)
                    this.setState({
                      loadingPDF: false
                    })
                  })
            })
          }
          catch (e) {
            console.log(e)
            this.setState({
              loadingPDF: false
            })
          }
        }
        else {
          let clientWidth = 0;
          let mainContainer;

          // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          if (element) {
            clientWidth = element.clientWidth;
            if (this.props.form.pdfWidth) {
              element.style.width = this.props.form.pdfWidth + "px";
            }
            else {
              element.style.width = "1200px";
            }

            mainContainer = element.querySelector('.maincontainer');

            if (mainContainer) {
              mainContainer.classList.add('print');
            }
            // element.style.width = "1702px";
          }
          // }

          const pdf = new jsPDF('p', 'pt', 'a4');

          const htmlToConvert = document.createElement("div")
          htmlToConvert.innerHTML = (document.getElementById("htmlTemplate") as HTMLElement).innerHTML
          if (this.props.form.pdfWidth) {
            htmlToConvert.style.width = this.props.form.pdfWidth + "px";
          }
          else {
            htmlToConvert.style.width = "1200px";
          }

          pdf.html(htmlToConvert, {
            y: 0,
            x: 0,
            autoPaging:'text',
            margin: 50,
            html2canvas: {
              scale: 0.5,
              svgRendering: true,
              scrollX: 0,
              scrollY: 0
            },
            callback: () => {
              if (element) {
                element.style.width = clientWidth + "px";

                if (mainContainer) {
                  mainContainer.classList.remove('print');
                }
              }

              if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

                this.formService.sendPDF(this.props.form.form, pdf.output('blob')).then((response: AxiosResponse<RequirementResults>) => {
                  if (Capacitor.isNativePlatform()) {
                    Browser.open({ url: `${ process.env.REACT_APP_API_URL }/` + response.data['link'] });
                  }
                  else {
                    window.open(`${ process.env.REACT_APP_API_URL }/` + response.data['link'], '_blank', 'location=no');
                  }
                });
                // if(element) {
                //   element.style.width = clientWidth+"px";
                // }
              }
              else {
                pdf.save("results.pdf");
              }
            }
          });
        }

      }

      return;
    }

    // Default behavior
    JsPDFUtils.initJsPDF();

    const doc = new jsPDF();
    doc.setFont('Roboto-Bold', 'normal');
    doc.setFontSize(10);

    const headerHeight = 10;

    const langImage = new Image();
    langImage.src = this.formService.t(this.t, 'bbri_logo', false, false, false, true);
    doc.addImage(langImage, 'png', 13, 10, headerHeight * 4, headerHeight);

    const logoImage = new Image();
    logoImage.src = this.formService.t(this.t, 'logo_app', false, false, false, true);
    const logoImageRapport = logoImage.width > logoImage.height ? logoImage.width / logoImage.height : logoImage.height / logoImage.width;
    if (logoImageRapport > 2) {
      doc.addImage(logoImage, 'png', 210 - 5 * headerHeight, 10, (headerHeight) * logoImageRapport, headerHeight);
    }
    else {
      doc.addImage(logoImage, 'png', 210 - 5 * headerHeight, 10, (headerHeight) / logoImageRapport, headerHeight);
    }

    doc.setFontSize(7);
    doc.text('Version 1 - 15.01.21', 210 - 5 * headerHeight, headerHeight + 12);
    doc.setFontSize(10);

    autoTable(doc, {
      startY: headerHeight + 20,
      body: [
        [
          `${ this.formService.t(this.t, "ui_pdf_date", false) } : ${ this.getTodayDate() }`,
        ],
      ]
    });

    // = Summary
    doc.text(this.formService.t(this.t, "ui_summary_title", false), 13, (doc as any).autoTable.previous.finalY + 10);
    const summaryTable: any = {
      head: [
        [
          this.formService.t(this.t, "ui_summary_question", false),
          this.formService.t(this.t, "ui_summary_response", false)
        ]
      ],
      body: [],
      startY: (doc as any).autoTable.previous.finalY + 15,
      headStyles: {
        font: 'Roboto-Bold',
        fontStyle: 'normal'
      },
      styles: {
        font: 'Roboto-Regular',
        fontStyle: 'normal'
      }
    };

    this.createSummaryTable(summaryTable.body);

    autoTable(doc, summaryTable);

    // = Requirements
    doc.text(
      this.formService.t(this.t, "ui_requirement_results_title", false),
      13,
      (doc as any).autoTable.previous.finalY + 10
    );


    const body: string[][][] = [];
    const myData = document.getElementById("table-results") as HTMLTableElement;
    const myDataRows = myData.rows as any;
    for (const myDataRow of myDataRows) {
      if (myDataRow.cells.length === 3) {
        let el1 = myDataRow.cells[0].innerText;
        let el2 = myDataRow.cells[1].innerText.replace(/<\/?[^>]+(>|$)/g, "");
        let el3 = myDataRow.cells[2].innerHTML
          .replace(/<\/span>/g, '\n\n')
          .replace(/<li>/g, '\n-')
          .replace(/<\/li><\/ul>/g, '\n\n')
          .replace(/<\/?[^>]+(>|$)/g, "");
        body.push([el1, el2, el3]);
      }
      else {
        body.push(['', '', myDataRow.cells[0].innerHTML
          .replace('</span>', '\n\n')
          .replace(/<\/?[^>]+(>|$)/g, "")
        ]);
      }
    }
    let head = body[0];
    head.shift();
    head.unshift([""]);
    body.shift();
    // @ts-ignore
    head[2] = head[2].replace(/<\/?[^>]+(>|$)/g, "");

    doc.setFont('Roboto-Regular', 'normal');
    autoTable(doc, {
      head: [head],
      //html: "#table-results",
      startY: (doc as any).autoTable.previous.finalY + 15,
      body: body,
      headStyles: {
        font: 'Roboto-Bold',
        fontStyle: 'normal'
      },
      styles: {
        font: 'Roboto-Regular',
        fontStyle: 'normal'
      }
    });

    doc.setFont('Roboto-Regular', 'normal');
    doc.setFontSize(8);
    let y = (doc as any).autoTable.previous.finalY + 20;
    y = this.writeMultiLinesText(doc, this.formService.t(this.t, "disclaimer_title", false), y);
    this.writeMultiLinesText(doc, this.formService.t(this.t, "disclaimer_text", false), y);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

      this.formService.sendPDF(this.props.form.form, doc.output('blob')).then((response: AxiosResponse<RequirementResults>) => {
        if (Capacitor.isNativePlatform()) {
          Browser.open({ url: `${ process.env.REACT_APP_API_URL }/` + response.data['link'] });
        }
        else {
          window.open(`${ process.env.REACT_APP_API_URL }/` + response.data['link'], '_blank', 'location=no');
        }
      });
    }
    else {
      doc.save("results.pdf");
    }
  }

  private async generateHtmlDoc(htmlResults: HTMLElement) {

    const logoApp = this.formService.t(this.t, 'logo_app', false, false, false, true);

    let header = `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8" /><title>Document</title>`;

    // const logoImage = new Image()
    // logoImage.src = logoApp
    // const logoImageRapport = logoImage.width > logoImage.height ? logoImage.width / logoImage.height : logoImage.height / logoImage.width;
    // let width = 0
    // if (logoImageRapport > 2) {
    //   width = 50 * logoImageRapport
    // }
    // else {
    //   width = 50 / logoImageRapport
    // }

    const logoData = await this.getBase64FromUrl(logoApp)

    let logo = `<img style="height: 50px" src="${logoData}" alt="Logo"/>`
    let footer = "</body></html>";

    let result = htmlResults.innerHTML

    const styleTagRegex = /<style>[\s\S]*?<\/style>/i;

    const styleTagMatch = result.match(styleTagRegex)

    if (styleTagMatch) {
      result = result.replace(styleTagMatch[0], "").trim()
      header += styleTagMatch[0]
    }

    header += "</head><body>"

    // return header+logo+result+footer
    return juice(header+logo+result+footer)
  }

  private async getBase64FromUrl(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  // private async exportWord() {
  //   const htmlResults = document.getElementById("htmlTemplate")
  //   if (htmlResults) {
  //     //DOCX
  //     try {
  //       const blob : Blob = await HTMLtoDOCX(await this.generateHtmlDoc(htmlResults), null)
  //
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       document.body.appendChild(a);
  //       a.href = url;
  //       a.download = "results.docx";
  //       a.click();
  //       document.body.removeChild(a);
  //     }
  //     catch (e) {
  //       console.log(e)
  //     }
  //
  //     // DOC
  //     // const source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(this.generateHtmlDoc(htmlResults))
  //     // let fileDownload = document.createElement("a");
  //     // document.body.appendChild(fileDownload);
  //     // fileDownload.href = source;
  //     // fileDownload.download = 'results.doc';
  //     // fileDownload.click();
  //     // document.body.removeChild(fileDownload);
  //   }
  // }

  private async exportWord() {
    this.submissionService.exportSubmissionToWord(this.props.idSubmittedSubmission!, this.props.i18n.language).then((response: AxiosResponse<Blob>) => {
      FileUtils.downloadFile(response);
    })
  }

  writeMultiLinesText(doc, text, y) {
    const pageHeight = doc.internal.pageSize.height;
    const lines = doc.splitTextToSize(text, 175);

    for (const line of lines) {
      if (y > pageHeight - 20) {
        doc.addPage();
        y = 15;
      }

      doc.text(line, 13, y);
      y += doc.getTextDimensions(text).h;
    }

    return y;
  }

  /**
   * Create sumary table
   */
  createSummaryTable(table: any[]) {
    FormUtils.formatValue(
      this.t,
      this.formService,
      this.props.form,
      this.props.history,
      this.props.values,
      (id: number, question: FormQuestion, value: string) => {
        let valueToRender = value;
        switch (question.type) {
          // case QuestionType.DROPDOWN:
          default:
            valueToRender = this.formService.t(this.t, value, false);
            break;
        }
        table.push([this.formService.t(this.t, question.question, false), valueToRender]);
      }
    );

  }

  /**
   * Create table row according to the results
   */
  createRowResults() {
    const data: ReactNode[] = [];
    this.xlsxData = [
      [
        "",
        this.formService.t(this.t, "ui_requirement_results_requirement", false, false, true),
        this.formService.t(this.t, "ui_requirement_results_result", false, false, true),
      ],
    ];

    const requirementMap = {};
    // = Sort by requirement
    for (const result of this.props.results) {
      if (!result.answer) {
        continue;
      }
      if (!requirementMap.hasOwnProperty(result.requirement)) {
        requirementMap[result.requirement] = [];
      }
      requirementMap[result.requirement].push(result);
    }

    for (const requirement in requirementMap) {
      const options = requirementMap[requirement];
      let optionI = 0;

      for (const option of options) {
        if (!option.answer) {
          continue;
        }
        let answers = "";


        if (option.prefix) {
          answers += `${ this.formService.t(this.t, option.prefix, true, false, true) } `;
        }

        if (Array.isArray(option.answer)) {
          for (const answer of option.answer) {
            answers += this.formService.t(this.t, answer, true, false, true);
          }
        }
        else {
          answers = this.formService.t(this.t, option.answer, true, false, true);
        }

        if (option.suffix) {
          answers += ` ${ this.formService.t(this.t, option.suffix, true, false, true) }`;
        }

        answers = answers.replace(/<p(\s*|>)/g, "<span$1").replace(/<\/p>/g, "</span>");

        // = For XLSX data export

        // Remove HTML tags
        let results = answers.replace(/<br\/?>/g, "\n");
        results = results.replace(/<\/?[A-Za-z]+>/g, "");
        this.xlsxData.push([
          requirement.toUpperCase(),
          this.formService.t(this.t, option.requirementTitle, false),
          results,
        ]);

        // = Render
        if (optionI === 0) {
          data.push(
            <tr key={ option.requirementTitle + "-" + optionI }>
              <td rowSpan={ options.length }>{ requirement.toUpperCase() }</td>
              <td rowSpan={ options.length }>
                { this.formService.t(this.t, option.requirementTitle, true) }
              </td>
              <td dangerouslySetInnerHTML={ { __html: answers } }></td>
            </tr>
          );
        }
        else {
          data.push(
            <tr key={ option.requirementTitle + "-" + optionI }>
              <td dangerouslySetInnerHTML={ { __html: answers } }></td>
            </tr>
          );
        }
        optionI++;
      }
    }
    return data;
  }


  private openConfirmation(props: Props) {
    //props.onResetClick();
  }

  private async loginAndSave() {
    const user = await this.authService.loginSSO()
    this.setState({
      openSubmissionNameModal: true
    })
  }

  renderDialogFooter() {
    return (
      <div>
        <button
          className="confirm-button"
          onClick={ () => this.setState({ openConfirmationModal: !this.state.openConfirmationModal }) }
        >
          { this.formService.t(this.t, "ui_input_no", true) }
        </button>
        <button
          id="newForm"
          className="confirm-button"
          onClick={ () => {
            ReactGA.event({
              category: ReactGAUtils.getCategory(),
              action: 'click',
              label: 'Nouveau formulaire'
            });
            this.props.onResetClick();
          } }>
          { this.formService.t(this.t, "ui_input_yes", true) }
        </button>
      </div>
    );
  }

  private _replaceHtml(replaceElement: string, value: string) {
    const re = new RegExp(replaceElement, 'g');
    return this.html2render.replace(re, this.formService.t(this.t, value, true, false, true));
  }

  private _getTableResult() {
    if (this.html2render) {
      return (<div dangerouslySetInnerHTML={ { __html: this.state.htmlTable } }></div>);
    }
    else if (this.props.form.type === FormType.GRAPH) {
      return (
        <div>
          { this.state.graphInfo.length > 0 && Array.isArray(this.state.graphInfo) ? (
            <>
              <div style={{ display: "flex" }}>
                <h2 style={{ marginBottom: 10 }}>{this.formService.t(this.t, "ui_result_graph_title", true)}</h2>
              </div>
              <div>
                <div id="graph-result" >
                  {this.props.results.map(
                      r => <p><strong>{this.formService.t(this.t, r.requirementTitle, false)}</strong> : {this.formService.t(this.t, r.answer.toString().toLowerCase(), false)}</p>
                  )}
                </div>
                <small >
                  <i style={{ marginTop: "20px" }} className="pi pi-info-circle"/> {this.formService.t(this.t, "ui_graph_notes", false)}
                </small>
                <FormPVGraph graphs={[{
                  title: this.state.diagramName,
                  dots: this.state.graphInfo
                }]}></FormPVGraph>
              </div>
            </>
          ) : (
              <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>{this.formService.t(this.t, this.state.graphInfo.toString(), false)}</h3>
                  <Button onClick={() => GraphPDFUtils.exportGraphPDF(
                      this.formService.t(this.t, "title_app", true),
                      this.formService.t(this.t, "ui_result_graph_title", false),
                      this.formService.t(this.t, "ui_comparaison_table_title", false),
                      this.formService.t(this.t, "ui_result_diagram_title", false),
                      this.formService.t(this.t, 'logo_app', false, false, false, true),
                      this.formService.t(this.t, 'bbri_logo', false, false, false, true)
                  )}>PDF</Button>
                </div>
                <br/>
                <div id="graph-result" >
                  {this.props.results.map(
                      r => <p><strong>{this.formService.t(this.t, r.requirementTitle, false)}</strong> : {this.formService.t(this.t, r.answer.toString().toLowerCase(), false)}</p>
                  )}
                </div>
              </>
          )}
          {this.state.diagramInfo && (
            <>
              <h2 style={{ marginBottom: 10, marginTop: 20 }}>{this.formService.t(this.t, "ui_result_diagram_title", true)}</h2>
              <FormDiagram diagram={this.state.diagramInfo}
                comments={this.state.allComments}
                editable={false}
              ></FormDiagram>
            </>
          )}
        </div>
      )
    }
    else if (!this.state.loadingHTML) {
      return (<Table id="table-results">
        <thead>
        <tr>
          <th style={ { width: "10%" } }></th>
          <th style={ { width: "30%" } }>
            { this.formService.t(this.t, "ui_requirement_results_requirement", true) }
          </th>
          <th style={ { width: "60%" } }>
            { this.formService.t(this.t, "ui_requirement_results_result", true) }
          </th>
        </tr>
        </thead>
        <tbody>{ this.createRowResults() }</tbody>
      </Table>);
    }
  }




  render() {
    window.scroll(0, 0);
    if (!this.state.translationsReady) {
      return <div>Loading...</div>;
    }

    return (

      <div className="container containerSummary">
        <Toast ref={(el) => (this.toast = el)}/>
        <div className="table">
          <Content>
            <Content.Header>
              <div className="table-results-header">
                <h1>{ this.formService.t(this.t, "ui_requirement_results_title", true) }</h1>
                <div className="table-results-actions">
                  {(this.props.form.type === FormType.REQUIREMENT && !this.html2render) && (
                    <Button id="exportXls" variant="action" onClick={ () => {
                      ReactGA.event({
                        category: ReactGAUtils.getCategory(),
                        action: 'click',
                        label: 'Excel'
                      });
                      this.exportXLSX();
                    } }>
                      <i className="pi pi-file-excel"></i> XLSX
                    </Button>
                  )}
                  {(this.html2render && this.props.form.pdfFromDocx) ? (
                        <Button disabled={this.state.loadingPDF} id="exportWord" variant="action" onClick={ () => {
                          this.exportPDF(true);
                        } }>
                          <i className="pi pi-file-pdf"></i> PDF
                        </Button>
                  ) : (
                      <Button id="exportPdf" variant="action" onClick={ () => {
                        ReactGA.event({
                          category: ReactGAUtils.getCategory(),
                          action: 'click',
                          label: 'PDF'
                        });
                        this.exportPDF(false);
                      } }>
                        <i className="pi pi-file-pdf"></i> PDF
                      </Button>
                  )}
                  {(this.html2render && this.props.form.docxGeneration) && (
                      <Button id="exportWord" variant="action" onClick={ () => {
                        this.exportWord();
                      } }>
                        <i className="pi pi-file"></i> Word
                      </Button>
                  )}
                </div>
              </div>
            </Content.Header>
            <Content.Body>
              <Panel id="htmlTemplate">
                {
                  this._getTableResult()
                }
              </Panel>
              <div style={{ display: 'none' }}>
                <Table id="table-results">
                  <thead>
                  <tr>
                    <th style={ { width: "10%" } }></th>
                    <th style={ { width: "30%" } }>
                      { this.formService.t(this.t, "ui_requirement_results_requirement", true) }
                    </th>
                    <th style={ { width: "60%" } }>
                      { this.formService.t(this.t, "ui_requirement_results_result", true) }
                    </th>
                  </tr>
                  </thead>
                  <tbody>{ this.createRowResults() }</tbody>
                </Table>
              </div>
            </Content.Body>
          </Content>
        </div>
        <div className="form-actions-wrapper">
          <div className="left-actions">
            {!this.props.onlyResult && (
              <Button
                variant="secondary"
                onClick={() => this.props.onPreviousClick()}
              >
                <ChevronLeft />
                {this.formService.t(this.t, "ui_requirement_results_button_back_summary", true)}
              </Button>
            )}
          </div>
          <div className="right-actions">
            {this.props.connected && !this.props.onlyResult && (
                <>
                  <Button onClick={() => {
                    this.setState({
                      openSubmissionNameModal: true
                    })
                  }}>{this.formService.t(this.t, "ui_save_new_simulation", false)}</Button>
                  {this.props.submission && (
                      <Button onClick={() => {
                        this.setState({
                          openSubmissionEditModal: true
                        })
                      }}>{this.formService.t(this.t, "ui_modify_simulation", false)}{this.props.submission.name}</Button>
                  )}
                </>
            )}
            {this.props.connected && (
                <Button onClick={() => window.location.reload()}>{this.formService.t(this.t, "ui_go_back_to_user", false)}</Button>
            )}
            {(!this.props.connected && !this.props.onlyResult) && (
                <Button onClick={() => this.loginAndSave()}>{this.formService.t(this.t, "ui_login_save", false)}</Button>
            )}
            <Button onClick={() => {
              this.setState({ openConfirmationModal: !this.state.openConfirmationModal });
            }}>
              {this.formService.t(this.t, "ui_requirement_results_button_new_form", true)}
              <FileText/>
            </Button>
          </div>
        </div>
        <div className="confirm-dialog">
          <Dialog
            header={this.formService.t(this.t, "ui_confirmation_header", true)}
            visible={this.state.openConfirmationModal}
            modal
            focusOnShow={false}
            style={this.isOnMobile ? { width: "95%" } : { width: "450px" }}
            footer={this.renderDialogFooter()}
            onHide={() => this.setState({ openConfirmationModal: false })}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              <span>{this.formService.t(this.t, "ui_new_form_confirmation_body", true)}</span>

            </div>

          </Dialog>
          <Dialog
              header={this.formService.t(this.t, "ui_submission_name_dialog_header", true)}
              visible={this.state.openSubmissionNameModal}
              modal
              focusOnShow={false}
              onShow={() => (document.activeElement as HTMLElement).blur()}
              style={this.isOnMobile ? { width: "95%" } : { width: "450px" }}
              onHide={() => this.setState({ openSubmissionNameModal: false })}
          >
            <InputText style={{ width: "100%"}} value={this.state.newSubmissionName} onChange={(e: any) => this.setState({ newSubmissionName: e.target.value})}></InputText>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <Button onClick={() => {
                if (AuthService.user?.sub && this.props.idSubmittedSubmission) {
                  this.submissionService.saveLastSubmission(this.props.idSubmittedSubmission, this.state.newSubmissionName)
                      .then(() => {
                        window.location.href = "user"
                      })
                      .catch((e) => {
                        this.toast?.show({
                          severity: SeverityEnum.ERROR,
                          detail: this.formService.t(this.props.t, "ui_duplicate_name_error", false, false),
                        })
                      })
                }
              }}>{this.formService.t(this.props.t, "save", false)}</Button>
            </div>
          </Dialog>
          <Dialog
              header={this.formService.t(this.t, "ui_submission_edit_dialog_header", true)}
              visible={this.state.openSubmissionEditModal}
              modal
              focusOnShow={false}
              onShow={() => (document.activeElement as HTMLElement).blur()}
              style={this.isOnMobile ? { width: "95%" } : { width: "450px" }}
              onHide={() => this.setState({ openSubmissionEditModal: false })}
          >
            <div style={{display:"flex"}}>
              <Button onClick={() => {
                const responseDTO = (new ResponsesDTO(this.props.values)).responses
                this.formService.updateSubmission("default", {
                  id: this.props.submission?._id,
                  responses: responseDTO.filter(r => JSON.stringify(r.value) !== '{}'),
                  history: this.props.history,
                  name: this.props.submission?.name
                })
                    .then(() => {
                      window.location.href = "user"
                    })
              }}>{this.formService.t(this.props.t, "oui", false)}</Button>
              <Button onClick={() => this.setState({ openSubmissionEditModal: false })}>{this.formService.t(this.props.t, "non", false)}</Button>
            </div>

          </Dialog>
        </div>
      </div>
    );
  }


}

export default withTranslation()(FormResults);
