import {Button} from "@bbri/ui";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {RoleEnum} from "../../shared/enum/RoleEnum";
import {AuthService} from "../services/AuthService";
import {AuthUser} from "../models/AuthUser";
import {FormService} from '../../form/services/FormService';

interface Props extends WithTranslation {
}

function ButtonLogin(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const authService = new AuthService();
  const formService = new FormService();

  const handleClick = () => {

    authService.loginSSO()
        .then((user : AuthUser | undefined) => {
            if (user) {
                let from
                if (user.role === RoleEnum.ADMIN) {
                    from = { pathname: "/admin" };
                }

                if (user.role === RoleEnum.USER) {
                    from = { pathname: "/user" }
                }

                history.replace(from);
            }
        })
        .catch((e) => {

        })
    
  };

  return (
    <Button block type="button" onClick={() => {
      handleClick();
    }}>
      {formService.t(props.t, "button_login_sso", true)}
    </Button>
  );
}

export default withTranslation()(ButtonLogin);
