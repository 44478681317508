import { AxiosResponse } from 'axios';
import { APIService } from '../../shared/services/APIService';
import { ResponsesDTO } from '../dto/ResponsesDTO';
import { Form } from '../models/Form';
import { RequirementResults } from '../models/RequirementResults';
import { TFunction } from 'i18next';
import { RichText } from 'prismic-reactjs';
import ReactDOMServer from 'react-dom/server';
import { Submission } from '../../user/models/Submission';
import { SubmissionDTO } from "../dto/SubmissionDTO"
import {SubmissionSubmitDTO} from "../dto/SubmissionSubmitDTO";

export class FormService extends APIService {

  /**
   * Get form
   */
  getForm(name: string) {
    return this.http.get<Form>('/forms/' + name);
  }

  /**
   * Submit the form responses
   */
  async submitForm(formName: string, dto: SubmissionDTO) {
    return this.http.post<SubmissionSubmitDTO>(`/forms/${ formName }/submissions`, dto)
      .then((response: AxiosResponse<SubmissionSubmitDTO>) => {
        return response.data;
      });
  }

  async updateSubmission(formName: string, submission: SubmissionDTO) : Promise<Submission> {
    return this.http.put<Submission>(`/forms/${formName}/submissions`, submission)
      .then((response: AxiosResponse<Submission>) => {
        return response.data
      })
  }

  async sendPDF(formName: string, pdf: any) {
    const formData = new FormData();
    formData.append('pdf', pdf)
    return this.http.post<any>(`forms/${ formName }/pdf`, formData);
  }

  async sendXLSX(formName: string, xlsx: any) {
    return this.http.post<any>(`forms/${ formName }/xlsx`, xlsx);
  }

  checkIfValue(value: any, key: string) {
    return (value) ? value : key;
  }

  t(t: TFunction, key: string, asRichText: boolean, isUI: boolean = false, asStaticMarkup: boolean = false, isURL: boolean = false): any {
    if (isUI) {
      return t(key);
    }
    else if (isURL) {
      const translation = t(key, { returnObjects: true }) as any;
      if (translation.hasOwnProperty('image')) {
        return `${process.env.REACT_APP_API_URL}/images/${translation.image}/download`;
      }
      else if (translation.hasOwnProperty('url')) {
        return translation.url;
      }
      else {
        return null;
      }
    }

    if (asRichText) {
      const result = RichText.render(t(key, { returnObjects: true }));
      return result ? (asStaticMarkup ? ReactDOMServer.renderToStaticMarkup(result as any) : result) : t(key);
    }
    else {
      const result = RichText.asText(t(key, { returnObjects: true }));
      return result ? result : key;
    }
  }

  getQuestionsByType(t: string[]) {
    let types = t.join(',')
    return this.http.get<Form>('/forms/questions', { params: { types } });
  }
}
