
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {AuthService} from "../login/services/AuthService";
import {Submission} from "./models/Submission"
import {SubmissionService} from "./services/SubmissionService"
import {Button, Content, Panel} from "@bbri/ui";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import FormPage from "../form/FormPage";
import {Dialog} from "primereact/dialog";
import {Checkbox} from "primereact/checkbox";
import "./UserPage.scss"
import {DecisionTreeService} from "../form/services/DecisionTreeService";
import {Form} from "../form/models/Form";
import {FormService} from "../form/services/FormService";
import {Graph} from "../form/models/Graph";
import {Dot} from "../form/models/Dot";
import FormPVGraph from "../form/components/FormPVGraph";
import {FormUtils} from "../form/utils/FormUtils";
import {TFunction} from "i18next";
import {FormType} from "../form/enum/FormType";
import {ResponsesDTO} from "../form/dto/ResponsesDTO";
import {InputText} from "primereact/inputtext";
import {Toast} from "primereact/toast";
import {SeverityEnum} from "../shared/enum/SeverityEnum";
import {InputTextarea} from "primereact/inputtextarea";

interface Props extends WithTranslation {
}

interface States {
    form: Form | null
    submissions: Submission[]
    selectedSubmissions: Submission[]
    comparedGraphs: Graph[]
    highlightedValues: any[]
    editState: string
    currentSubmission: Submission | null
    duplicateSubmissionName: string
    newSubmissionName: string
    newSubmissionDescription: string
    showArchiveModal: boolean
    showArchiveMultipleModal: boolean
    showArchivedValue: boolean
    showDeleteModal: boolean
    showDuplicateModal: boolean
    showNameModal: boolean
    showDescriptionModal: boolean
}



class UserPage extends React.Component<Props, States> {

    private toast : Toast | null
    private submissionService: SubmissionService
    private decisionTreeService: DecisionTreeService
    private formService: FormService
    private t: TFunction;


    constructor(props: Props) {
        super(props);
        this.toast = null
        this.submissionService = new SubmissionService()
        this.decisionTreeService = new DecisionTreeService()
        this.formService = new FormService()
        this.t = this.props.t;
        this.state = {
            form: null,
            submissions: [],
            selectedSubmissions: [],
            comparedGraphs: [],
            highlightedValues: [],
            editState: "OVERVIEW",
            currentSubmission: null,
            duplicateSubmissionName: "",
            newSubmissionName: "",
            newSubmissionDescription: "",
            showArchiveModal: false,
            showArchiveMultipleModal: false,
            showArchivedValue: false,
            showDeleteModal: false,
            showDuplicateModal: false,
            showNameModal: false,
            showDescriptionModal: false
        }

        this._getSubmissions()
    }

    componentDidMount(): void {
        this.formService.getForm("default")
            .then((res) => {
                this.setState({
                    form: res.data
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    private _getSubmissions() {
        if (AuthService.user) {
            this.submissionService.getUserSubmissions()
                .then(
                    (res) => {
                        this.setState({
                            submissions: res.data
                        })
                    }
                )
        }
    }

    private handleNewSimulation() {
        this.setState({
            editState: "NEW",
            currentSubmission: null,
            comparedGraphs: []
        })
    }

    private handleUpdateClick(props: Submission) {
        this.setState({
            editState: "UPDATE",
            currentSubmission: props,
            comparedGraphs: []
        })
    }

    private handleResultClick(props: Submission): void {
        this.setState({
            editState: "RESULT",
            currentSubmission: props,
            comparedGraphs: []
        })
    }

    private handleArchiveClick(props: Submission): void {
        this.setState({
            showArchiveModal: true,
            currentSubmission: props,
            comparedGraphs: []
        })
    }

    private handleDeleteClick(props: Submission): void {
        this.setState({
            showDeleteModal: true,
            currentSubmission: props,
            comparedGraphs: []
        })
    }

    private async handleCloneClick(props: Submission) {

        this.setState({
            showDuplicateModal: true,
            currentSubmission: props,
        })

    }

    private async handleCompareSimulation() {
        let graphs: Graph[] = []
        for (const submission of this.state.selectedSubmissions) {
            if (this.state.form?.graphDecisionTree) {
                const result = await this.decisionTreeService.computeDecisionTree(this.state.form.graphDecisionTree, FormUtils.getValidValues(this.state.form.questions, submission.responses, submission.history, submission.history[submission.history.length - 1]))
                if (typeof result !== "string") {
                    graphs.push({
                        title: submission.name,
                        dots: result as any as Dot[]
                    })
                }
            }
        }
        this.setState({
            comparedGraphs: graphs,
            highlightedValues: []
        })
    }

    private async handleArchiveMultipleClick() {
        this.setState({
            showArchiveMultipleModal: true,
            comparedGraphs: []
        })
    }

    private async handleDuplicate() {
        if (AuthService.user?.sub && this.state.currentSubmission) {
            const data = await this.formService.submitForm("default", {
                responses: (new ResponsesDTO(this.state.currentSubmission.responses)).responses,
                history: this.state.currentSubmission.history,
                name: ""
            })
            try {
                await this.submissionService.saveLastSubmission(data.id, this.state.duplicateSubmissionName)
            }
            catch (e) {
                this.toast?.show({
                    severity: SeverityEnum.ERROR,
                    detail: this.formService.t(this.props.t, "ui_duplicate_name_error", false, false),
                })
            }
            this._getSubmissions()
            this.setState({
                showDuplicateModal: false
            })
        }
    }

    private handleChangeName() {
        if (this.state.currentSubmission) {
            this.submissionService.changeSubmissionName(this.state.currentSubmission?._id, this.state.newSubmissionName)
                .then(() => {
                    this.setState({
                        showNameModal: false
                    })
                    this._getSubmissions()
                })
        }
    }

    private handleChangeDescription() {
        if (this.state.currentSubmission) {
            this.submissionService.changeSubmissionDescription(this.state.currentSubmission?._id, this.state.newSubmissionDescription)
                .then(() => {
                    this.setState({
                        showDescriptionModal: false
                    })
                    this._getSubmissions()
                })
        }
    }

    private handleGraphClick(graphs: Graph[]) {
        this.setState({
            highlightedValues: [...this.state.highlightedValues, ...this.getHighlightedValueForDatable(graphs)]
        })
    }

    private getHighlightedValueForDatable(graphs: Graph[]) {
        const hlValue = [...graphs]

        const validValue : any = []



        for (const graph of hlValue) {
            for (const dot of graph.dots) {
                const currentRow = validValue.find(v => v.x === dot.x)
                if (currentRow) {
                    currentRow[graph.title] = Math.floor(dot.y) + " kW"
                }
                else {
                    validValue.push({
                        x: dot.x,
                        [graph.title]: Math.floor(dot.y) + " kW"
                    })
                }
            }
        }

        return validValue
    }

    private nameTemplate(props : Submission) {
        return (
            <div style={{ display: "flex", alignItems: "center"}}>
                <p style={{ marginRight: "10px" }}>{props.name}</p>
                <i style={{ cursor: "pointer"}}
                   title={this.formService.t(this.t, "modify_submission_name", false)}
                   className="pi pi-pencil"
                   onClick={() => {
                       this.setState({
                           showNameModal: true,
                           currentSubmission: props
                       })
                   }}></i>
            </div>
        )
    }

    private descriptionTemplate(props : Submission) {
        return (
            <div style={{ display: "flex", alignItems: "center"}}>
                <p style={{ display:"block",
                    marginRight: "10px",
                    overflow: "hidden",
                    maxHeight: "12em",
                    textOverflow: "ellipsis" }}>{props.description}</p>
                <i style={{ cursor: "pointer"}}
                   title={this.formService.t(this.t, "modify_submission_description", false)}
                   className="pi pi-pencil"
                   onClick={() => {
                       this.setState({
                           newSubmissionDescription: props.description,
                           showDescriptionModal: true,
                           currentSubmission: props
                       })
                   }}></i>
            </div>
        )
    }

    private actionButtonTemplate(props) {
        return (
            <div className="action-user">
                <i title={this.formService.t(this.t, "modify_submission", false)} className="pi pi-pencil" onClick={() => this.handleUpdateClick(props)}></i>
                <i title={this.formService.t(this.t, "result_submission", false)} className="pi pi-chart-line" onClick={() => this.handleResultClick(props)}></i>
                <i title={this.formService.t(this.t, "duplicate_submission", false)} className="pi pi-clone" onClick={() => this.handleCloneClick(props)}></i>
                <i title={this.formService.t(this.t, "archive_submission", false)} className="pi pi-inbox" onClick={() => this.handleArchiveClick(props)}></i>
                <i title={this.formService.t(this.t, "delete_submission", false)} className="pi pi-trash" onClick={() => this.handleDeleteClick(props)}></i>
            </div>
        )
    }

    private dateTemplate(props: Submission) {
        return (new Date(props.submissionDate)).toLocaleDateString()
    }

    private resultTemplate(props: Submission, title: string) {
        let results: string = ""
        for (let r of props.results) {
            if (r.requirementTitle === title) {
                results = r.answer
            }
        }
        return this.formService.t(this.t, results, false)
    }

    private archiveModal() {
        return (
            <Dialog
                visible={this.state.showArchiveModal}
                onHide={() => this.setState({ showArchiveModal: false })}
                style={{ width: "800px" }}
                header={this.formService.t(this.t, "ui_archive_submission_modal", false)}
            >
                <div style={{ display: "flex" }}>
                    <Button onClick={() => {
                        if (this.state.currentSubmission?._id) {
                            this.submissionService.archiveSubmission(this.state.currentSubmission?._id)
                                .then(() => {
                                    this._getSubmissions()
                                    this.setState({ showArchiveModal: false })
                                })
                        }
                    }}>{this.formService.t(this.t, "oui", false)}</Button>
                    <Button onClick={() => this.setState({ showArchiveModal: false })}>{this.formService.t(this.t, "non", false)}</Button>
                </div>
            </Dialog>
        )
    }

    private archiveMultipleModal() {
        return (
            <Dialog
                visible={this.state.showArchiveMultipleModal}
                onHide={() => this.setState({ showArchiveMultipleModal: false })}
                style={{ width: "800px" }}
                header={this.formService.t(this.t, "ui_archive_submissions_modal", false)}
            >
                <div style={{ display: "flex" }}>
                    <Button onClick={() => {
                        this.submissionService.archiveMultipleSubmission(this.state.selectedSubmissions.map(s => s._id))
                            .then(() => {
                                this._getSubmissions()
                                this.setState({ showArchiveMultipleModal : false })
                            })
                    }}>{this.formService.t(this.t, "oui", false)}</Button>
                    <Button onClick={() => this.setState({ showArchiveMultipleModal: false })}>{this.formService.t(this.t, "non", false)}</Button>
                </div>
            </Dialog>
        )
    }

    private deleteModal() {
        return (
            <Dialog
                visible={this.state.showDeleteModal}
                onHide={() => this.setState({ showDeleteModal: false })}
                style={{ width: "800px" }}
                header={this.formService.t(this.t, "ui_delete_submission_modal", false)}
            >
                <div style={{ display: "flex" }}>
                    <Button onClick={() => {
                        if (this.state.currentSubmission?._id) {
                            this.submissionService.deleteSubmission(this.state.currentSubmission?._id)
                                .then(() => {
                                    this._getSubmissions()
                                    this.setState({ showDeleteModal: false })
                                })
                        }
                    }}>{this.formService.t(this.t, "oui", false)}</Button>
                    <Button onClick={() => this.setState({ showDeleteModal: false })}>{this.formService.t(this.t, "non", false)}</Button>
                </div>
            </Dialog>
        )
    }

    private duplicateModal() {
        return (
            <Dialog
                visible={this.state.showDuplicateModal}
                onHide={() => this.setState({ showDuplicateModal: false })}
                style={{ width: "800px" }}
                header={this.formService.t(this.t, "ui_submission_name_dialog_header", false)}
            >
                <InputText style={{ width: "100%"}} value={this.state.duplicateSubmissionName} onChange={(e : any) => this.setState({ duplicateSubmissionName: e.target.value })}></InputText>
                <div style={{ display: "flex", marginTop: "10px" }}>
                    <Button onClick={() => this.handleDuplicate()}>{this.formService.t(this.props.t, "save", false)}</Button>
                </div>
            </Dialog>
        )
    }

    private nameModal() {
        return (
            <Dialog
                visible={this.state.showNameModal}
                onHide={() => this.setState({ showNameModal: false })}
                style={{ width: "800px" }}
                header={this.formService.t(this.t, "ui_submission_name_dialog_header", false)}
            >
                <InputText style={{ width: "100%"}} value={this.state.newSubmissionName} onChange={(e : any) => this.setState({ newSubmissionName: e.target.value })}></InputText>
                <div style={{ display: "flex", marginTop: "10px" }}>
                    <Button onClick={() => this.handleChangeName()}>{this.formService.t(this.props.t, "save", false)}</Button>
                </div>
            </Dialog>
        )
    }

    private descriptionModal() {
        return (
            <Dialog
                visible={this.state.showDescriptionModal}
                onHide={() => this.setState({ showDescriptionModal: false })}
                style={{ width: "800px" }}
                header={this.formService.t(this.t, "ui_submission_description_dialog_header", false)}
            >
                <InputTextarea autoResize={false} style={{ width: "100%", height: "200px" }} value={this.state.newSubmissionDescription} onChange={(e : any) => this.setState({ newSubmissionDescription: e.target.value })}></InputTextarea>
                <div style={{ display: "flex", marginTop: "10px" }}>
                    <Button onClick={() => this.handleChangeDescription()}>{this.formService.t(this.props.t, "save", false)}</Button>
                </div>
            </Dialog>
        )
    }


    render() {
        return (
            <div>
                <Toast ref={(el) => (this.toast = el)}/>
                {this.state.editState === "OVERVIEW" && (
                    <Content>
                        <Content.Header><h1>{this.formService.t(this.t, "ui_user_page_title", false)}</h1></Content.Header>
                        <Content.Body>
                            <Panel>
                                <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignContent: "center" }}>
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        <Button onClick={() => this.handleNewSimulation()}>{this.formService.t(this.t, "ui_make_new_simulation", false)}</Button>
                                        {this.state.form?.type === FormType.GRAPH && (
                                            <Button onClick={() => this.handleCompareSimulation()}>{this.formService.t(this.t, "ui_compare_simulation", false)}</Button>
                                        )}
                                    </div>
                                    <div style={{ marginLeft: "10px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "10px" }}>
                                            <Button onClick={() => {
                                                this.handleArchiveMultipleClick()
                                            }}>{this.formService.t(this.t, "ui_archive_simulations", false)}</Button>
                                            <div>
                                                <Checkbox
                                                    checked={this.state.showArchivedValue}
                                                    onChange={(e) => this.setState({ showArchivedValue: e.checked })}></Checkbox>
                                                <label style={{ marginLeft: "10px" }}>{this.formService.t(this.t, "ui_show_archived_simulation", false)}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <DataTable paginator className="submission-datatable" style={{ marginTop: "10px" }}
                                    rowClassName={(data) => ({ 'archived-submission': data.archived })} rows={20}
                                    value={this.state.showArchivedValue ? this.state.submissions : this.state.submissions.filter(s => s.archived === false)}
                                    selection={this.state.selectedSubmissions}
                                    onSelectionChange={(e) => this.setState({ selectedSubmissions: e.value })}
                                    >
                                    <Column selectionMode="multiple" style={{ width: '1em' }} />
                                    <Column style={{ width: '4em' }} filter sortable field="name" filterPlaceholder={this.formService.t(this.t, "search_by_name", false)} header={this.formService.t(this.t, "nom_header", false)} body={(props) => this.nameTemplate(props)}></Column>
                                    <Column style={{ width: '4em' }} field="description" header={this.formService.t(this.t, "description_header", false)} body={(props) => this.descriptionTemplate(props)}></Column>
                                    <Column style={{ width: '3em' }} sortable field="submissionDate" body={(props) => this.dateTemplate(props)} header={this.formService.t(this.t, "date_header", false)}></Column>
                                    {(this.state.submissions.length > 0 && this.state.form?.type === FormType.GRAPH) && this.state.submissions[0].results.map(
                                        r => <Column style={{ width: '3em' }} body={(props) => this.resultTemplate(props, r.requirementTitle)} header={this.formService.t(this.t, r.requirementTitle, false)}></Column>
                                    )}
                                    <Column style={{ width: '3em' }} header={this.formService.t(this.t, "actions_header", false)} body={(props) => this.actionButtonTemplate(props)}></Column>
                                </DataTable>
                                {(this.state.comparedGraphs.length > 0) && (
                                    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                                        <h1>{this.formService.t(this.t, "ui_compare_graph_title", false)}</h1>
                                        <small >
                                            <i style={{ marginTop: "20px" }} className="pi pi-info-circle"/> {this.formService.t(this.t, "ui_graph_compare_notes", false)}
                                        </small>
                                        <FormPVGraph graphs={this.state.comparedGraphs}
                                                    onGraphClick={(graphs: Graph[]) => this.handleGraphClick(graphs)}></FormPVGraph>
                                        {/*{(this.state.highlightedValues.length > 0 && this.state.comparedGraphs.length > 0) && (*/}
                                        {/*    <>*/}
                                        {/*        {this.state.highlightedValues.map(g => (*/}
                                        {/*            <>*/}
                                        {/*                <p>{g.title} {g.dots.map(d => (<p>{d.x} | {d.y}</p>))}</p>*/}
                                        {/*            </>*/}
                                        {/*        ))}*/}
                                        {/*    </>*/}
                                        {/*)}*/}
                                        {(this.state.highlightedValues.length > 0 && this.state.comparedGraphs.length > 0) && (
                                            <>
                                                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                                                    <Button onClick={() => {
                                                        this.setState({
                                                            highlightedValues: []
                                                        })
                                                    }}>Reset</Button>
                                                </div>
                                                <div id="graph-table">
                                                    <DataTable
                                                        value={this.state.highlightedValues}
                                                    >
                                                        <Column sortable field="x" header={this.formService.t(this.t, "graph_x_label", false)}></Column>
                                                        {this.state.selectedSubmissions.slice(0, 5).map(d => (
                                                            <Column field={d.name} header={d.name}></Column>
                                                        ))}
                                                    </DataTable>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </Panel>
                        </Content.Body>
                    </Content>
                )}

                {(this.state.editState === "UPDATE" && this.state.currentSubmission) && (
                    <FormPage connected={true} submission={this.state.currentSubmission} displayResult={false} />
                )}
                {(this.state.editState === "RESULT" && this.state.currentSubmission) && (
                    <FormPage connected={true} submission={this.state.currentSubmission} displayResult={true} />
                )}
                {(this.state.editState === "NEW" && (
                    <FormPage connected={true} displayResult={false} />
                ))}



                {this.archiveModal()}

                {this.archiveMultipleModal()}

                {this.deleteModal()}

                {this.duplicateModal()}

                {this.nameModal()}

                {this.descriptionModal()}
            </div>
        );
    }



}

export default withTranslation()(UserPage);
