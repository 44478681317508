import { Diagram } from '../../form/models/Diagram';
import { APIService } from '../../shared/services/APIService';

export class AdminDiagramService extends APIService {

  // Download diagram
  downloadDiagrams() {
    return this.http.get<Blob>('/diagrams/download', { responseType: 'blob' });
  }

  // Get all diagrams
  getAllDiagram() {
    return this.http.get<Diagram[]>("/diagrams")
  }

  // Create diagram
  updateOne(diagram: Diagram) {
    return this.http.put<void>("/diagrams/updateone", diagram)
  }

  /**
   * Upload and replace the form
   *
   * @role Admin
   */
  uploadManyDiagram(file: File) {
    const formData = new FormData();
    formData.append('diagrams', file);
    return this.http.put<void>('/diagrams/uploadmany', formData);
  }

  // Delete diagram
  deleteOne(name: string) {
    return this.http.delete<void>("/diagrams/" + name)
  }

}