import React, {Component} from "react";
import Logo from "../shared/components/Logo";
import ToolLogo from "../shared/components/ToolLogo";
import SelectLanguage from "../shared/components/SelectLanguage";
import "./MainLayout.scss";
import About from '../shared/components/About';
import NavigateButton from "../shared/components/NavigateButton";
import {AuthService} from "../login/services/AuthService";
import {RoleEnum} from "../shared/enum/RoleEnum";
import LogoutIcon from "../shared/components/LogoutIcon";

interface Props {
}

class MainLayout extends Component<Props> {
  render() {
    return (
      <React.Fragment>
        <header className="layout-header">
          <Logo/>
          <ToolLogo/>
          <div className="right">
              {(AuthService.isAuth && AuthService.user?.role === RoleEnum.ADMIN) ? (
                  <NavigateButton header="ui_navigate_admin_button" path="/admin" logo="pi-cog"/>
              ) : ''}
              {(AuthService.isAuth && AuthService.user?.email !== "anonymous@bbri.be") ? (
                  <LogoutIcon></LogoutIcon>
              ) : <NavigateButton header="ui_navigate_home_button" path="/home" logo="pi-home"/>}
            <About/>
            <SelectLanguage/>
          </div>
        </header>
        <main className="layout-content container">{this.props.children}</main>
      </React.Fragment>
    );
  }
}

export default MainLayout;
