import { AxiosResponse } from 'axios';
import { APIService } from '../../shared/services/APIService';
import {Script} from "../models/Script";
import {ScriptDTO} from "../dto/ScriptDTO";

export class AdminTemplateWordService extends APIService {
  
  /**
   * Download a template by name
   */
  downloadOne(name: string) {
    return this.http.get<Blob>(`/templates/${name}/download`, { responseType: 'blob' });
  }

  /**
   * Upload one script
   */
  uploadOne(file: File) {
    const formData = new FormData();
    formData.append('file', file)

    return this.http.post<void>(`/templates/uploadone`, formData);
  }

  /**
   * Delete one script
   */
  deleteOne(name: string) {
    return this.http.delete<void>(`/templates/${name}`);
  }
}
