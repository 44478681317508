import { Button, Content, Panel, Table } from "@bbri/ui";
import { TFunction } from "i18next";
import React, { ReactNode } from "react";
import { ChevronLeft, Send } from "react-feather";
import { withTranslation, WithTranslation } from "react-i18next";
import { ResponsesDTO } from "../dto/ResponsesDTO";
import { Form } from "../models/Form";
import { FormQuestion } from "../models/FormQuestion";
import { RequirementResults } from "../models/RequirementResults";
import { FormService } from "../services/FormService";
import { FormUtils } from "../utils/FormUtils";
import "./FormSummary.scss";
import { QuestionType } from '../enum/QuestionType';
import { RichText } from 'prismic-reactjs';
import { Submission } from "../../user/models/Submission";
import { SubmissionDTO } from "../dto/SubmissionDTO";
import {SubmissionSubmitDTO} from "../dto/SubmissionSubmitDTO";
import {AuthService} from "../../login/services/AuthService";

interface Props extends WithTranslation {
  form: Form;
  history: number[];
  values: Record<string, any>;
  submission?: Submission
  connected?: boolean
  onPreviousClick: () => void;
  onQuestionClick: (questionId: number) => void;
  onSubmitForm: (results: RequirementResults, id: string) => void;
}

interface States {
  submitButton: boolean;
}

class FormSummary extends React.Component<Props, States> {
  private formService: FormService;
  private t: TFunction;
  private isOnMobile: boolean = false;

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.t = this.props.t;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isOnMobile = true;
    }
    this.state = {
      submitButton: false
    };
  }

  /**
   * Submit the form
   */
  handleSubmitClick() {
    this.setState({
      submitButton: true
    });
    // = Get values according the history
    const values: Record<string, any> = {};
    for (let i = 0; i < this.props.history.length; i++) {
      const id = this.props.history[i];
      const question = this.props.form.questions[id];

      if (question.type === QuestionType.MULTIPLE && question.subQuestions) {
        for (const subQ of question.subQuestions) {
          values[subQ.question] = this.props.values[subQ.question] ?? 0;
        }
      }
      else {
        values[question.question] = this.props.values[question.question];
      }
    }

    // = Send DTO
      const dto : SubmissionDTO = {
        responses: (new ResponsesDTO(values)).responses,
        history: this.props.history,
        name: "",
        userEmail: AuthService.isAuth && AuthService.user?.email ? AuthService.user?.email : "",
      }

      this.formService
        .submitForm(this.props.form.form, dto)
        .then((data: SubmissionSubmitDTO) => {
          this.setState({
            submitButton: false
          });
          this.props.onSubmitForm({ results: data.results }, data.id);
        });
  }

  private _getTableBody(question: FormQuestion, value: any) {
    const tableBody: any[] = [];
    for (let i = 0; i < /*this._getSize(question)*/ value.length; i++) {
      tableBody.push(
        <tr key={`${question}`}>
          {this._getTableRow(question, value[i], i)}
        </tr>
      );
    }
    return tableBody;
  }

  private _getSize(question: FormQuestion): number {
    let sizeQuestion: any = null;
    for (const q of this.props.form.questions) {
      if (q.question === question.size.target) {
        sizeQuestion = q;
      }
    }

    if (sizeQuestion && sizeQuestion.size) {
      return this._getSize(sizeQuestion);
    }

    return this.props.values[sizeQuestion.question];
  }

  private _getTableRow(question: FormQuestion, el: any, i: number) {
    const row: any[] = [];
    if (question.subQuestions) {
      for (const subQ of question.subQuestions) {
        if (subQ.type === QuestionType.PREVIOUS_QUESTION && this.props.values[subQ.target][i]) {
          row.push(
            <td>{this.props.values[subQ.target][i][subQ.question]}</td>
          );
        }
        else {
          switch (subQ.type) {
            case QuestionType.DROPDOWN:
              row.push(
                <td>{this.formService.t(this.t, el[subQ.question], true, false)}</td>
              );
              break;
            case QuestionType.DATE_PICKER:
              row.push(
                  <td>{el[subQ.question]}</td>
              )
              break;
            default:
              row.push(
                <td>{el[subQ.question]}</td>
              );
              break;
          }
        }
      }
    }
    return row;
  }

  render() {
    const data: ReactNode[] = [];
    window.scroll(0, 0);
    FormUtils.formatValue(
      this.t,
      this.formService,
      this.props.form,
      this.props.history,
      this.props.values,
      (id: number, question: FormQuestion, value: any) => {
        if (question.toDisplayInSummary === false) {
          return;
        }

        switch (question.type) {
          case QuestionType.NUMBER:
            data.push(
              <tr
                key={id}
                className="form-summary-question"
                onClick={() => this.props.onQuestionClick(id)}
              >
                <td>{this.formService.t(this.t, question.question, true, false)}</td>
                <td>{(value === '' ? (`0 ${this.formService.t(this.t, question.unit as any, false, false)}`.replace("undefined", "")) : value)}</td>
              </tr>
            );
            break;
          case QuestionType.DATE_PICKER:
            data.push(
                <tr
                    key={id}
                    className="form-summary-question"
                    onClick={() => this.props.onQuestionClick(id)}
                >
                  <td>{this.formService.t(this.t, question.question, true, false)}</td>
                  <td>{value}</td>
                </tr>
            );
            break;
          case QuestionType.CHECKBOX:
            if (!value) {
              value = []
            }

            if (!Array.isArray(value)) {
              value = [value];
            }

            value = value.map(x => this.formService.t(this.t, x, true, false));

            data.push(
              <tr
                key={id}
                className="form-summary-question"
                onClick={() => this.props.onQuestionClick(id)}
              >
                <td>{this.formService.t(this.t, question.question, true, false)}</td>
                <td>{value}</td>
              </tr>
            );
            break;
          case QuestionType.MULTIPLE:
            if (question.subQuestions) {
              for (const qst of question.subQuestions) {
                if (typeof this.props.values[qst.question] === "boolean") {
                  data.push(
                    <tr
                      key={id}
                      className="form-summary-question"
                      onClick={() => this.props.onQuestionClick(id)}
                    >
                      <td>{this.formService.t(this.t, qst.question, true, false)}</td>
                      <td>{this.formService.t(this.t, (this.props.values[qst.question]) ? 'oui' : 'non', true, false)}</td>
                    </tr>
                  );
                }
                else {
                  data.push(
                    <tr
                      key={id}
                      className="form-summary-question"
                      onClick={() => this.props.onQuestionClick(id)}
                    >
                      <td>{this.formService.t(this.t, qst.question, true, false)}</td>
                      <td>{this.formService.t(this.t, this.props.values[qst.question], true, false)}</td>
                    </tr>
                  );
                }
              }
            }
            break;
          case QuestionType.TABLE:
            const tableHeader: any[] = [];
            if (question.subQuestions) {
              for (let i = 0; i < question.subQuestions.length; i++) {
                const subQ = question.subQuestions[i];

                tableHeader.push(
                  <th key={`${id}-header-${i}`}>
                    {this.formService.t(this.t, subQ.question, true, false)}
                  </th>
                );
              }
            }
            data.push(
              <tr
                key={id}
                className="form-summary-question"
                onClick={() => this.props.onQuestionClick(id)}
              >
                <td>{this.formService.t(this.t, question.question, true, false)}</td>
                <td>
                  <table className="tableQuestionSummary">
                    <thead>
                    <tr>
                      {tableHeader}
                    </tr>
                    </thead>
                    <tbody>
                    {this._getTableBody(question, value)}
                    </tbody>
                  </table>
                </td>
              </tr>
            );
          case QuestionType.TEXT_ONLY:
            break;
          default:
            data.push(
              <tr
                key={id}
                className="form-summary-question"
                onClick={() => this.props.onQuestionClick(id)}
              >
                <td>{this.formService.t(this.t, question.question, true, false)}</td>
                <td>{this.formService.t(this.t, value, true, false)}</td>
              </tr>
            );
            break;
        }
      }
    );

    return (
      <div className="container containerSummary">
        <Content>
          <Content.Header>
            <h1>{this.formService.t(this.t, "ui_summary_title", true)}</h1>
          </Content.Header>
          <small>
            <i className="pi pi-info-circle"/> {this.formService.t(this.t, "ui_summary_notes", false)}
          </small>
          <Content.Body>
            <Panel>
              <div className="summaryTable">
                <Table>
                  <thead>
                  <tr>
                    <th className="questionHeader">{this.formService.t(this.t, "ui_summary_question", true)}</th>
                    <th className="responseHeader">{this.formService.t(this.t, "ui_summary_response", true)}</th>
                  </tr>
                  </thead>
                  <tbody>{data}</tbody>
                </Table>
              </div>
            </Panel>
          </Content.Body>
        </Content>
        <div className="form-actions-wrapper">
          <div className="left-actions">
            <Button
              variant="secondary"
              onClick={() => this.props.onPreviousClick()}
            >
              <ChevronLeft/> {this.formService.t(this.t, "button_previous", false, true)}
            </Button>
          </div>
          <div className="right-actions">
            <Button id="results" onClick={() => this.handleSubmitClick()}
                    disabled={this.state.submitButton}
            >
              {this.formService.t(this.t, "button_submit", false, true)}{" "}
              {
                this.state.submitButton ?
                  (<i className="pi pi-spin pi-spinner" style={{ padding: 0 }}/>) :
                  (<Send style={{ transform: "rotate(45deg)" }}/>)
              }
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FormSummary);
